// ----------------------------------------------------------------------

export default function IconButton(theme) {
  return {
    MuiIconButton: {
      styleOverrides: {
        root: {
          color: (theme.palette.mode === 'dark' ? theme.palette.grey[300] : theme.palette.primary.main),
        },
        colorError: {
          color: theme.palette.error.main
        },
      },
    },
  };
}
