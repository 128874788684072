import AWS from 'aws-sdk';
import { COGNITO_API } from '../config';

export default async function resetUserPassword(username) {
  const cognitoClient = new AWS.CognitoIdentityServiceProvider({
    apiVersion: '2016-04-19',
    region: COGNITO_API.region,
  });

  const params = {
    UserPoolId: COGNITO_API.CognitoID,
    Username: username,
  };

  async function adminResetPassword(params) {
    try {
      await cognitoClient.adminResetUserPassword(params).promise();
      return { code: 200, username: params.Username };
    } catch (error) {
      return { code: 404, message: error.message };
    }
  }

  return adminResetPassword(params);
}
