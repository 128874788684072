// routes
import { PATH_REPORT } from '../../../../../../routes/VI/DIGI/paths';
// components
import SvgIconStyle from '../../../../../../components/SvgIconStyle';
//
import { PATH_REPORT as PATH_ASSES } from '../../../../../../routes/VI/ASSES/paths';

// ----------------------------------------------------------------------

const getIcon = (name) => <SvgIconStyle src={`/icons/${name}.svg`} sx={{ width: 1, height: 1 }} />;

const ICONS = {
  user: getIcon('ic_user'),
  form: getIcon('ic_form'),
  upload: getIcon('ic_upload'),
  analytics: getIcon('ic_analytics'),
  dashboard: getIcon('ic_dashboard'),
  maintenance: getIcon('ic_maintenance'),
  search: getIcon('ic_search'),
  speaker: getIcon('ic_speaker'),
  target: getIcon('ic_target'),
  question: getIcon('ic_question'),
  export: getIcon('ic_analytics_export'),
};

export const navConfig = [

  // PERFORMANCE
  // ----------------------------------------------------------------------
  {
    subheader: 'Performance',
    group: 'Indicadores',
    icon: ICONS.analytics,
    items: [
      {
        title: 'ROAe',
        path: PATH_REPORT.performance.receitaAdvisor,
        icon: ICONS.analytics,
      },
      {
        title: 'Painel Digital',
        path: PATH_REPORT.digital.root,
        icon: ICONS.analytics,
      },
      {
        title: 'Gerencial',
        path: PATH_REPORT.gerencial,
        icon: ICONS.analytics,
      },
      {
        title: 'Bases',
        path: PATH_REPORT.bases,
        icon: ICONS.analytics,
      },
      {
        title: 'Cross Sell',
        path: PATH_REPORT.xsell,
        icon: ICONS.analytics,
      },    
    ],
  },

  {
    subheader: 'Esforço',
    group: 'Indicadores',
    path: PATH_REPORT.esforco.root,
    icon: ICONS.analytics,
    items: [
      {
        title: 'Acompanhamento',
        path: PATH_ASSES.esforco.crmAdvisor,
        icon: ICONS.analytics,
      },
    ],
  },

  {
    subheader: 'Reuniões',
    group: 'Indicadores',
    path: PATH_ASSES.reunioes.root,
    icon: ICONS.analytics,
    items: [
      {
        title: 'Novo N3',
        path: PATH_ASSES.reunioes.newN3,
        icon: getIcon('ic_analytics_export')
      }
    ],
  },

  // RANKING
  // {
  //   subheader: 'Ranking',
  //   items: [
  //     {
  //       title: 'Assessoria',
  //       path: PATH_REPORT.ranking.ranking,
  //       icon: ICONS.analytics,
  //     }
  //   ]
  // },
];
