// ----------------------------------------------------------------------

export default function InputLabel(theme) {
  const isLight = theme.palette.mode === 'light';

  return {
    MuiInputLabel: {
      styleOverrides: {
        root: {
          '&.Mui-focused': {
            color: isLight ? theme.palette.primary.main : theme.palette.grey[400],
          },
        },
      },
    },
  };
}
