import { Translations } from '@aws-amplify/ui-components';
import { DefaultTexts } from '@aws-amplify/ui';

const translations = {
  [Translations.BACK_TO_SIGN_IN]: 'Voltar para tela anterior',
  [Translations.CHANGE_PASSWORD_ACTION]: 'Alterar',
  [Translations.CHANGE_PASSWORD]: 'Alterar senha',
  [Translations.CODE_LABEL]: 'Código de verificação',
  [Translations.CODE_PLACEHOLDER]: 'Insira o código',
  [Translations.CONFIRM_SIGN_UP_CODE_LABEL]: 'Código de Confirmação',
  [Translations.CONFIRM_SIGN_UP_CODE_PLACEHOLDER]: 'Digite seu código',
  [Translations.CONFIRM_SIGN_UP_HEADER_TEXT]: 'Confirmar inscrição',
  [Translations.CONFIRM_SIGN_UP_LOST_CODE]: 'Perdeu seu código?',
  [Translations.CONFIRM_SIGN_UP_RESEND_CODE]: 'Reenviar código',
  [Translations.CONFIRM_SIGN_UP_SUBMIT_BUTTON_TEXT]: 'Confirmar',
  [Translations.CONFIRM_SMS_CODE]: 'Confirmar código SMS',
  [Translations.CONFIRM_TOTP_CODE]: 'Confirmar token de acesso',
  [Translations.CONFIRM]: 'Confirmar',
  [Translations.CREATE_ACCOUNT_TEXT]: 'Criar conta',
  [Translations.EMAIL_LABEL]: 'Endereço de e-mail',
  [Translations.EMAIL_PLACEHOLDER]: 'Digite seu endereço de e-mail',
  [Translations.FORGOT_PASSWORD_TEXT]: 'Esqueceu sua senha?',
  [Translations.LESS_THAN_TWO_MFA_VALUES_MESSAGE]: 'Menos de dois tipos de MFA disponíveis',
  [Translations.NEW_PASSWORD_LABEL]: 'Nova senha',
  [Translations.NEW_PASSWORD_PLACEHOLDER]: 'Digite sua nova senha',
  [Translations.NO_ACCOUNT_TEXT]: 'Sem conta?',
  [Translations.USERNAME_REMOVE_WHITESPACE]: 'Usuário não pode conter espaços em branco',
  [Translations.PASSWORD_REMOVE_WHITESPACE]: 'A senha não pode começar ou terminar com um espaço em branco',
  [Translations.PASSWORD_LABEL]: 'Senha',
  [Translations.PASSWORD_PLACEHOLDER]: 'Digite sua senha',
  [Translations.PHONE_LABEL]: 'Número de telefone',
  [Translations.PHONE_PLACEHOLDER]: '(555) 555-1212',
  [Translations.QR_CODE_ALT]: 'qrcode',
  [Translations.RESET_PASSWORD_TEXT]: 'Redefinir senha',
  [Translations.RESET_YOUR_PASSWORD]: 'Redefina sua senha',
  [Translations.SELECT_MFA_TYPE_HEADER_TEXT]: 'Selecione o tipo de MFA',
  [Translations.SELECT_MFA_TYPE_SUBMIT_BUTTON_TEXT]: 'Verificar',
  [Translations.SEND_CODE]: 'Enviar código',
  [Translations.SUBMIT]: 'Enviar',
  [Translations.SETUP_TOTP_REQUIRED]: 'Token de acesso precisa ser configurado',
  [Translations.SIGN_IN_ACTION]: 'Login',
  [Translations.SIGN_IN_HEADER_TEXT]: 'Faça login em sua conta',
  [Translations.SIGN_IN_TEXT]: 'Entrar',
  [Translations.SIGN_IN_WITH_AMAZON]: 'Sign in with Amazon',
  [Translations.SIGN_IN_WITH_AUTH0]: 'Entrar com Auth0',
  [Translations.SIGN_IN_WITH_AWS]: 'Sign in with AWS',
  [Translations.SIGN_IN_WITH_FACEBOOK]: 'Faça login com o Facebook',
  [Translations.SIGN_IN_WITH_GOOGLE]: 'Sign in with Google',
  [Translations.SIGN_OUT]: 'Sair',
  [Translations.SIGN_UP_EMAIL_PLACEHOLDER]: 'Email',
  [Translations.SIGN_UP_HAVE_ACCOUNT_TEXT]: 'Você possui uma conta?',
  [Translations.SIGN_UP_HEADER_TEXT]: 'Crie uma nova conta',
  [Translations.SIGN_UP_PASSWORD_PLACEHOLDER]: 'Senha',
  [Translations.SIGN_UP_SUBMIT_BUTTON_TEXT]: 'Criar conta',
  [Translations.SIGN_UP_USERNAME_PLACEHOLDER]: 'Usuário',
  [Translations.SKIP]: 'Pular',
  [Translations.SUCCESS_MFA_TYPE]: 'Sucesso! Seu tipo de MFA agora é: ',
  [Translations.TOTP_HEADER_TEXT]: 'Digitalize e insira seu token de acesso',
  [Translations.TOTP_LABEL]: 'Digite o token de acesso:',
  [Translations.TOTP_ISSUER]: 'AWSCognito',
  [Translations.TOTP_SETUP_FAILURE]: 'A configuração do token falhou',
  [Translations.TOTP_SUBMIT_BUTTON_TEXT]: 'Verificar token de segurança',
  [Translations.TOTP_SUCCESS_MESSAGE]: 'Configuração do token com sucesso!',
  [Translations.UNABLE_TO_SETUP_MFA_AT_THIS_TIME]: 'Falhou! Incapaz de configurar MFA neste momento ',
  [Translations.USERNAME_LABEL]: 'Usuário',
  [Translations.USERNAME_PLACEHOLDER]: 'Digite seu usuário',
  [Translations.VERIFY_CONTACT_EMAIL_LABEL]: 'Email',
  [Translations.VERIFY_CONTACT_HEADER_TEXT]: 'A recuperação da conta requer informações de contato verificadas',
  [Translations.VERIFY_CONTACT_PHONE_LABEL]: 'Número de telefone',
  [Translations.VERIFY_CONTACT_SUBMIT_LABEL]: 'Enviar',
  [Translations.VERIFY_CONTACT_VERIFY_LABEL]: 'Verificar',
  [Translations.ADDRESS_LABEL]: 'Endereço',
  [Translations.ADDRESS_PLACEHOLDER]: 'Digite seu endereço',
  [Translations.NICKNAME_LABEL]: 'Apelido',
  [Translations.NICKNAME_PLACEHOLDER]: 'Digite seu apelido',
  [Translations.BIRTHDATE_LABEL]: 'Aniversário',
  [Translations.BIRTHDATE_PLACEHOLDER]: 'Digite sua data de nascimento',
  [Translations.PICTURE_LABEL]: 'URL da imagem',
  [Translations.PICTURE_PLACEHOLDER]: 'Digite o URL da sua imagem',
  [Translations.FAMILY_NAME_LABEL]: 'Nome de Família',
  [Translations.FAMILY_NAME_PLACEHOLDER]: 'Digite seu sobrenome',
  [Translations.PREFERRED_USERNAME_LABEL]: 'Nome de usuário preferido',
  [Translations.PREFERRED_USERNAME_PLACEHOLDER]: 'Digite seu nome de usuário preferido',
  [Translations.GENDER_LABEL]: 'Gênero',
  [Translations.GENDER_PLACEHOLDER]: 'Digite seu gênero',
  [Translations.PROFILE_LABEL]: 'URL do perfil',
  [Translations.PROFILE_PLACEHOLDER]: 'Digite o URL do seu perfil',
  [Translations.GIVEN_NAME_LABEL]: 'Nome',
  [Translations.GIVEN_NAME_PLACEHOLDER]: 'Digite seu primeiro nome',
  [Translations.ZONEINFO_LABEL]: 'Fuso horário',
  [Translations.ZONEINFO_PLACEHOLDER]: 'Insira seu fuso horário',
  [Translations.LOCALE_LABEL]: 'Locale',
  [Translations.LOCALE_PLACEHOLDER]: 'Digite sua localidade',
  [Translations.UPDATED_AT_LABEL]: 'Atualizado em',
  [Translations.UPDATED_AT_PLACEHOLDER]: 'Digite a hora em que as informações foram atualizadas pela última vez',
  [Translations.MIDDLE_NAME_LABEL]: 'Nome do meio',
  [Translations.MIDDLE_NAME_PLACEHOLDER]: 'Digite seu nome do meio',
  [Translations.WEBSITE_LABEL]: 'Site',
  [Translations.WEBSITE_PLACEHOLDER]: 'Digite seu site',
  [Translations.NAME_LABEL]: 'Nome Completo',
  [Translations.NAME_PLACEHOLDER]: 'Digite seu nome completo',
  [Translations.PHOTO_PICKER_TITLE]: 'Título do selecionador',
  [Translations.PHOTO_PICKER_HINT]: 'Texto ou conteúdo auxiliar pode ocupar este espaço aqui',
  [Translations.PHOTO_PICKER_PLACEHOLDER_HINT]: 'Dica de espaço reservado',
  [Translations.PHOTO_PICKER_BUTTON_TEXT]: 'Botão',
  [Translations.IMAGE_PICKER_TITLE]: 'Adicionar foto do perfil',
  [Translations.IMAGE_PICKER_HINT]: 'Visualize a imagem antes de fazer o upload',
  [Translations.IMAGE_PICKER_PLACEHOLDER_HINT]: 'Toque para selecionar a imagem',
  [Translations.IMAGE_PICKER_BUTTON_TEXT]: 'Upload',
  [Translations.PICKER_TEXT]: 'Escolha um arquivo',
  [Translations.TEXT_FALLBACK_CONTENT]: 'Conteúdo substituto',
  [Translations.CONFIRM_SIGN_UP_FAILED]: 'Falha ao confirmar inscrição',
  [Translations.SIGN_UP_FAILED]: 'Falha na inscrição',
  [DefaultTexts.BACK_SIGN_IN]: 'Voltar à tela anterior',
  [DefaultTexts.BIRTHDATE]: 'Data de nascimento',
  [DefaultTexts.CHANGE_PASSWORD]: 'Configurar nova senha',
  [DefaultTexts.CHANGING_PASSWORD]: 'Alterando',
  [DefaultTexts.CODE]: 'Código',
  [DefaultTexts.CONFIRM_PASSWORD]: 'Confirmar senha',
  [DefaultTexts.CONFIRM_RESET_PASSWORD_HEADING]: 'Redefinir sua senha',
  [DefaultTexts.CONFIRM_SIGNUP_HEADING]: 'Confirmar inscrição',
  [DefaultTexts.CONFIRM_SMS]: 'Confirmar código SMS',
  [DefaultTexts.CONFIRM_TOTP]: 'Confirmar token de acesso',
  [DefaultTexts.CONFIRM]: 'Confirmar',
  [DefaultTexts.CONFIRMATION_CODE]: 'Código de confirmação',
  [DefaultTexts.CONFIRMING]: 'Confirmando',
  [DefaultTexts.CREATE_ACCOUNT]: 'Criar conta',
  [DefaultTexts.CREATING_ACCOUNT]: 'Criando conta',
  [DefaultTexts.EMAIL_ADDRESS]: 'Email',
  [DefaultTexts.ENTER_CODE]: 'Digite seu código',
  [DefaultTexts.ENTER_USERNAME]: 'Digite seu nome de usuário',
  [DefaultTexts.FAMILY_NAME]: 'Sobrenome',
  [DefaultTexts.GIVEN_NAME]: 'Nome',
  [DefaultTexts.FORGOT_YOUR_PASSWORD]: 'Esqueceu sua senha? ',
  [DefaultTexts.HIDE_PASSWORD]: 'Ocultar senha',
  [DefaultTexts.LOADING]: 'Carregando',
  [DefaultTexts.LOGIN_NAME]: 'Nome de usuário',
  [DefaultTexts.MIDDLE_NAME]: 'Nome do meio',
  [DefaultTexts.NAME]: 'Nome',
  [DefaultTexts.NICKNAME]: 'Apelido',
  [DefaultTexts.NEW_PASSWORD]: 'Nova senha',
  [DefaultTexts.PASSWORD]: 'Senha',
  [DefaultTexts.PHONE_NUMBER]: 'Número de telefone',
  [DefaultTexts.PREFERRED_USERNAME]: 'Nome de usuário preferido',
  [DefaultTexts.PROFILE]: 'Perfil',
  [DefaultTexts.RESEND_CODE]: 'Reenviar código',
  [DefaultTexts.RESET_PASSWORD_HEADING]: 'Redefina sua senha',
  [DefaultTexts.RESET_PASSWORD]: 'Redefinir senha',
  [DefaultTexts.SEND_CODE]: 'Enviar código',
  [DefaultTexts.SENDING]: 'Enviando',
  [DefaultTexts.SETUP_TOTP]: 'Configure seu token de acesso',
  [DefaultTexts.SHOW_PASSWORD]: 'Mostrar senha',
  [DefaultTexts.SIGN_IN_BUTTON]: 'Fazer login',
  [DefaultTexts.SIGN_IN_TAB]: 'Entrar',
  [DefaultTexts.SIGN_IN_WITH_AMAZON]: 'Entrar com Amazon',
  [DefaultTexts.SIGN_IN_WITH_APPLE]: 'Entrar com Apple',
  [DefaultTexts.SIGN_IN_WITH_FACEBOOK]: 'Entrar com Facebook',
  [DefaultTexts.SIGN_IN_WITH_GOOGLE]: 'Entrar com Google',
  [DefaultTexts.SIGN_IN]: 'Faça login na sua conta',
  [DefaultTexts.SIGN_UP_BUTTON]: 'Criar uma nova conta',
  [DefaultTexts.SIGNING_IN_BUTTON]: 'Entrando',
  [DefaultTexts.SKIP]: 'Pular',
  [DefaultTexts.SUBMIT]: 'Enviar',
  [DefaultTexts.SUBMITTING]: 'Enviando',
  [DefaultTexts.VERIFY_CONTACT]: 'Verificar contato',
  [DefaultTexts.VERIFY_HEADING]: 'A recuperação da conta requer informações verificadas',
  [DefaultTexts.VERIFY]: 'Verificar',
  [DefaultTexts.WEBSITE]: 'Site',
  "Your passwords must match": "As senhas inseridas não são iguais.",
  "Provided password cannot be used for security reasons.": "A senha deve ter no mínimo oito caracteres com, pelo menos, um número, um caractere especial, uma letra maiúscula e uma letra minúscula.",
  "Invalid verification code provided, please try again.": "Código de verificação do email inválido.",
  "Invalid code received for user": "Código inválido. Certifique-se de que o horário do seu aparelho celular e do seu computador estejam sincronizados e atualizados.",
  "Incorrect username or password.": "Usuário ou senha incorretos."
};

export default translations;
