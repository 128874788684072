import { Suspense, lazy } from 'react';
import { Navigate, useRoutes, useLocation } from 'react-router-dom';
// layouts
import DashboardLayout from '../../../../layouts/dashboard';
// components
import LoadingScreen from '../../../../components/LoadingScreen';
// hooks 
import useAuth from '../../../../hooks/useAuth';
// default routes
import defaultRouter from '../../../defaultRouter';
// ----------------------------------------------------------------------

const Loadable = (Component) => (props) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { pathname } = useLocation();

  return (
    <Suspense fallback={<LoadingScreen isDashboard={
      pathname.includes('/settings')} />}>
      <Component {...props} />
    </Suspense>
  );
};

export function Router() {
  const { user, username, userRole, userDepartment, userBranch, userRoutes } = useAuth();

  return useRoutes([
    ...defaultRouter({ defaultRoute: "/home", user, username, userRole, userDepartment, userBranch, userRoutes }),

    {
      path: 'settings',
      element: <DashboardLayout />,
      children: [
        {
          path: 'user',
          children: [
            { element: <Navigate to="/settings/user/list" replace />, index: true },
            { path: 'list', element: <UserList /> },
            { path: ':username/edit', element: <UserCreate /> },
          ],
        },
      ],
    },
  ]);
}

// SETTINGS
const UserList = Loadable(lazy(() => import('../../../../pages/VI/general/user/UserList')));
const UserCreate = Loadable(lazy(() => import('../../../../pages/VI/general/user/UserCreate')));
