// ----------------------------------------------------------------------

function path(root, sublink) {
  return `${root}${sublink}`;
}

const ROOTS_REPORT = '/report';

// ----------------------------------------------------------------------

export const PATH_REPORT = {
  root: ROOTS_REPORT,
  gerencial: path(ROOTS_REPORT, '/gerencial'),
};
