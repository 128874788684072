import { current, createSlice } from '@reduxjs/toolkit';

// ----------------------------------------------------------------------

const initialState = {
  nItems: localStorage.getItem('cartItems') ? JSON.parse(localStorage.getItem('cartItems')).length : 0,
  items: localStorage.getItem('cartItems') ? JSON.parse(localStorage.getItem('cartItems')) : []
};

const slice = createSlice({
  name: 'valorStore',
  initialState,
  reducers: {

    // GET BOARD
    putItem(state, action) {
      const newState = [
        ...state.items,
        action.payload
      ];
      state.items = newState;
      state.nItems = newState.length;
      localStorage.setItem('cartItems', JSON.stringify(newState));
    },    

    updateItem(state, action) {
      const newItem = action.payload;
      const newState = state.items.map(e => e.createdAt === newItem.createdAt ? newItem : e);
      state.items = newState;
      localStorage.setItem('cartItems', JSON.stringify(newState));
    },

    removeItem(state, action) {
      const createdAt = action.payload;
      const newState = state.items.filter(e => e.createdAt !== createdAt);
      state.items = newState;
      state.nItems -= 1;
      localStorage.setItem('cartItems', JSON.stringify(newState));
    },

    setCart(state, action) {
      const newState = [
        ...action.payload
      ];
      state.items = newState;
      state.nItems = newState.length;

      localStorage.setItem('cartItems', JSON.stringify(newState));
    },

    persistCardDetails(state, action) {
      const { id, values } = action.payload;
      const currentCards = current(state.board.cards);
      const newCards = { ...currentCards, [id]: { ...currentCards[id], ...values } };
      state.board.cards = newCards;
    },
  },
});

// Reducer
export default slice.reducer;

export const { actions } = slice;
export const { putItem, updateItem, removeItem, setCart } = actions;

