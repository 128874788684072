// routes
import { PATH_REPORT } from '../../../../../../routes/VCS/VCS/paths';
// components
import SvgIconStyle from '../../../../../../components/SvgIconStyle';

// ----------------------------------------------------------------------

const getIcon = (name) => <SvgIconStyle src={`/icons/${name}.svg`} sx={{ width: 1, height: 1 }} />;

const ICONS = {
  analytics: getIcon('ic_analytics'),
  search: getIcon('ic_search'),
  share: getIcon('ic_share'),
  target: getIcon('ic_target'),
  question: getIcon('ic_question'),
  maintenance: getIcon('ic_maintenance'),
  upload: getIcon('ic_upload'),
  export: getIcon('ic_analytics_export'),
};

// eslint-disable-next-line
export const navConfig = [
  // PAINEIS
  // ----------------------------------------------------------------------
  {
    subheader: 'Painéis',
    group: 'Indicadores',
    icon: ICONS.analytics,
    items: [
      {
        title: 'Gerencial Prev',
        path: PATH_REPORT.gerencial,
        icon: ICONS.analytics,
      },
      {
        title: 'Campanhas CRM',
        path: PATH_REPORT.campaigns,
        icon: ICONS.analytics,
      },
      {
        title: 'Captação',
        path: PATH_REPORT.captacao,
        icon: ICONS.analytics,
      },
    ],
  },

  {
    group: 'Indicadores',
    items: [
      {
        title: 'Ativações',
        path: PATH_REPORT.ativacoes,
        icon: ICONS.analytics,
      },
    ],
  },
];
