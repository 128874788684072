import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
// import Hotjar from '@hotjar/browser';
import { CognitoUserPool } from 'amazon-cognito-identity-js';
import { useState, useEffect, useCallback, createContext } from 'react';
// amplify
import AWS from 'aws-sdk';
import { Amplify, Auth, I18n, Storage, Hub } from 'aws-amplify';
import { Authenticator, useTheme, View, Heading, useAuthenticator, Alert, Loader } from '@aws-amplify/ui-react';
// @mui
import { Stack, Typography, useTheme as muiTheme } from '@mui/material';
import { LicenseInfo } from '@mui/x-license-pro';
// components
import Logo from '../components/Logo';
// AWS sdk
import listUser from './listUser';
import createUser from './createUser';
import updateUser from './updateUser';
import enableUser from './enableUser';
import listSesions from './listSesions';
import disableUser from './disableUser';
import resetTotp4User from './resetTotp4User';
import resetUserPassword from './resetUserPassword';
// redux
import { setCart as setMyCart } from "../redux/slices/valorStore";
// utils
import axios from '../utils/axios';
import { encrypt } from '../utils/crypto';
//
import { COGNITO_API, MUI_LICENSE, COGNITO_ENDPOINT_API, ROUTES_API, ANNOUNCEMENTS_API, VALOR_STORE_API, PEOPLE_API } from '../config';
// translations
import translations from './Translations';
// css
import './styles.css';

// ----------------------------------------------------------------------

Amplify.configure({
  Auth: {
    region: COGNITO_API.region,
    userPoolId: COGNITO_API.CognitoID,
    identityPoolId: COGNITO_API.CognitoIdentityPoolId,
    userPoolWebClientId: COGNITO_API.CognitoClientID,
    oauth: {
      domain: COGNITO_API.CognitoDomainName,
      scope: ['phone', 'email', 'openid'],
      responseType: 'token',
    },
  },
  Storage: {
    AWSS3: {
      bucket: COGNITO_API.bucketSorage,
      region: COGNITO_API.region,
      identityPoolId: COGNITO_API.CognitoIdentityPoolId,
    },
  },
});

Storage.configure({ level: 'protected' });

AWS.config.update({
  accessKeyId: COGNITO_API.accessKeyId,
  secretAccessKey: COGNITO_API.secretAccessKey,
  region: COGNITO_API.region,
});

LicenseInfo.setLicenseKey(MUI_LICENSE);

const userPool = new CognitoUserPool({
  UserPoolId: COGNITO_API.CognitoID,
  ClientId: COGNITO_API.CognitoClientID,
});

I18n.putVocabulariesForLanguage('en', translations);
I18n.putVocabulariesForLanguage('pt', translations);

// const siteId = 3532067;
// const hotjarVersion = 6;

// Hotjar.init(siteId, hotjarVersion);

const formFields = {
  confirmSignIn: {
    confirmation_code: {
      labelHidden: true,
      placeholder: 'Insira o código',
      isRequired: true,
      autoFocus: true
    },
  },
  signIn: {
    username: {
      labelHidden: true,
      placeholder: 'Insira seu usuário ou email',
      isRequired: true
    },
    password: {
      labelHidden: true,
      placeholder: 'Insira sua senha',
      isRequired: true
    },
  },
  confirmResetPassword: {
    confirmation_code: {
      placeholder: 'Código',
      labelHidden: true,
      isRequired: true,
    },
    password: {
      placeholder: 'Nova senha',
      labelHidden: true,
      isRequired: true,
    },
    confirm_password: {
      placeholder: 'Confirmar senha',
      labelHidden: true,
      isRequired: true,
    }
  },
  forceNewPassword: {
    password: {
      placeholder: 'Nova senha',
      labelHidden: true,
      isRequired: true,
    },
    confirm_password: {
      placeholder: 'Confirmar senha',
      labelHidden: true,
      isRequired: true,
    }
  },
  setupTOTP: {
    confirmation_code: {
      labelHidden: true,
      placeholder: 'Insira o código',
      isRequired: true,
    },
  },
}

const AuthContext = createContext({
  user: null,
  username: null,
  userCore: null,
  userDepartment: null,
  userRole: null,
  userBranch: null,
  userRoutes: null,
  userAnnouncements: null,
  picture: null,
  method: 'cognito',
  skipUpdateChecked: false,
  setSkipUpdateChecked: () => { },
  setResourcesOnLogin: () => Promise.resolve(),
  logout: () => Promise.resolve(),
  globalLogout: () => Promise.resolve(),
  getIdToken: () => Promise.resolve(),
  updatePassword: () => Promise.resolve(),
  resetPassword: () => Promise.resolve(),
  createProfile: () => Promise.resolve(),
  updateProfile: () => Promise.resolve(),
  enableProfile: () => Promise.resolve(),
  disableProfile: () => Promise.resolve(),
  listProfiles: () => Promise.resolve(),
  listSesions: () => Promise.resolve(),
  setupTOTP: () => Promise.resolve(),
  resetTOTP: () => Promise.resolve(),
  verifyTotpToken: () => Promise.resolve(),
  downloadPicture: () => Promise.resolve(),
  setUserAnnouncements: () => Promise.resolve(),
  setCart: () => Promise.resolve(),
  setAssignedUsername: () => { },
  setAssignedUserRole: () => { },
  setAssignedUserBranch: () => { },
  setAssignedDepartment: () => { },
  setAssignedCore: () => { },
  setPicture: () => { },
});

const listener = (data) => {
  switch (data.payload.event) {
    case 'tokenRefresh_failure':
      window.location.href = '/';
      break;
    case 'configured':
      window.location.href = '/';
      break;
    case 'signIn':
      localStorage.removeItem('assignedUsername');
      localStorage.removeItem('assignedUserRole');
      localStorage.removeItem('assignedUserBranch');
      localStorage.removeItem('assignedDepartment');
      localStorage.removeItem('assignedCore');
      localStorage.removeItem('clickLogout');
      localStorage.removeItem('userResources');
      localStorage.removeItem('SUC');
      localStorage.removeItem('cartItems');
      break;
    default:
      break;
  }
}

Hub.listen('auth', listener);

AuthProvider.propTypes = {
  children: PropTypes.node,
};

function AuthProvider({ children }) {
  const [picture, setPicture] = useState();
  const [username, setUsername] = useState(null);
  const [userCore, setUserCore] = useState(null);
  const [userRole, setUserRole] = useState(null);
  const [userBranch, setUserBranch] = useState(null);
  const [userRoutes, setUserRoutes] = useState(null);
  const [userDepartment, setUserDepartment] = useState(null);
  const [userAnnouncements, setUserAnnouncements] = useState(null);
  const [skipUpdateChecked, setSkipUpdateChecked] = useState(false);

  const dispatch = useDispatch();

  const setRoutes = useCallback((user, username) => {
    if (username) {
      axios.request({
        url: `${ROUTES_API}/officer/${username}/get`,
        method: "GET",
        headers: {
          Authorization: `Bearer ${user?.signInUserSession?.idToken?.jwtToken}`,
          'Access-Control-Allow-Origin': '*',
          Accept: '*/*'
        }
      }).then(resp => {
        setUserRoutes(resp?.data);
      });
    }
  }, []);

  const setAnnouncements = useCallback((user) => {
    if (user) {
      axios.request({
        url: `${ANNOUNCEMENTS_API}/list?notifications=true`,
        method: "GET",
        headers: {
          Authorization: `Bearer ${user?.signInUserSession?.idToken?.jwtToken}`,
          'Access-Control-Allow-Origin': '*',
          Accept: '*/*'
        }
      }).then(resp => {
        setUserAnnouncements(resp?.data);
      });
    }
  }, []);

  const setCart = async () => {
    try {
      const user = await Auth.currentAuthenticatedUser();
      const resp = await axios.request({
        url: `${VALOR_STORE_API}/getcart`,
        method: "GET",
        headers: {
          Authorization: `Bearer ${user?.signInUserSession?.idToken?.jwtToken}`,
          'Access-Control-Allow-Origin': '*',
          Accept: '*/*'
        }
      });

      dispatch(setMyCart(resp.data));

      return resp.data;
    } catch (e) {
      console.log(e);
      return [];
    }
  }

  const setResourcesOnLogin = async () => {
    const user = await Auth.currentAuthenticatedUser();
    setRoutes(user, user.username);
    if (user?.attributes?.picture) {
      const key = `profilePictures/${user.username}/${user?.attributes?.picture}`;
      downloadPicture(key, setPicture);
      // setPicture(resp);
    } else {
      return false;
    }

    setAnnouncements(user);
    setCart();

    if (localStorage.getItem('SUC') !== 'TRUE') {
      const resp = await axios.request({
        url: `${PEOPLE_API}/${user.username}/get?fields=skipUpdateCount`,
        method: "GET",
        headers: {
          Authorization: `Bearer ${user?.signInUserSession?.idToken?.jwtToken}`,
          'Access-Control-Allow-Origin': '*',
          Accept: '*/*'
        }
      })

      if (!resp.data?.Items?.skipUpdateCount || Number(resp.data?.Items?.skipUpdateCount) !== -1)
        return false;
    }

    localStorage.setItem('userResources', 'loaded');

    return true;
  }

  useEffect(() => {
    (async () => {
      const user = await Auth.currentAuthenticatedUser();

      if (localStorage.getItem('assignedUsername') && ((user?.attributes?.['custom:access_level'] === 'DEV' || user?.attributes?.['custom:access_level'] === 'FOLLOW'))) {
        setUsername(localStorage.getItem('assignedUsername'));
        setRoutes(user, localStorage.getItem('assignedUsername'));
      } else {
        setRoutes(user, user.username);
        // if (user?.attributes?.['custom:access_level'] === 'DEV')
        setAnnouncements(user);
      }

      if (localStorage.getItem('assignedCore') && ((user?.attributes?.['custom:access_level'] === 'DEV' || user?.attributes?.['custom:access_level'] === 'FOLLOW'))) {
        setUserCore(localStorage.getItem('assignedCore'));
      }
      if (localStorage.getItem('assignedDepartment') && ((user?.attributes?.['custom:access_level'] === 'DEV' || user?.attributes?.['custom:access_level'] === 'FOLLOW'))) {
        setUserDepartment(localStorage.getItem('assignedDepartment'));
      }
      if (localStorage.getItem('assignedUserRole') && ((user?.attributes?.['custom:access_level'] === 'DEV' || user?.attributes?.['custom:access_level'] === 'FOLLOW'))) {
        setUserRole(localStorage.getItem('assignedUserRole'));
      }
      if (localStorage.getItem('assignedUserBranch') && ((user?.attributes?.['custom:access_level'] === 'DEV' || user?.attributes?.['custom:access_level'] === 'FOLLOW'))) {
        setUserBranch(localStorage.getItem('assignedUserBranch'));
      }
    })()
    // eslint-disable-next-line
  }, []);

  const setAssignedCoreUser = useCallback((user, core) => {
    if (!core) {
      setUserCore('NULL');
    }
    if ((user?.attributes?.['custom:access_level'] === 'DEV' || user?.attributes?.['custom:access_level'] === 'FOLLOW')) {
      setUserCore(core);
    }
  }, []);

  const setAssignedDepartmentUser = useCallback((user, departament) => {
    if (!departament) {
      setUserDepartment('NULL');
    }
    if ((user?.attributes?.['custom:access_level'] === 'DEV' || user?.attributes?.['custom:access_level'] === 'FOLLOW')) {
      setUserDepartment(departament);
    }
  }, []);

  const setAssignedUserRoleUser = useCallback((user, userRole) => {
    if (!userRole) {
      setUserRole('NULL');
    }
    if ((user?.attributes?.['custom:access_level'] === 'DEV' || user?.attributes?.['custom:access_level'] === 'FOLLOW')) {
      setUserRole(userRole);
    }
  }, []);

  const setAssignedUserBranchUser = useCallback((user, userBranch) => {
    if (!userBranch) {
      setUserBranch('NULL');
    }
    if ((user?.attributes?.['custom:access_level'] === 'DEV' || user?.attributes?.['custom:access_level'] === 'FOLLOW')) {
      setUserBranch(userBranch);
    }
  }, []);

  const setAssignedUsernameUser = useCallback((user, username) => {
    if (!username) {
      setUsername('NULL');
    }
    if ((user?.attributes?.['custom:access_level'] === 'DEV' || user?.attributes?.['custom:access_level'] === 'FOLLOW')) {
      setUsername(username);
      if (!username)
        setRoutes(user, user.username);
      else setRoutes(user, username);
    }
  }, [setRoutes]);

  const updatePassword = useCallback(async (oldPassword, newPassword) => {
    const cognitoUser = userPool.getCurrentUser();
    // eslint-disable-next-line no-promise-executor-return
    await new Promise((res) => cognitoUser.getSession(res));

    cognitoUser.changePassword(oldPassword, newPassword, (error, result) => {
      if (error) {
        throw error;
      }
      return result;
    });
  }, []);

  const setupTOTP = useCallback(async (user) => {
    const res = await Auth.setupTOTP(user);
    const authCode = `otpauth://totp/Intranet%20Valor:${user.username}?secret=${res}&issuer=Cognito`;
    return authCode;
  }, []);

  const resetTOTP = useCallback(async (userdata) => {
    if (userdata) {
      await resetTotp4User(userdata);
    }
    else {
      const user = await Auth.currentAuthenticatedUser();
      await Auth.setupTOTP(user);
    }
  }, []);

  const verifyTotpToken = useCallback(async (user, challengeAnswer) => {
    await Auth.verifyTotpToken(user, challengeAnswer);
    await Auth.setPreferredMFA(user, 'TOTP');
  }, []);

  const logout = useCallback(async () => {
    await Auth.signOut();
    localStorage.removeItem('assignedUsername');
    localStorage.removeItem('assignedUserRole');
    localStorage.removeItem('assignedUserBranch');
    localStorage.removeItem('assignedDepartment');
    localStorage.removeItem('assignedCore');
    localStorage.removeItem('userResources');
    localStorage.removeItem('SUC');
    localStorage.removeItem('cartItems');
    window.setTimeout(async () => {
      localStorage.removeItem('clickLogout');
    }, 1000);
    window.location.href = '/';
  }, []);

  const refreshSession = useCallback(() => {
    try {
      window.setTimeout(async () => {
        await Auth.currentAuthenticatedUser({ bypassCache: true });
      }, 2000);
    } catch {
      logout();
    }
  }, [logout]);

  const createProfile = useCallback((values) => {
    const resp = createUser(values);
    refreshSession();
    return resp;
  }, [refreshSession]);

  const updateProfile = useCallback((values) => {
    const resp = updateUser(values);
    refreshSession();
    return resp;
  }, [refreshSession]);

  const resetPassword = useCallback((username) => resetUserPassword(username), []);

  const resetAccount = (username, setResult) => {
    axios.request({
      url: `${COGNITO_ENDPOINT_API}/${btoa(encrypt(`${username}#${Math.floor(Date.now() / 1000)}#account`))}/reset-account`,
      method: "GET",
      headers: { "Accept": "*/*" }
    }).then(() =>
      setResult('success')
    ).catch(() =>
      setResult('error')
    )
  }

  const disableProfile = useCallback((username) => {
    const resp = disableUser(username);
    refreshSession();
    return resp;
  }, [refreshSession]);

  const enableProfile = useCallback((username) => {
    const resp = enableUser(username);
    refreshSession();
    return resp;
  }, [refreshSession]);

  const listProfiles = useCallback(() => listUser(), []);

  const globalLogout = useCallback(async () => {
    await Auth.signOut({ global: true });
  }, []);

  const refreshTokenCall = useCallback(async () => {
    const cognitoUser = await Auth.currentAuthenticatedUser();
    const currentSession = cognitoUser.signInUserSession;
    return new Promise((success, failure) => {
      cognitoUser.refreshSession(currentSession.refreshToken, (error, session) => {
        if (error) {
          failure(error)
        }
        success(session?.idToken?.jwtToken);
      });
    })
  }, []);

  const getIdTokenUser = useCallback(async (user, props) => {
    try {
      // eslint-disable-next-line
      if (props?.force) {
        return refreshTokenCall();
      }
      if (user.signInUserSession.idToken.payload.exp * 1000 > Date.now()) {
        return user.signInUserSession.idToken.jwtToken;
      }
      return refreshTokenCall();
    } catch (e) {
      logout();
    }
    return null;
  }, [logout, refreshTokenCall]);

  const downloadPicture = useCallback(async (key, setter = null) => {
    let img;
    try {
      const resp = await Storage.get(key, {
        download: true,
        level: 'public',
        cacheControl: 'no-cache'
      });
      img = URL.createObjectURL(resp.Body);
      if (setter)
        setter(img);
    } catch (error) { console.log(error) }
    return img;
  }, []);

  useEffect(() => {
    const download = async () => {
      const user = await Auth.currentAuthenticatedUser({ bypassCache: true });
      if (user?.attributes?.picture) {
        const key = `profilePictures/${user.username}/${user?.attributes?.picture}`;
        const resp = await downloadPicture(key);
        setPicture(resp);
      }
    };

    download();
    // eslint-disable-next-line
  }, []);

  const components = {
    Header() {
      const { tokens } = useTheme();

      return (
        <View textAlign="center" padding={`${tokens.space.large} ${tokens.space.large} 0 ${tokens.space.large}`} display="flex" justifyContent="center">
          <Logo disabledLink sx={{ width: 240, height: 80 }} color='#0D2335' />
        </View>
      );
    },

    SignIn: {
      Header() {
        const { tokens } = useTheme();

        return (
          <Heading padding={`${tokens.space.medium} 0 0 ${tokens.space.xl}`} level={3}>
            Faça login em sua conta
          </Heading>
        );
      },
    },

    ConfirmSignIn: {
      Footer() {
        const [result, setResult] = useState(false);
        const [loading, setLoading] = useState(false);
        const theme = muiTheme();
        const { user } = useAuthenticator();

        return (
          <>
            {result === 'success' &&
              <Alert variation='success'>
                Sua solicitação foi recebida com sucesso! Verifique seu email para prosseguir com o processo.
              </Alert>
            }

            {result === 'error' &&
              <Alert variation='error'>
                Erro na solicitação! Tente novamente.
              </Alert>
            }

            {(!loading || result === 'error') &&
              <Typography variant='body2' color={theme.palette.grey[500]}>
                Não tem um código ou trocou de celular?&nbsp;
                <a
                  role='button'
                  tabIndex={0}
                  style={{ color: theme.palette.primary.main, cursor: 'pointer' }}
                  onClick={() => {
                    setLoading(true);
                    resetAccount(user.username, setResult);
                  }}
                  onKeyDown={() => resetAccount(user.username, setResult)}
                >
                  Clique aqui
                </a> para reestabelecer o seu acesso.
              </Typography>
            }

            {loading && !result &&
              <Stack direction='row' justifyContent='center' sx={{ width: '100%' }}>
                <Loader size='large' />
              </Stack>
            }
          </>
        )
      },
    },

    ConfirmResetPassword: {
      Header() {
        const { tokens } = useTheme();
        const theme = muiTheme();
        return (
          <Heading
            padding={`${tokens.space.medium} 0 0 0`}
            level={3}
          >
            Redefinir senha
            <Typography variant='body1' sx={{ mt: 2 }} color={theme.palette.grey[700]}>
              Um código de confirmação foi enviado para o seu email.
              Insira-o no campo abaixo e defina uma nova senha.
            </Typography>
          </Heading>
        );
      },
    },
  };

  const services = {
    async handleForgotPassword(username) {
      // custom username
      axios.request({
        url: `${COGNITO_ENDPOINT_API}/${btoa(encrypt(`${username}#${Math.floor(Date.now() / 1000)}#password`))}/reset-pswd`,
        method: "GET",
        headers: { "Accept": "*/*" }
      }) /* .then((resp) => {
        console.log(resp?.data?.message)
        if (resp?.data?.message?.cognito === 200) {
          window.alert('Solicitação enviada. Verifique seu email para informar o código recebido.')
        }
        else if (resp?.data?.message?.cognito === 201) { // created
          window.alert('Usuário recriado!');
          window.location.reload();
        }
        else {
          window.alert('Falha na solicitação!');
        }
      })
        .catch((error) => {
          // window.alert(`Falha na solicitação!\n${error?.error?.split(": ").at(-1) || "Erro desconhecido"}`);
          console.log(error?.error)
        }); */
    },
  };

  return (
    <Authenticator services={services} components={components} formFields={formFields}>

      { // eslint-disable-next-line no-unused-vars
        ({ signOut, user }) => {
          // use the token or Bearer depend on the wait BE handle, by default amplify API only need to token.
          axios.defaults.headers.common.Authorization = `Bearer ${user?.signInUserSession?.idToken?.jwtToken}`;

          return (
            // eslint-disable-next-line react/jsx-no-constructed-context-values
            <AuthContext.Provider value={{
              method: 'cognito',
              user,
              skipUpdateChecked,
              username: username || user?.username || 'NULL',
              userCore: userCore || user?.attributes?.['custom:core'] || 'NULL',
              userDepartment: userDepartment || user.attributes?.['custom:department'] || 'NULL',
              userRole: userRole || user?.attributes?.['custom:access_level'] || 'NULL',
              userBranch: userBranch || user?.attributes?.['custom:branch'] || 'NULL',
              userAnnouncements,
              userRoutes,
              picture,
              logout,
              setSkipUpdateChecked,
              setResourcesOnLogin,
              globalLogout,
              getIdToken: (props) => getIdTokenUser(user, props),
              updatePassword,
              resetPassword,
              createProfile,
              updateProfile,
              enableProfile,
              disableProfile,
              listProfiles,
              listSesions,
              setupTOTP,
              resetTOTP,
              verifyTotpToken,
              downloadPicture,
              setAssignedUsername: (username) => setAssignedUsernameUser(user, username),
              setAssignedUserRole: (userRole) => setAssignedUserRoleUser(user, userRole),
              setAssignedUserBranch: (userBranch) => setAssignedUserBranchUser(user, userBranch),
              setAssignedDepartment: (department) => setAssignedDepartmentUser(user, department),
              setAssignedCore: (core) => setAssignedCoreUser(user, core),
              setUserAnnouncements,
              setPicture,
              setCart
            }}>
              {children}
            </AuthContext.Provider>
          )
        }
      }
    </Authenticator >
  );
}


export { AuthContext, AuthProvider };
