import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
// @mui
import { styled } from '@mui/material/styles';
import { List, Box, ListSubheader, Divider, Typography, TextField, Stack, useTheme } from '@mui/material';
// hooks
// import useLocales from '../../../hooks/useLocales';
// components
import Iconify from '../../Iconify';
import { NavListRoot } from './NavList';
// 
import { MENU_SECTIONS as SECTIONS } from '../../../config';

// ----------------------------------------------------------------------

export const ListSubheaderStyle = styled((props) => <ListSubheader disableSticky disableGutters {...props} />)(
  ({ theme }) => ({
    ...theme.typography.overline,
    paddingTop: theme.spacing(3),
    paddingLeft: theme.spacing(2),
    paddingBottom: theme.spacing(1),
    color: theme.palette.text.primary,
    transition: theme.transitions.create('opacity', {
      duration: theme.transitions.duration.shorter,
    }),
  })
);

// ----------------------------------------------------------------------

NavSectionVertical.propTypes = {
  isCollapse: PropTypes.bool,
  navConfig: PropTypes.array,
};

export default function NavSectionVertical({ navConfig, isCollapse = false, ...other }) {
  const [menu, setMenu] = useState([]);
  const [search, setSearch] = useState('');

  const theme = useTheme();

  useEffect(() => {
    const map = {};
    navConfig.forEach(i => {
      if (i.group) {
        if (map[i.group])
          map[i.group].push(i);
        else map[i.group] = [i];
      } else if (map.Outros) {
        map.Outros.push(i);
      }
      else map.Outros = [i];
    });

    const menu_ = [];
    SECTIONS.forEach(s => {
      if (map[s]) {
        menu_.push({ group: s, groupItems: map[s] })
      }
    })

    setMenu(menu_);
  }, [navConfig])

  useEffect(() => {
    const map = {};
    navConfig.forEach(i => {
      const keys = `${i.subheader} ${i.items?.map(e => e.title)?.join(' ')}`;
      // if (!search || i.subheader?.toLowerCase().includes(search.toLocaleLowerCase())) {
      if (!search || keys?.toLowerCase().includes(search.toLocaleLowerCase())) {
        if (i.group) {
          if (map[i.group])
            map[i.group].push(i);
          else map[i.group] = [i];
        } else if (map.Outros) {
          map.Outros.push(i);
        }
        else map.Outros = [i];
      }
    });

    const menu_ = [];
    SECTIONS.forEach(s => {
      if (map[s]) {
        menu_.push({ group: s, groupItems: map[s] })
      }
    })

    setMenu(menu_);
    // eslint-disable-next-line
  }, [search])

  return (
    <Box {...other}>
      <Stack direction='row' justifyContent='center' sx={{ width: '100%', mb: 1 }}>
        {isCollapse ?
          <Box sx={{ borderRadius: 1, border: `1px solid ${theme.palette.grey[300]}`, display: 'flex', justifyContent: 'center', p: 1 }}>
            <Iconify icon='fe:search' sx={{ fontSize: 18 }} />
          </Box>
          :
          <TextField
            placeholder='Pesquisar'
            value={search}
            onChange={(e) => setSearch(e.target.value)}
            size='small'
            InputProps={{
              startAdornment: (
                <Iconify icon='fe:search' sx={{ mr: 1, fontSize: 18 }} />
              )
            }}
            sx={{ width: '90%' }}
          />
        }
      </Stack>

      {menu.map(section => (
        <Box key={section.group}>
          {!isCollapse &&
            <Divider variant='middle' textAlign='center'>
              <Typography variant='h6'>{section.group}</Typography>
            </Divider>
          }
          {section.groupItems.map((group, i) => (
            <List key={`nav-${group.subheader}-${i}`} disablePadding sx={{ px: 2 }}>
              {/* {group.subheader &&
            <ListSubheaderStyle
            sx={{
              ...(isCollapse && {
                  opacity: 0,
                }),
              }}
              >
              {translate(group.subheader)}
              </ListSubheaderStyle>
            } */}

              {(() => {
                // eslint-disable-next-line
                let items = group.items;
                if (group.subheader) {
                  items = [{
                    title: group.subheader,
                    path: group.path,
                    icon: group.icon,
                    children: group.items
                  }];
                }

                return items.map((list) => (
                  <NavListRoot key={list.title + list.path} list={list} isCollapse={isCollapse} expand={Boolean(search)} />
                ))
              })()}

            </List>
          ))}
        </Box>
      ))}

      {!menu.length &&
        <Typography
          variant='overline'
          textAlign='center'
          color={theme.palette.primary.light}
          sx={{ width: '100%', display: 'block', mt: 2 }}
        >
          Sem resultados
        </Typography>
      }
    </Box>
  );
}
