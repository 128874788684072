export default function CircularProgress(theme) {
  return {
    MuiCircularProgress: {
      styleOverrides: {
        root: {
          color: (theme.palette.mode === 'dark' && theme.palette.grey[300]),
        },
      },
    },
  };
}