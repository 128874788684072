function path(root, sublink) {
    return `${root}${sublink}`;
  }
  
  const ROOTS_DASHBOARD = '/dashboard';
  const ROOTS_REPORT = '/report';
  
  // ----------------------------------------------------------------------
  
  export const PATH_DASHBOARD = {
    root: ROOTS_DASHBOARD,
    base: path(ROOTS_DASHBOARD, '/base'),
  };
  
  export const PATH_REPORT = {
    root: ROOTS_REPORT,
    capitalMarket: path(ROOTS_REPORT, '/capital-market'),
    performance: {
      root: path(ROOTS_REPORT, '/performance'),
      gerencial: path(ROOTS_REPORT, '/performance/gerencial'),
      bases: path(ROOTS_REPORT, '/performance/bases'),
      cambio: path(ROOTS_REPORT, '/performance/cambio')
    },
  }