import { Suspense, lazy } from 'react';
import { Navigate, useRoutes, useLocation } from 'react-router-dom';
// layouts
import DashboardLayout from '../../../../layouts/dashboard';
// components
import LoadingScreen from '../../../../components/LoadingScreen';
// hooks 
import useAuth from '../../../../hooks/useAuth';
// default routes
import defaultRouter from '../../../defaultRouter';

// ----------------------------------------------------------------------

const Loadable = (Component) => (props) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { pathname } = useLocation();

  return (
    <Suspense fallback={<LoadingScreen isDashboard={
      pathname.includes('/dashboard') ||
      pathname.includes('/report')} />}>
      <Component {...props} />
    </Suspense>
  );
};

export function Router() {
  const { user, username, userRole, userDepartment, userBranch, userRoutes } = useAuth();

  return useRoutes([
    ...defaultRouter({ defaultRoute: "/home", user, username, userRole, userDepartment, userBranch, userRoutes }),

    {
      path: 'superranking/leader',
      element: <DashboardLayout />,
      children: [
        { element: <Navigate to="/superranking/leader/general" replace />, index: true },
        { path: 'general', element: <SuperRankingLeaderGeneral /> },
        { path: 'team', element: <SuperRankingLeaderTeam /> }
      ]
    },

    {
      path: 'report',
      element: <DashboardLayout />,
      children: [
        { element: <Navigate to="/report/performance" replace />, index: true },

        {
          path: 'digital',
          children: [
            { path: 'leader', element: <DigitalLeader /> },
          ]
        },

        {
          path: 'performance',
          children: [
            { element: <Navigate to="/report/performance/gerencial" replace />, index: true },
            {
              path: 'gerencial',
              children: [
                { element: <Navigate to="/report/performance/gerencial/advisor" replace />, index: true },
                { path: 'advisor', element: <GerencialAdvisor /> },
                { path: 'leader', element: <GerencialLeader /> },
              ]
            },
            {
              path: 'corretagem',
              children: [
                { element: <Navigate to="/report/performance/corretagem/advisor" replace />, index: true },
                { path: 'advisor', element: <CorretagemAdvisor /> },
                { path: 'leader', element: <CorretagemLeader /> },
              ]
            },
            { path: 'bases', element: <Bases /> },
            {
              path: 'receita',
              children: [
                { element: <Navigate to="/report/performance/receita/advisor" replace />, index: true },
                { path: 'advisor', element: <Receita /> },
                { path: 'leader', element: <ReceitaLeader /> },
              ]
            },
          ],
        },

        {
          path: 'oportunidades',
          children: [
            { element: <Navigate to="/report/oportunidades/ativacoes" replace />, index: true },
            {
              path: 'ativacoes',
              children: [
                { element: <Navigate to="/report/oportunidades/ativacoes/advisor" replace />, index: true },
                { path: 'advisor', element: <AtivacoesAdvisor /> },
                { path: 'leader', element: <AtivacoesLeader /> },
              ]
            },
          ],
        },

        {
          path: 'ranking',
          children: [
            { element: <Navigate to="/report/ranking/follow-up" replace />, index: true },
            {
              path: 'follow-up',
              children: [
                { element: <Navigate to="/report/ranking/follow-up/advisor" replace />, index: true },
                { path: 'advisor', element: <RankingAdvisor /> },
                { path: 'leader', element: <RankingLeader /> },
              ]
            },
          ],
        },

        {
          path: 'esforco',
          children: [
            { element: <Navigate to="/report/esforco/crmleader" replace />, index: true },
            { path: 'crmadvisor', element: <CRMAdvisor /> },
            { path: 'crmleader', element: <CRMLeader /> }
          ],
        },

        {
          path: 'campanhas',
          children: [
            { element: <Navigate to="/report/campanhas/crm" replace />, index: true },
            { path: 'crm', element: <CRM /> },
          ],
        },

        {
          path: 'reunioes',
          children: [
            { element: <Navigate to="/report/reunioes/n2" replace />, index: true },
            { path: 'n2', element: <N2 /> },
            { path: 'n3', element: <N3 /> },
            { path: 'newN3', element: <NewN3 />, index: true }, 
          ],
        },
      ],
    },
  ]);
}

// SUPER RANKING LEADER
const SuperRankingLeaderGeneral = Loadable(lazy(() => import('../../../../pages/VI/ASSES/general/power-bi/SuperRankingLeaderGeneral')));
const SuperRankingLeaderTeam = Loadable(lazy(() => import('../../../../pages/VI/ASSES/LEADER/power-bi/SuperRankingLeaderTeam')));

// REPORT
const GerencialAdvisor = Loadable(lazy(() => import('../../../../pages/VI/ASSES/general/power-bi/performance/GerencialAdvisor')));
const GerencialLeader = Loadable(lazy(() => import('../../../../pages/VI/ASSES/LEADER/power-bi/performance/GerencialLeader')));

const CorretagemAdvisor = Loadable(lazy(() => import('../../../../pages/VI/ASSES/general/power-bi/performance/CorretagemAdvisor')));
const CorretagemLeader = Loadable(lazy(() => import('../../../../pages/VI/ASSES/LEADER/power-bi/performance/CorretagemLeader')));

const Bases = Loadable(lazy(() => import('../../../../pages/VI/ASSES/general/power-bi/performance/Bases')));
const Receita = Loadable(lazy(() => import('../../../../pages/VI/ASSES/general/power-bi/performance/Receita')));
const ReceitaLeader = Loadable(lazy(() => import('../../../../pages/VI/ASSES/general/power-bi/performance/ReceitaLeader')));

const CRMAdvisor = Loadable(lazy(() => import('../../../../pages/VI/ASSES/general/power-bi/esforco/CRMAdvisor')));
const CRMLeader = Loadable(lazy(() => import('../../../../pages/VI/ASSES/general/power-bi/esforco/CRMLeader')));

const AtivacoesAdvisor = Loadable(lazy(() => import('../../../../pages/VI/ASSES/general/power-bi/oportunidades/AtivacoesAdvisor')));
const AtivacoesLeader = Loadable(lazy(() => import('../../../../pages/VI/ASSES/LEADER/power-bi/oportunidades/AtivacoesLeader')));

const N2 = Loadable(lazy(() => import('../../../../pages/VI/ASSES/LEADER/power-bi/reunioes/N2')));
const N3 = Loadable(lazy(() => import('../../../../pages/VI/ASSES/general/power-bi/reunioes/N3')));
const NewN3 = Loadable(lazy(() => import('../../../../pages/VI/ASSES/LEADER/power-bi/reunioes/NewN3')));

const RankingAdvisor = Loadable(lazy(() => import('../../../../pages/VI/ASSES/general/power-bi/ranking/RankingAdvisor')));
const RankingLeader = Loadable(lazy(() => import('../../../../pages/VI/ASSES/LEADER/power-bi/ranking/RankingLeader')));

const CRM = Loadable(lazy(() => import('../../../../pages/VI/ASSES/general/power-bi/campaign/CRM')));
// const Ativacao300kConsulta = Loadable(lazy(() => import('../../../../pages/VI/ASSES/general/power-bi/campaign/ativacao300k/Consulta')));

// DIGITAL
const DigitalLeader = Loadable(lazy(() => import('../../../../pages/VI/DIGI/LEADER/DigitalLeader')));
