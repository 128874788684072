// ----------------------------------------------------------------------

function path(root, sublink) {
  return `${root}${sublink}`;
}

const ROOTS_SETTINGS = '/settings';

// ----------------------------------------------------------------------


export const PATH_SETTINGS = {
  root: ROOTS_SETTINGS,
  user: {
    root: path(ROOTS_SETTINGS, '/user'),
    list: path(ROOTS_SETTINGS, '/user/list'),
    newUser: path(ROOTS_SETTINGS, '/user/new'),
    updates: path(ROOTS_SETTINGS, '/user/updates'),
  },
  profiles: path(ROOTS_SETTINGS, '/profiles'),
};
