// components
import SvgIconStyle from '../../../components/SvgIconStyle';

// ----------------------------------------------------------------------

const getIcon = (name) => <SvgIconStyle src={`/icons/${name}.svg`} sx={{ width: 1, height: 1 }} />;

const ICONS = {
  user: getIcon('ic_user'),
  analytics: getIcon('ic_analytics'),
  dashboard: getIcon('ic_dashboard'),
};

const navConfigBlank = [
  {
    items: [
      {
        title: 'Home',
        path: '/',
        icon: ICONS.dashboard,
      },
    ],
  },
];

export default navConfigBlank;
