// ----------------------------------------------------------------------

export default function Stepper(theme) {
  return {
    MuiStepConnector: {
      styleOverrides: {
        line: {
          borderColor: theme.palette.divider,
        },
      },
    },
    MuiStepIcon: {
      styleOverrides: {
        root: {
          '&.Mui-completed': {
            color: theme.palette.mode === 'dark' ? theme.palette.grey[200] : theme.palette.primary.main
          }
        }
      }
    }
  };
}
