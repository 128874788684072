// ----------------------------------------------------------------------

function path(root, sublink) {
    return `${root}${sublink}`;
  }
  
  const ROOTS_REPORT = '/report';
  
  // ----------------------------------------------------------------------
  
  export const PATH_REPORT = {
    root: ROOTS_REPORT,
    clientHistory: path(ROOTS_REPORT, '/clienthistory'),
    bases: path(ROOTS_REPORT, '/bases'),
    eventos: path(ROOTS_REPORT, '/events'),
    sdrleads: path(ROOTS_REPORT, '/sdrleads')
  };
  