// ----------------------------------------------------------------------

function path(root, sublink) {
  return `${root}${sublink}`;
}

const ROOTS_REPORT = '/report';

// ----------------------------------------------------------------------

export const PATH_REPORT = {
  root: ROOTS_REPORT,
  profiles: path(ROOTS_REPORT, '/profiles'),
  gerencial: path(ROOTS_REPORT, '/gerencial'),
  campaigns: path(ROOTS_REPORT, '/campaigns'),
  captacao: path(ROOTS_REPORT, '/captacao'),
  metas: path(ROOTS_REPORT, '/metas'),
  crossSell: path(ROOTS_REPORT, '/cross-sell'),
  pj: path(ROOTS_REPORT, '/pj')
};
