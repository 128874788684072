import axios from 'axios';
import PropTypes from 'prop-types';
import { Storage } from 'aws-amplify';
import { useEffect, useState, useRef } from 'react';
// @mui
import { Box, Button, ButtonBase, Card, CircularProgress, Dialog, DialogActions, DialogContent, Stack, ToggleButton, ToggleButtonGroup, Typography, useTheme } from '@mui/material';
// components
import Image from './Image';
import Iconify from './Iconify';
import Markdown from './Markdown';
// hooks 
import useAuth from '../hooks/useAuth';
// 
import { ANNOUNCEMENTS_API } from '../config';

AnnouncementDialog.propTypes = {
  onLogin: PropTypes.bool,
  openDialog: PropTypes.bool,
  setOpenDialog: PropTypes.func,
  announcements: PropTypes.array,
  setAnnouncements: PropTypes.func,
  setUserAnnouncements: PropTypes.func
}
export default function AnnouncementDialog({ openDialog, onLogin, setOpenDialog, announcements, setAnnouncements, setUserAnnouncements }) {
  const [cover, setCover] = useState(null);
  const [loading, setLoading] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [currentAnnouncement, setCurrentAnnouncement] = useState(null);

  const announcementsStatus = useRef({}); // unmount function just read refs (not states)

  const theme = useTheme();
  const { getIdToken } = useAuth();

  useEffect(() => {
    if (announcements && announcements.length) {
      setCurrentAnnouncement(announcements[0]);
      getAnnouncement(announcements[0].id); // get body
      const status = {};
      announcements.forEach(e => status[e.id] = 'remember');
      announcementsStatus.current = status;
    }
    // eslint-disable-next-line
  }, [announcements])

  useEffect(() => async () => {
    const idToken = await getIdToken();
    try {
      if (onLogin) {
        await axios.request({
          url: `${ANNOUNCEMENTS_API}/popupdate`,
          method: 'POST',
          headers: {
            Authorization: `Bearer ${idToken}`,
            'Access-Control-Allow-Origin': '*',
            Accept: '*/*'
          }
        });
      } else {
        const promises = announcements.map((e) => {
          const data = {
            announcement: e.id,
            startDate: e.startDate,
            status: announcementsStatus.current?.[e.id]
          }
          return axios.request({
            url: `${ANNOUNCEMENTS_API}/status`,
            method: 'POST',
            data,
            headers: {
              Authorization: `Bearer ${idToken}`,
              'Access-Control-Allow-Origin': '*',
              Accept: '*/*'
            }
          });
        });

        await Promise.all(promises);
      }

      let reads = 0;
      Object.values(announcementsStatus.current).forEach(e => reads += e === 'read' ? 1 : 0);
      setUserAnnouncements(prev => (
        {
          ...prev,
          ...(onLogin && { lastPopupDate: Date.now() }),
          unreadCount: Number(prev.unreadCount) - reads,
          Items: prev.Items.map(e => {
            const status = announcementsStatus.current[e.id];
            if (status) {
              e.status = status;
              if (!onLogin)
                e.lastPopupDate = Date.now();
            }

            return e;
          })
        }
      ));
      setAnnouncements(null);
    } catch (e) {
      setAnnouncements(null);
    }
    // eslint-disable-next-line  
  }, []);


  const getAnnouncement = async (id) => {
    if (!id)
      return
    setLoading(true);
    const idToken = await getIdToken();
    const resp = await axios.request({
      url: `${ANNOUNCEMENTS_API}/announcement/${id}`,
      method: 'GET',
      headers: {
        Authorization: `Bearer ${idToken}`,
        'Access-Control-Allow-Origin': '*',
        Accept: '*/*'
      }
    });
    // setCurrentAnnouncement(resp.data);
    if (resp.data.cover) {
      const key = `announcements/cover/${resp.data.cover}`;
      Storage.get(key, {
        download: true,
        level: 'public',
        cacheControl: 'no-cache'
      }).then(resp => {
        const img = URL.createObjectURL(resp.Body);
        setCover(img);
        setLoading(false);
      }).catch(e => {
        setLoading(false);
        console.log(e);
      });
    } 

    if (resp.data.hasBody) {
      const announcement_ = resp.data;
      Storage.get(`announcements/body/${id}`, {
        download: true,
        level: 'public',
        cacheControl: 'no-cache'
      }).then(async resp => {
        const body = await resp.Body.text();
        announcement_.body = body;
        setCurrentAnnouncement(announcement_);
        setLoading(false);
      }).catch(e => {
        setCurrentAnnouncement(resp.data);
        setLoading(false);
        console.log(e);
      });
    } else setCurrentAnnouncement(resp.data);
  }

  const putReaction = async (reaction) => {
    if (currentAnnouncement.reaction === reaction)
      return

    const newReactions = { ...currentAnnouncement.reactions };
    if (currentAnnouncement.reaction)
      newReactions[currentAnnouncement.reaction] = Number(newReactions[currentAnnouncement.reaction]) - 1;
    newReactions[reaction] = Number(newReactions[reaction]) + 1;

    setCurrentAnnouncement(prev => ({ ...prev, reaction, reactions: newReactions }));
    try {
      const idToken = await getIdToken();
      const data = {
        announcement: currentAnnouncement.id,
        startDate: currentAnnouncement.startDate,
        reaction
      }

      await axios.request({
        url: `${ANNOUNCEMENTS_API}/reaction`,
        method: 'POST',
        data,
        headers: {
          Authorization: `Bearer ${idToken}`,
          'Access-Control-Allow-Origin': '*',
          Accept: '*/*'
        }
      });
    }
    catch (e) {
      console.log(e);
    }
  }

  const putStatus = async (status) => {
    if (!currentAnnouncement || currentAnnouncement.status === 'read')
      return

    const { id } = currentAnnouncement;
    announcementsStatus.current = { ...announcementsStatus.current, [id]: status }

    try {
      const idToken = await getIdToken();
      const data = {
        announcement: currentAnnouncement.id,
        startDate: currentAnnouncement.startDate,
        status
      }

      await axios.request({
        url: `${ANNOUNCEMENTS_API}/status`,
        method: 'POST',
        data,
        headers: {
          Authorization: `Bearer ${idToken}`,
          'Access-Control-Allow-Origin': '*',
          Accept: '*/*'
        }
      });
    }
    catch (e) {
      console.log(e);
    }
  }

  const handleNext = () => {
    if (currentIndex === announcements.length - 1)
      setOpenDialog(false);
    else {
      setCover(null);
      setCurrentAnnouncement(announcements[currentIndex + 1]);
      getAnnouncement(announcements[currentIndex + 1]?.id);
      setCurrentIndex(prev => prev + 1);
    }
  }

  return (
    <Dialog
      open={openDialog}
      onClose={() => setOpenDialog(false)}
      maxWidth='lg'
    >
      <DialogContent>
        {announcements?.length > 1 &&
          <ToggleButtonGroup
            exclusive
            value={currentIndex}
            onChange={(e, newValue) => {
              if (newValue === null || newValue === currentIndex)
                return
              setCurrentIndex(newValue);
              setCover(null);
              setCurrentAnnouncement(announcements[newValue]);
              getAnnouncement(announcements[newValue]?.id);
            }}
            sx={{ mb: 1, height: 40 }}
          >
            {announcements.map((a, i) =>
              <ToggleButton key={i} value={i}>
                {i + 1}
              </ToggleButton>
            )}
          </ToggleButtonGroup>
        }

        <Card sx={{ pb: 2, px: 2 }}>
          <Typography variant='h3' sx={{ py: 2 }}>
            {currentAnnouncement?.title}
          </Typography>

          <Typography variant="h6" sx={{ pb: 2 }}>
            {currentAnnouncement?.subtitle}
          </Typography>

          {cover &&
            <Image src={cover} align='center' />
          }

          {loading ?
            <Stack direction='row' justifyContent='center'>
              <CircularProgress color="inherit" size={40} />
            </Stack>
            :
            <Box>
              <Markdown children={currentAnnouncement?.body} />
            </Box>
          }
        </Card>
      </DialogContent>

      <DialogActions sx={{ pt: '0 !important', display: 'flex', justifyContent: 'space-between' }}>
        <Stack direction='row' justifyContent='right' color={theme.palette.grey[600]} sx={{ mt: 1 }}>
          {currentAnnouncement?.reactions?.good}
          <ButtonBase onClick={() => putReaction('good')}>
            <Iconify icon='ph:smiley-fill'
              sx={{
                mr: 0.5,
                fontSize: 25,
                ...(currentAnnouncement?.reaction === 'good' && { color: 'green' }),
                '&:hover': {
                  color: 'green'
                }
              }}
            />
          </ButtonBase>

          {currentAnnouncement?.reactions?.neutral}
          <ButtonBase onClick={() => putReaction('neutral')}>
            <Iconify icon='ph:smiley-meh-fill'
              sx={{
                mr: 0.5,
                fontSize: 25,
                ...(currentAnnouncement?.reaction === 'neutral' && { color: theme.palette.warning.main }),
                '&:hover': {
                  color: theme.palette.warning.main
                }
              }}
            />
          </ButtonBase>

          {currentAnnouncement?.reactions?.bad}
          <ButtonBase onClick={() => putReaction('bad')}>
            <Iconify icon='ph:smiley-sad-fill'
              sx={{
                mr: 1,
                fontSize: 25,
                ...(currentAnnouncement?.reaction === 'bad' && { color: theme.palette.error.main }),
                '&:hover': {
                  color: theme.palette.error.main
                }
              }}
            />
          </ButtonBase>

          {currentAnnouncement?.readCounter}
          <Iconify icon='ic:baseline-remove-red-eye' sx={{ fontSize: 25, ml: 0.5 }} />
        </Stack>

        <Stack direction='row' spacing={1}>
          {announcementsStatus.current?.[currentAnnouncement?.id] !== 'read' ?
            <>
              <Button
                disabled={announcementsStatus.current?.[currentAnnouncement?.id] === 'read'}
                onClick={() => {
                  putStatus('remember');
                  handleNext();
                }}
              >
                Lembrar-me mais tarde
              </Button>
              <Button
                variant='contained'
                disabled={announcementsStatus.current?.[currentAnnouncement?.id] === 'read'}
                onClick={() => {
                  putStatus('read');
                  handleNext();
                }}
              >
                Marcar como lido
              </Button>
            </>
            :
            <Typography sx={{ display: 'flex', alignItems: 'center' }}>
              Lido
              <Iconify icon='material-symbols:check-circle-rounded' sx={{ ml: 0.5 }} />
            </Typography>
          }
        </Stack>
      </DialogActions>

    </Dialog>
  )
}