import AWS from 'aws-sdk';
import { COGNITO_API } from '../config';

import createUser from './createUser';

export default async function resetTotp4User(userdata) {
  const cognitoClient = new AWS.CognitoIdentityServiceProvider({
    apiVersion: '2016-04-19',
    region: COGNITO_API.region,
  });

  const params = {
    UserPoolId: COGNITO_API.CognitoID,
    Username: userdata.Username || userdata.username,
  };

  async function adminResetTotp() {
    try {
      await cognitoClient.adminDeleteUser(params).promise();
      await createUser(userdata);
      return { code: 200, username: params.Username };
    } catch (error) {
      return { code: 404, message: error.message };
    }
  }

  return adminResetTotp();
}
