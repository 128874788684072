// routes
import { PATH_REPORT } from '../../../../../../routes/VI/ASSES/paths';
import { PATH_S_RANKING_LEADER } from '../../../../../../routes/generalPaths';
// components
import SvgIconStyle from '../../../../../../components/SvgIconStyle';

// ----------------------------------------------------------------------

const getIcon = (name) => <SvgIconStyle src={`/icons/${name}.svg`} sx={{ width: 1, height: 1 }} />;

const ICONS = {
  user: getIcon('ic_user'),
  form: getIcon('ic_form'),
  upload: getIcon('ic_upload'),
  analytics: getIcon('ic_analytics'),
  dashboard: getIcon('ic_dashboard'),
  maintenance: getIcon('ic_maintenance'),
  search: getIcon('ic_search'),
  speaker: getIcon('ic_speaker'),
  target: getIcon('ic_target'),
  question: getIcon('ic_question'),
  export: getIcon('ic_analytics_export'),
};

export const navConfig = [  

  // SUPER RANKING LÍDERES
  {
    subheader: 'Super Ranking Líderes',
    group: 'Rankings',
    path: PATH_S_RANKING_LEADER.root,
    icon: ICONS.analytics,
    items: [
      {
        title: 'Geral',
        path: PATH_S_RANKING_LEADER.general,
        icon: ICONS.analytics
      }
    ]
  },

  // RANKING
  // {
  //   subheader: 'Ranking',
  //   items: [
  //     {
  //       title: 'Assessoria',
  //       path: PATH_REPORT.ranking.root,
  //       icon: ICONS.analytics,
  //       children: [
  //         { title: 'Individual', path: PATH_REPORT.ranking.followUp.advisor },
  //         { title: 'Equipe', path: PATH_REPORT.ranking.followUp.leader },
  //         { title: 'Unidade', path: PATH_REPORT.ranking.followUp.manager },
  //       ],
  //     }
  //   ]
  // },  
  {
    subheader: 'PAN',
    group: 'Bônus',
    path: PATH_REPORT.pan.root,
    icon: ICONS.analytics,
    items: [
      {
        title: 'PAN 1.0',
        path: PATH_REPORT.pan.pan,
        icon: ICONS.analytics,
      },
      {
        title: 'PAN 2.0',
        path: PATH_REPORT.pan.pan2,
        icon: ICONS.analytics,
      }
    ]
  },

  {
    subheader: 'Esforço',
    group: 'Indicadores',
    icon: ICONS.analytics,
    items: [
      {
        title: 'Acompanhamento',
        path: PATH_REPORT.esforco.crmLeader,
        icon: ICONS.analytics,
      },
    ],
  },
]