import { current, createSlice } from '@reduxjs/toolkit';
// utils
import axios from '../../utils/axios';
//
import { dispatch } from '../store';
// config
import { TRANSFER_API } from '../../config';
// context
import listUser from '../../contexts/listUser';

// ----------------------------------------------------------------------

function objFromArray(array, key = 'id') {
  return array.reduce((accumulator, current) => {
    accumulator[current[key]] = current;
    return accumulator;
  }, {});
}

const initialState = {
  isLoading: false,
  error: null,
  board: {
    cards: {},
    columns: {},
    columnOrder: [],
  },
  profiles: [],
};

const slice = createSlice({
  name: 'transfer',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET BOARD
    getBoardSuccess(state, action) {
      state.isLoading = false;
      const board = action.payload;
      const cards = objFromArray(board.cards);
      const columns = objFromArray(board.columns);
      const { columnOrder } = board;
      state.board = {
        cards,
        columns,
        columnOrder,
      };
    },

    // GET PROFILES
    getProfilesSuccess(state, action) {
      state.isLoading = false;
      state.profiles = action.payload;
    },

    persistCard(state, action) {
      const columns = action.payload;
      state.board.columns = columns;
    },

    persistCardDetails(state, action) {
      const { id, values } = action.payload;
      const currentCards = current(state.board.cards);
      const newCards = { ...currentCards, [id]: { ...currentCards[id], ...values } };
      state.board.cards = newCards;
    },

    persistColumn(state, action) {
      state.board.columnOrder = action.payload;
    }
  },
});

// Reducer
export default slice.reducer;

export const { actions } = slice;

// ----------------------------------------------------------------------

export function getBoard(username, userRole) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.request({
        url: userRole === 'DEV' || userRole === 'ADMIN' ? `${TRANSFER_API}/list` : `${TRANSFER_API}/officer/${username}`,
        method: 'GET',
      });
      dispatch(slice.actions.getBoardSuccess(response.data?.items?.board));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getProfiles() {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const profiles = await listUser();
      profiles.sort((a, b) => {
        if(a.access_level === 'MANAGER' && b.access_level !== 'MANAGER')
          return -1;
        if(a.access_level !== 'MANAGER' && b.access_level === 'MANAGER')
          return 1;
        if(a.access_level === 'LEADER' && b.access_level !== 'MANAGER' && b.access_level !== 'LEADER')  
          return -1;
        if(a.access_level !== 'LEADER' && a.access_level !== 'MANAGER' && b.access_level === 'LEADER')  
          return 1;
        return 0;
      });
      // profiles = profiles.filter(e => e.access_level === "MANAGER");
      // profiles = profiles.map(e => e.Username);
      dispatch(slice.actions.getProfilesSuccess(profiles));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function persistColumn(newColumnOrder) {
  return () => {
    dispatch(slice.actions.persistColumn(newColumnOrder));
  };
}

// ----------------------------------------------------------------------

export function persistCard(columns) {
  return () => {
    dispatch(slice.actions.persistCard(columns));
  };
}

// ----------------------------------------------------------------------

export function persistCardDetails({ id, values }) {
  return () => {
    dispatch(slice.actions.persistCardDetails({ id, values }));
  };
}
