import { Suspense, lazy } from 'react';
import { Navigate, useRoutes, useLocation } from 'react-router-dom';
// layouts
import DashboardLayout from '../../../layouts/dashboard';
// hooks 
import useAuth from '../../../hooks/useAuth';
// components
import LoadingScreen from '../../../components/LoadingScreen';
// default routes
import defaultRouter from '../../defaultRouter';

// ----------------------------------------------------------------------

const Loadable = (Component) => (props) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { pathname } = useLocation();

  return (
    <Suspense fallback={<LoadingScreen isDashboard={
      pathname.includes('/test') ||
      pathname.includes('/settings')} />}>
      <Component {...props} />
    </Suspense>
  );
};

export function Router() {
  const { user, username, userRole, userBranch, userDepartment, userRoutes } = useAuth();

  return useRoutes([
    ...defaultRouter({ defaultRoute: "/home", user, username, userRole, userDepartment, userBranch, userRoutes }),

    {
      path: 'test',
      element: <DashboardLayout />,
      children: [
        { element: <Navigate to="/test/base" replace />, index: true },
        { path: 'base', element: <GeneralBase /> },
        { path: 'orgstructure', element: <OrgStructure /> },
        { path: 'bi', element: <BiTeste /> },
        { path: 'composite', element: <CompositeModel /> },        
        { path: 'vgr', element: <ConsolidadaVGR /> },
        { path: 'npscalculator', element: <NpsCalculator /> },
      ]
    },

    {
      path: 'acessos',
      element: <DashboardLayout />,
      children: [
        { element: <Acessos />, index: true },
      ]
    },

    {
      path: 'announcements',
      element: <DashboardLayout />,
      children: [
        { path: 'new', element: <NewAnnouncement /> },
        { path: ':id/edit', element: <NewAnnouncement isEdit /> },
        { path: 'management', element: <AnnouncementsManagement /> },
      ]
    },

    {
      path: 'settings',
      element: <DashboardLayout />,
      children: [
        {
          path: 'user',
          children: [
            { element: <Navigate to="/settings/user/list" replace />, index: true },
            { path: 'list', element: <UserList /> },
            { path: 'new', element: <UserCreate /> },
            { path: 'mass', element: <UserCreateMass /> },
            { path: ':username/edit', element: <UserCreate /> },
            { path: ':username/history', element: <UserHistory /> },
            { path: 'updates', element: <UserUpdateLogs /> },
            { path: 'cognito-logs', element: <CognitoLogs /> },
          ],
        },
        {
          path: 'report',
          children: [
            { element: <Navigate to="/settings/report/list" replace />, index: true },
            { path: 'list', element: <ReportList /> },
            { path: ':reportkey/edit', element: <ReportCreate /> },
          ],
        },

        {
          path: 'campaign',
          children: [
            { element: <Navigate to="/settings/campaign/list" replace />, index: true },
            { path: 'list', element: <CampaignList /> },
            { path: ':campaignId/edit', element: <CampaignCreate /> },
          ],
        },

        { path: 'org', element: <Organizational /> },
      ],
    },
  ]);
}

// TEST
const GeneralBase = Loadable(lazy(() => import('../../../pages/VI/general/GeneralBase')));
const BiTeste = Loadable(lazy(() => import('../../../pages/VI/DADOS/test/BiTeste')));
const CompositeModel = Loadable(lazy(() => import('../../../pages/VI/DADOS/test/CompositeModel')));
const OrgStructure = Loadable(lazy(() => import('../../../pages/general/orgchart/OrgStructure')));
const NewAnnouncement = Loadable(lazy(() => import('../../../pages/general/announcements/NewAnnouncement')));
const AnnouncementsManagement = Loadable(lazy(() => import('../../../pages/general/announcements/Management')));
const ConsolidadaVGR = Loadable(lazy(() => import('../../../pages/VGR/general/ConsolidadaVGR')));
const NpsCalculator = Loadable(lazy(() => import('../../../pages/general/npscalculator/NpsCalculator')));
// PRODUCTS
// const RendaVariavel = Loadable(lazy(() => import('../../../pages/general/products/RendaVariavel')));

const Acessos = Loadable(lazy(() => import('../../../pages/VI/ASSES/MANAGER/power-bi/Acessos')));

// SETTINGS
const Organizational = Loadable(lazy(() => import('../../../pages/VI/general/Organizational')));
// user
const UserList = Loadable(lazy(() => import('../../../pages/VI/general/user/UserList')));
const UserCreate = Loadable(lazy(() => import('../../../pages/VI/general/user/UserCreate')));
const UserHistory = Loadable(lazy(() => import('../../../pages/VI/general/user/UserHistory')));
const CognitoLogs = Loadable(lazy(() => import('../../../pages/VI/DADOS/cognito/CognitoLogs')));
const UserCreateMass = Loadable(lazy(() => import('../../../pages/VI/general/user/UserCreateMass')));
const UserUpdateLogs = Loadable(lazy(() => import('../../../pages/VI/general/user/UserUpdateLogs')));
// report
const ReportList = Loadable(lazy(() => import('../../../pages/VI/DADOS/report/ReportList')));
const ReportCreate = Loadable(lazy(() => import('../../../pages/VI/DADOS/report/ReportCreate')));
// campaign
const CampaignList = Loadable(lazy(() => import('../../../pages/VI/DADOS/campaigns/CampaignList')));
const CampaignCreate = Loadable(lazy(() => import('../../../pages/VI/DADOS/campaigns/CampaignCreate')));
