// components
import SvgIconStyle from '../../../../../../components/SvgIconStyle';
// routes
import { PATH_REPORT } from '../../../../../../routes/VI/ASSES/paths';
import { PATH_S_RANKING_LEADER } from '../../../../../../routes/generalPaths';
import { PATH_REPORT as PATH_DIGI } from '../../../../../../routes/VI/DIGI/paths';

// ----------------------------------------------------------------------

const getIcon = (name) => <SvgIconStyle src={`/icons/${name}.svg`} sx={{ width: 1, height: 1 }} />;

const ICONS = {
  user: getIcon('ic_user'),
  form: getIcon('ic_form'),
  upload: getIcon('ic_upload'),
  analytics: getIcon('ic_analytics'),
  dashboard: getIcon('ic_dashboard'),
  maintenance: getIcon('ic_maintenance'),
  search: getIcon('ic_search'),
  speaker: getIcon('ic_speaker'),
  target: getIcon('ic_target'),
  question: getIcon('ic_question'),
  export: getIcon('ic_analytics_export'),
};

export const navConfig = [

  {
    group: 'Indicadores',
    subheader: 'Digital',
    icon: ICONS.analytics,
    path: PATH_DIGI.digital.root,
    items: [
      {
        title: 'Líder',
        path: PATH_DIGI.digital.leader,
        icon: ICONS.analytics,
      },
    ]
  },

  // CAMPANHAS
  // ----------------------------------------------------------------------
  {
    group: 'Campanhas',
    items: [
      {
        title: 'CRM',
        path: PATH_REPORT.campanhas.crm,
        icon: ICONS.analytics,
      },
    ],
  },

  // SUPER RANKING LÍDERES
  {
    subheader: 'Super Ranking Líderes',
    group: 'Rankings',
    path: PATH_S_RANKING_LEADER.root,
    icon: ICONS.analytics,
    items: [
      {
        title: 'Geral',
        path: PATH_S_RANKING_LEADER.general,
        icon: ICONS.analytics
      },
      {
        title: 'Equipe',
        path: PATH_S_RANKING_LEADER.team,
        icon: ICONS.analytics
      }
    ]
  },

  // PERFORMANCE
  // ----------------------------------------------------------------------
  {
    subheader: 'Performance',
    group: 'Indicadores',
    // path: PATH_REPORT.performance.root,
    icon: ICONS.analytics,
    items: [
      {
        title: 'Gerencial',
        path: PATH_REPORT.performance.gerencial.root,
        icon: ICONS.analytics,
        children: [
          { title: 'Individual', path: PATH_REPORT.performance.gerencial.advisor },
          { title: 'Equipe', path: PATH_REPORT.performance.gerencial.leader },
        ],
      },
      {
        title: 'ROAe',
        path: PATH_REPORT.performance.receitaAdvisor,
        icon: ICONS.analytics,
        children: [
          { title: 'Individual', path: PATH_REPORT.performance.receitaAdvisor },
          { title: 'Equipe', path: PATH_REPORT.performance.receitaLeader },
        ]
      },
      {
        title: 'Corretagem',
        path: PATH_REPORT.performance.corretagem.root,
        icon: ICONS.analytics,
        children: [
          { title: 'Individual', path: PATH_REPORT.performance.corretagem.advisor },
          { title: 'Equipe', path: PATH_REPORT.performance.corretagem.leader },
        ],
      },
      {
        title: 'Bases',
        path: PATH_REPORT.performance.bases,
        icon: ICONS.analytics,
      },
      {
        title: 'Cross Sell',
        path: PATH_REPORT.performance.xsell,
        icon: ICONS.analytics,
      },    
    ],
  },

  // OPORTUNIDADES
  // ----------------------------------------------------------------------
  {
    subheader: 'Oportunidades',
    group: 'Indicadores',
    path: PATH_REPORT.oportunidades.root,
    icon: ICONS.analytics,
    items: [
      {
        title: 'Ativações',
        path: PATH_REPORT.oportunidades.root,
        icon: ICONS.analytics,
        children: [
          { title: 'Individual', path: PATH_REPORT.oportunidades.ativacoes.advisor },
          { title: 'Equipe', path: PATH_REPORT.oportunidades.ativacoes.leader },
        ],
      },
    ],
  },

  // ESFORÇO
  // ----------------------------------------------------------------------
  {
    subheader: 'Esforço',
    group: 'Indicadores',
    path: PATH_REPORT.esforco.root,
    icon: ICONS.analytics,
    items: [
      {
        title: 'Individual',
        path: PATH_REPORT.esforco.crmAdvisor,
        icon: ICONS.analytics
      },
      {
        title: 'Equipe',
        path: PATH_REPORT.esforco.crmLeader,
        icon: ICONS.analytics,
      },
    ],
  },


  // REUNIÕES
  // ----------------------------------------------------------------------
  {
    subheader: 'Reuniões',
    group: 'Indicadores',
    path: PATH_REPORT.reunioes.root,
    icon: ICONS.analytics,
    items: [
      {
        title: 'N2',
        path: PATH_REPORT.reunioes.n2,
        icon: ICONS.analytics,
      },
      {
        title: 'N3',
        path: PATH_REPORT.reunioes.n3,
        icon: ICONS.analytics,
      },
      {
        title: 'Novo N3',
        path: PATH_REPORT.reunioes.newN3,
        icon: getIcon('ic_analytics_export')
      }
    ],
  },
];