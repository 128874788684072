import { Suspense, lazy } from 'react';
import { Navigate, useRoutes, useLocation } from 'react-router-dom';
// layouts
import DashboardLayout from '../../../../layouts/dashboard';
// components
import LoadingScreen from '../../../../components/LoadingScreen';
// hooks 
import useAuth from '../../../../hooks/useAuth';
// default routes
import defaultRouter from '../../../defaultRouter';

// ----------------------------------------------------------------------

const Loadable = (Component) => (props) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { pathname } = useLocation();

  return (
    <Suspense fallback={<LoadingScreen isDashboard={
      pathname.includes('/dashboard') ||
      pathname.includes('/report')} />}>
      <Component {...props} />
    </Suspense>
  );
};

export function Router() {
  const { user, username, userRole, userDepartment, userRoutes } = useAuth();

  return useRoutes([
    ...defaultRouter({ defaultRoute: "/home", user, username, userRole, userDepartment, userRoutes }),

    {
      path: 'report',
      element: <DashboardLayout />,
      children: [
        { element: <Navigate to="/report/performance" replace />, index: true },

        {
          path: 'performance',
          children: [
            { element: <Navigate to="/report/performance/gerencial" replace />, index: true },
            {
              path: 'gerencial',
              children: [
                { element: <Navigate to="/report/performance/gerencial/advisor" replace />, index: true },
                { path: 'advisor', element: <Gerencial /> },
              ]
            },
            { path: 'bases', element: <Bases /> },
            { path: 'receita', element: <Receita /> },
            {
              path: 'metas',
              children: [
                { element: <Navigate to="/report/performance/metas/farmer" replace />, index: true },
                { path: 'farmer', element: <Metas /> },
              ]
            },
            {
              path: 'corretagem',
              children: [
                { element: <Navigate to="/report/performance/corretagem/advisor" replace />, index: true },
                { path: 'advisor', element: <Corretagem /> },
              ]
            },
          ],
        },

        {
          path: 'oportunidades',
          children: [
            { element: <Navigate to="/report/oportunidades/ativacoes" replace />, index: true },
            {
              path: 'ativacoes',
              children: [
                { element: <Navigate to="/report/oportunidades/ativacoes/advisor" replace />, index: true },
                { path: 'advisor', element: <Ativacoes /> },
              ]
            },
          ],
        },

        {
          path: 'esforco',
          children: [
            { element: <Navigate to="/report/esforco/leads" replace />, index: true },
            {
              path: 'leads',
              children: [
                { element: <Navigate to="/report/esforco/leads/advisor" replace />, index: true },
                { path: 'advisor', element: <Leads /> },
              ]
            },
            {
              path: 'regua',
              children: [
                { element: <Navigate to="/report/esforco/regua/advisor" replace />, index: true },
                { path: 'advisor', element: <Regua /> },
              ]
            },
          ],
        },
      ],
    },
  ]);
}

// REPORT
const Bases = Loadable(lazy(() => import('../../../../pages/VI/ASSES/general/power-bi/performance/Bases')));
const Leads = Loadable(lazy(() => import('../../../../pages/VI/ASSES/general/power-bi/esforco/LeadsAdvisor')));
const Regua = Loadable(lazy(() => import('../../../../pages/VI/ASSES/general/power-bi/esforco/ReguaAdvisor')));
const Receita = Loadable(lazy(() => import('../../../../pages/VI/ASSES/general/power-bi/performance/Receita')));
const Metas = Loadable(lazy(() => import('../../../../pages/VI/ASSES/general/power-bi/performance/MetasFarmer')));
const Gerencial = Loadable(lazy(() => import('../../../../pages/VI/ASSES/general/power-bi/performance/GerencialAdvisor')));
const Ativacoes = Loadable(lazy(() => import('../../../../pages/VI/ASSES/general/power-bi/oportunidades/AtivacoesAdvisor')));
const Corretagem = Loadable(lazy(() => import('../../../../pages/VI/ASSES/general/power-bi/performance/CorretagemAdvisor')));
