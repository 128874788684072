// ----------------------------------------------------------------------

export default function Switch(theme) {
  const isLight = theme.palette.mode === 'light';

  return {
    MuiSwitch: {
      styleOverrides: {
        thumb: {
          boxShadow: theme.customShadows.z1,
        },
        track: {
          opacity: 1,
          backgroundColor: isLight ? theme.palette.grey[400] : theme.palette.grey[500],
        },
        switchBase: {
          color: isLight ? theme.palette.grey[300] : theme.palette.grey[700],
          left: 0,
          right: 'auto',
          '&:not(:.Mui-checked)': {
            color: theme.palette.grey[700],
          },
          '&.Mui-checked': {
            color: isLight ? theme.palette.main : theme.palette.grey[600],
          },
          '&.Mui-checked+.MuiSwitch-track': {
            backgroundColor: theme.palette.grey[700],
          },
          '&.Mui-checked.Mui-disabled, &.Mui-disabled': {
            color: theme.palette.grey[isLight ? 400 : 600],
          },
          // '&.Mui-disabled+.MuiSwitch-track': {
          //   opacity: 1,
          //   backgroundColor: `${theme.palette.action.disabledBackground} !important`,
          // },
        },
      },
    },
  };
}
