import { useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
// @mui
import { alpha } from '@mui/material/styles';
import {
  Stack,
  Select,
  Button,
  Tooltip,
  MenuItem,
  MenuList,
  TextField,
  InputLabel,
  FormControl
} from '@mui/material';
// hooks
import useAuth from '../../../hooks/useAuth';
// components
import Iconify from '../../../components/Iconify';
import MenuPopover from '../../../components/MenuPopover';
import { IconButtonAnimate } from '../../../components/animate';
// mocks
import { BRANCHES } from '../../../mocks/profiles';

// ----------------------------------------------------------------------

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  }
};

const ACCESS_LEVELS_FOLLOW = [
  { key: 'LEADER', label: 'Líder' },
  { key: 'ADVISOR', label: 'Assessor' },
  { key: 'FOLLOW', label: 'Melhoria Contínua' }
];

// ----------------------------------------------------------------------

export default function AssesPopover() {
  const anchorRef = useRef(null);
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const {
    username,
    userRole,
    userBranch,
    setAssignedUsername,
    setAssignedUserRole,
    setAssignedUserBranch,
  } = useAuth();

  const [inputUsername, setInputUsername] = useState(username);
  const [inputUserRole, setInputUserRole] = useState(userRole);
  const [inputUserBranch, setInputUserBranch] = useState(userBranch);

  const onEmbed = () => {
    setOpen(false);
    if (inputUserRole !== userRole) {
      setAssignedUserRole(inputUserRole);
      localStorage.setItem('assignedUserRole', inputUserRole);
    }
    if (inputUserBranch !== userBranch) {
      setAssignedUserBranch(inputUserBranch);
      localStorage.setItem('assignedUserBranch', inputUserBranch);
    }
    if (inputUsername !== username) {
      setAssignedUsername(inputUsername);
      localStorage.setItem('assignedUsername', inputUsername);
    }

    if (inputUserRole !== userRole ||
      inputUserBranch !== userBranch ||
      inputUsername !== username
    ) {
      navigate('/');
    }
  };

  return (
    <>
      <Tooltip title="Alterar nível de acesso">
        <IconButtonAnimate
          ref={anchorRef}
          size="large"
          color={open ? 'primary' : 'default'}
          onClick={() => setOpen(true)}
          sx={{
            ...(open && {
              bgcolor: (theme) => alpha(theme.palette.primary.main, theme.palette.action.focusOpacity),
            }),
          }}
        >
          <Iconify icon={'mdi:account-convert'} width={20} height={20} />
        </IconButtonAnimate>
      </Tooltip>

      <MenuPopover sx={{ minWidth: '280px' }}
        open={open} onClose={() => setOpen(false)} anchorEl={anchorRef.current} >

        <MenuList dense autoFocusItem>
          <Stack sx={{ pl: 2, pr: 2, pt: 1 }}>
            <FormControl fullWidth>
              <InputLabel>Nível de Acesso</InputLabel>
              <Select
                value={inputUserRole}
                label="Nível de Acesso"
                onChange={(e) => setInputUserRole(e.target.value)}
                MenuProps={MenuProps}
              >
                {ACCESS_LEVELS_FOLLOW.filter((e) => e.key !== 'NULL').map((option) => (
                  <MenuItem value={option.key} key={`${option.key}_level`}>{option.label}</MenuItem>
                ))}
              </Select>
            </FormControl>
          </Stack>
        </MenuList>

        <MenuList dense autoFocusItem>
          <Stack sx={{ pl: 2, pr: 2, pt: 1 }}>
            <FormControl fullWidth>
              <InputLabel>Filial</InputLabel>
              <Select
                value={inputUserBranch}
                label="Filial"
                onChange={(e) => setInputUserBranch(e.target.value)}
                MenuProps={MenuProps}
              >
                {BRANCHES.filter((e) => e.key !== 'NULL').map((option) => (
                  <MenuItem value={option.key} key={`${option.key}_branch`}>{option.label}</MenuItem>
                ))}
              </Select>
            </FormControl>
          </Stack>
        </MenuList>

        <MenuList dense autoFocusItem>
          <Stack sx={{ pl: 2, pr: 2, pt: 1 }}>
            <FormControl fullWidth>
              <TextField
                label="Officer"
                variant="outlined"
                defaultValue={inputUsername}
                onChange={(e) => setInputUsername(e.target.value)}
              />
            </FormControl>
          </Stack>
        </MenuList>

        <MenuList dense autoFocusItem>
          <Stack sx={{ pl: 2, pr: 2, pt: 2 }}>
            <Button onClick={onEmbed} variant="contained" endIcon={<Iconify icon="mdi:account-arrow-up" />}>
              Embedar
            </Button>
          </Stack>
        </MenuList>

      </MenuPopover>
    </>
  );
}
