import { Link as RouterLink } from 'react-router-dom';
// @mui
import { styled } from '@mui/material/styles';
import { Box, Button, Typography, Container } from '@mui/material';
// components
import Page from '../../components/Page';
import { SeverErrorIllustration } from '../../assets';

// ----------------------------------------------------------------------

const RootStyle = styled('div')(({ theme }) => ({
  display: 'flex',
  height: '100%',
  alignItems: 'center',
  paddingTop: theme.spacing(15),
  paddingBottom: theme.spacing(10),
}));

// ----------------------------------------------------------------------

export default function Page500() {
  return (
    <Page title="500" sx={{ height: 1 }}>
      <RootStyle>
        <Container>
          <Box sx={{ maxWidth: 480, margin: 'auto', textAlign: 'center' }}>
            <Typography variant="h3" paragraph>
              Erro Interno do Servidor
            </Typography>
            <Typography sx={{ color: 'text.secondary' }}>
              Houve um erro, por favor tente novamente mais tarde.
            </Typography>

            <SeverErrorIllustration sx={{ height: 240, my: { xs: 5, sm: 5 } }} />

            <Button to="/" size="large" variant="contained" component={RouterLink} style={{ textTransform: 'none' }}>
              Retornar à tela inicial
            </Button>
          </Box>
        </Container>
      </RootStyle>
    </Page>
  );
}
