// ----------------------------------------------------------------------

export default function Link(theme) {
  return {
    MuiLink: {
      defaultProps: {
        underline: 'hover',
      },

      styleOverrides: {
        root: {},
        underlineAlways: {
          ...theme.palette.mode === 'dark' && {
            color: theme.palette.grey[200],
            textDecorationColor: theme.palette.grey[200],
          }
        }
      },
    },
  };
}
