// ----------------------------------------------------------------------

export default function TextField(theme) {
  const isLight = theme.palette.mode === 'light';

  return {
    MuiTextField: {
      styleOverrides: {
        root: {
          ...!isLight && {
            '& label.Mui-focused': {
              color: theme.palette.grey[200]
            }
          },
        }
      },
    },  
  };
}
