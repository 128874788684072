import PropTypes from 'prop-types';
import { createContext, useState, useMemo } from 'react';
// utils
import { toDate } from '../utils/formatTime';

// ----------------------------------------------------------------------

const initialState = {
  updatedAt: null,
  setUpdatedAt: () => { },
};

const DataInformationContext = createContext(initialState);

DataInformationProvider.propTypes = {
  children: PropTypes.node,
};

function DataInformationProvider({ children }) {
  const [updatedAt, setUpdatedAt] = useState();

  const dataInformationvalues = useMemo(() => (
    {
      updatedAt,
      setUpdatedAt: (date) => setUpdatedAt(toDate(date, 'dd/MM/yyyy')),
    }
  ), [updatedAt]);

  return (
    <DataInformationContext.Provider
      value={dataInformationvalues}
    >
      {children}
    </DataInformationContext.Provider>
  );
}

export { DataInformationProvider, DataInformationContext };
