import AWS from 'aws-sdk';
import { COGNITO_API } from '../config';
import disableUser from './disableUser';
import enableUser from './enableUser';
import listUser from './listUser';

export default async function createUser(userdata) {
  const cognitoClient = new AWS.CognitoIdentityServiceProvider({
    apiVersion: '2016-04-19',
    region: COGNITO_API.region,
  });

  if (!Date.now) {
    Date.now = () => new Date().getTime();
  }

  const allowed = {
    uppers: 'QWERTYUIOPASDFGHJKLZXCVBNM',
    lowers: 'qwertyuiopasdfghjklzxcvbnm',
    numbers: '1234567890',
    symbols: '!@#$%^&*',
  };
  const getRandomCharFromString = (str) => str.charAt(Math.floor(Math.random() * str.length));
  const generatePassword = (length = 8) => {
    let pwd = '';
    pwd += getRandomCharFromString(allowed.uppers);
    pwd += getRandomCharFromString(allowed.lowers);
    pwd += getRandomCharFromString(allowed.numbers);
    pwd += getRandomCharFromString(allowed.symbols);
    // eslint-disable-next-line no-plusplus
    for (let i = pwd.length; i < length; i++) pwd += getRandomCharFromString(Object.values(allowed).join(''));
    return pwd;
  };

  // checking the users
  const verifyUsernameEmail = async () => {
    const userList = await listUser();
    let filteredUserList = userList.filter((user) => userdata.email === user.email);
    if (filteredUserList.length > 0) {
      return `Email já utilizado por ${filteredUserList[0].Username}`;
    }
    filteredUserList = userList.filter((user) => userdata.username === user.Username);
    if (filteredUserList.length > 0) {
      return `Username já utilizado por ${filteredUserList[0].Username}`;
    }
    return false;
  };

  const verify = await verifyUsernameEmail();
  if (verify) {
    return { code: 404, message: verify };
  }

  const attributes = [];
  const email = {
    Name: 'email',
    Value: userdata.email || '',
  };
  if (userdata.email) attributes.push(email);
  const isVerified = {
    Name: 'email_verified',
    Value: 'true',
  };
  if (userdata.email) attributes.push(isVerified);
  const updatedAt = {
    Name: 'updated_at',
    Value: Date.now().toString(),
  };
  if (userdata.email) attributes.push(updatedAt);
  const givenName = {
    Name: 'given_name',
    Value: userdata.givenName || userdata.given_name || '',
  };
  if (userdata.givenName || userdata.given_name) attributes.push(givenName);
  const familyName = {
    Name: 'family_name',
    Value: userdata.familyName || userdata.family_name || '',
  };
  if (userdata.familyName || userdata.family_name) attributes.push(familyName);
  const name = {
    Name: 'name',
    Value: `${userdata.givenName || userdata.given_name} ${userdata.familyName || userdata.family_name}` || '',
  };
  if ((userdata.givenName || userdata.given_name) && (userdata.familyName || userdata.family_name)) attributes.push(name);
  const nickname = {
    Name: 'nickname',
    Value: userdata.nickname || '',
  };
  if (userdata.nickname) attributes.push(nickname);

  const phone = userdata.phoneNumber || userdata.phone_number;
  const phoneNumber = {
    Name: 'phone_number',
    Value: phone && phone !== '' ? 
    `+55${phone.replace(' ', '').replace('-', '').replace('(','').replace(')','')}` : ''
  };
  if (phone && phone !== '+5599999999999') attributes.push(phoneNumber);
  const phoneNumberVerified = {
    Name: 'phone_number_verified',
    Value: 'true',
  };
  if (phone && phone !== '+5599999999999') attributes.push(phoneNumberVerified);
  const city = {
    Name: 'custom:city',
    Value: userdata.city || '',
  };
  if (userdata.city) attributes.push(city);
  const complement = {
    Name: 'custom:complement',
    Value: userdata.complement || '',
  };
  if (userdata.complement) attributes.push(complement);
  const district = {
    Name: 'custom:district',
    Value: userdata.district || '',
  };
  if (userdata.district) attributes.push(district);
  const number = {
    Name: 'custom:number',
    Value: userdata.number || '',
  };
  if (userdata.number) attributes.push(number);
  const state = {
    Name: 'custom:state',
    Value: userdata.state || '',
  };
  if (userdata.state) attributes.push(state);
  const street = {
    Name: 'custom:street',
    Value: userdata.street || '',
  };
  if (userdata.street) attributes.push(street);
  const accessLevel = {
    Name: 'custom:access_level',
    Value: userdata.accessLevel || userdata.access_level || '',
  };
  if (userdata.accessLevel || userdata.access_level) attributes.push(accessLevel);
  const core = {
    Name: 'custom:core',
    Value: userdata.core || '',
  };
  if (userdata.core) attributes.push(core);
  const department = {
    Name: 'custom:department',
    Value: userdata.department || '',
  };
  if (userdata.department) attributes.push(department);
  const branch = {
    Name: 'custom:branch',
    Value: userdata.branch || '',
  };
  if (userdata.branch) attributes.push(branch);
  const picture = {
    Name: 'picture',
    Value: userdata.picture || '',
  };
  if (userdata.picture) attributes.push(picture);
  const codXp = {
    Name: 'custom:cod_xp',
    Value: userdata.codXp || userdata.cod_xp || '',
  };
  if (userdata.cod_xp || userdata.codXp) attributes.push(codXp);

  const superior = {
    Name: 'custom:superior',
    Value: userdata.superior || '',
  };
  if (userdata.superior) attributes.push(superior);
  const roomsCrudEnabled = {
    Name: 'custom:rooms_crud_enabled',
    Value: userdata.roomsCrudEnabled || '',
  };
  if (userdata.roomsCrudEnabled) attributes.push(roomsCrudEnabled);
  const editUserEnabled = {
    Name: 'custom:edit_user_enabled',
    Value: userdata.editUserEnabled || '',
  };
  if (userdata.editUserEnabled) attributes.push(editUserEnabled);
  const readUsersEnabled = {
    Name: 'custom:read_users_enabled',
    Value: userdata.readUsersEnabled || '',
  };
  if (userdata.readUsersEnabled) attributes.push(readUsersEnabled);
  const sharingEnabled = {
    Name: 'custom:sharing_enabled',
    Value: userdata.sharingEnabled || '',
  };
  if (userdata.sharingEnabled) attributes.push(sharingEnabled);
  const uploadEnabled = {
    Name: 'custom:upload_enabled',
    Value: userdata.uploadEnabled || '',
  };
  if (userdata.uploadEnabled) attributes.push(uploadEnabled);

  const poolData = {
    UserPoolId: COGNITO_API.CognitoID,
    Username: userdata.username || userdata.Username,
    TemporaryPassword: generatePassword(12),
    UserAttributes: attributes,
    DesiredDeliveryMediums: ['EMAIL'],
  };

  async function adminCreateUser(poolData) {
    try {
      const response = await cognitoClient.adminCreateUser(poolData).promise();
      if (userdata.Enabled === 'true') {
        const resp = await enableUser(userdata.username);
        if (resp.code !== 200) {
          return { code: 404, message: resp.message };
        }
      } else if (userdata.Enabled === 'false') {
        const resp = await disableUser(userdata.username);
        if (resp.code !== 200) {
          return { code: 404, message: resp.message };
        }
      }
      return { code: 200, username: response.User.Username };
    } catch (error) {
      return { code: 404, message: error.message };
    }
  }
  
  return adminCreateUser(poolData);
}
