import PropTypes from 'prop-types';
import { createContext, useCallback, useState, useEffect, useMemo } from 'react';
// hooks
import useResponsive from '../hooks/useResponsive';

// ----------------------------------------------------------------------

const initialState = {
  collapseClick: false,
  collapseHover: false,
  onToggleCollapse: () => { },
  onHoverEnter: () => { },
  onHoverLeave: () => { },
  setCollapse: () => { },
};

const CollapseDrawerContext = createContext(initialState);

CollapseDrawerProvider.propTypes = {
  children: PropTypes.node,
};

function CollapseDrawerProvider({ children }) {
  const isDesktop = useResponsive('up', 'lg');

  const [collapse, setCollapse] = useState({
    click: false,
    hover: false,
  });

  useEffect(() => {
    if (!isDesktop) {
      setCollapse({
        click: false,
        hover: false,
      });
    }
  }, [isDesktop]);

  const handleToggleCollapse = useCallback(() => {
    setCollapse(c => ({ ...c, click: !c.click }));
  }, []);

  const handleHoverEnter = useCallback(() => {
    if (collapse.click) {
      setCollapse(c => ({ ...c, hover: true }));
    }
  }, [collapse.click]);

  const handleHoverLeave = useCallback(() => {
    setCollapse(c => ({ ...c, hover: false }));
  }, []);

  const collapseDrawerValues = useMemo(() => ({
    isCollapse: collapse.click && !collapse.hover,
    collapseClick: collapse.click,
    collapseHover: collapse.hover,
    onToggleCollapse: handleToggleCollapse,
    onHoverEnter: handleHoverEnter,
    onHoverLeave: handleHoverLeave,
    setCollapse,
  }), [collapse.click, collapse.hover, handleHoverEnter, handleHoverLeave, handleToggleCollapse]);

  return (
    <CollapseDrawerContext.Provider
      value={collapseDrawerValues}
    >
      {children}
    </CollapseDrawerContext.Provider>
  );
}

export { CollapseDrawerProvider, CollapseDrawerContext };
