import { useRef, useState } from "react";
import { useNavigate } from 'react-router-dom';
// @mui
import { Box, Card, CardActionArea, Divider, Popover, Stack, Tooltip, Typography, alpha, useTheme } from "@mui/material";
// components
import Iconify from "../../../components/Iconify";
import Scrollbar from "../../../components/Scrollbar";
import { IconButtonAnimate } from '../../../components/animate';

export default function Orders() {
  const [open, setOpen] = useState(false);

  const theme = useTheme();
  const anchorRef = useRef(null);
  const navigate = useNavigate();

  return (
    <>
      <Tooltip title="Demandas">
        <IconButtonAnimate
          ref={anchorRef}
          onClick={() => setOpen(!open)}
          color={open ? 'primary' : 'default'}
          sx={{
            padding: 0,
            margin: 0,
            width: 36,
            height: 36,
            ...(open && {
              bgcolor: theme.palette.grey[300],
            }),
            '& .MuiBadge-badge': {
              width: '5px',
              height: '12px',
              fontSize: 8
            },
          }}
        >
          <Iconify icon="material-symbols:task-outline" />
        </IconButtonAnimate>
      </Tooltip>

      <Popover
        open={open}
        anchorEl={anchorRef.current}
        onClose={() => setOpen(false)}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <Scrollbar sx={{ width: 300, maxHeight: '60vh' }}>
          <Card sx={{ p: 1, width: 300, bgcolor: alpha(theme.palette.grey[500], 0.1) }}>
            <Stack direction='row' justifyContent='space-between' alignItems='center' sx={{ mb: 1 }}>
              <Typography variant="h6">
                Demandas
              </Typography>
            </Stack>

            <Stack direction='column'>
              <Box>
                <Card
                  sx={{
                    borderRadius: 1,
                    '&:hover': {
                      bgcolor: alpha(theme.palette.grey[300], 0.1),
                    }
                  }}
                >
                  <CardActionArea sx={{ p: 1 }}
                    onClick={() => {
                      setOpen(false);
                      navigate('/backoffice/tickets');
                    }}
                  >
                    <Stack direction='row' spacing={1} alignItems='center'>
                      {/* <Iconify icon='entypo:archive' sx={{ fontSize: 24 }} /> */}
                      <Typography>
                        Backoffice Assessoria
                      </Typography>
                    </Stack>
                  </CardActionArea>
                </Card>
                <Divider sx={{ borderColor: theme.palette.grey[400], marginX: 0.5 }} />
              </Box>

              <Box>
                <Card
                  sx={{
                    borderRadius: 1,
                    '&:hover': {
                      bgcolor: alpha(theme.palette.grey[300], 0.1)
                    }
                  }}
                >
                  <CardActionArea sx={{ p: 1 }}
                    onClick={() => {
                      setOpen(false);
                      navigate('/vcs/tickets');
                    }}
                  >
                    <Stack direction='row' spacing={1} alignItems='center'>
                      {/* <Iconify icon='fluent:organization-20-filled' sx={{ fontSize: 24 }} /> */}
                      <Typography>
                        Backoffice VCS
                      </Typography>
                    </Stack>
                  </CardActionArea>
                </Card>
                <Divider sx={{ borderColor: theme.palette.grey[400], marginX: 0.5 }} />
              </Box>

              <Box>
                <Card
                  sx={{
                    borderRadius: 1,
                    '&:hover': {
                      bgcolor: alpha(theme.palette.grey[300], 0.1)
                    }
                  }}
                >
                  <CardActionArea sx={{ p: 1 }}
                    onClick={() => {
                      setOpen(false);
                      window.open('http://bit.ly/tivalor', '_blank');
                    }}
                  >
                    <Stack direction='row' spacing={1} alignItems='center'>
                      {/* <Iconify icon='fluent:organization-20-filled' sx={{ fontSize: 24 }} /> */}
                      <Typography>
                        Adm/TI
                      </Typography>
                    </Stack>
                  </CardActionArea>
                </Card>
                <Divider sx={{ borderColor: theme.palette.grey[400], marginX: 0.5 }} />
              </Box>
            </Stack>
          </Card>
        </Scrollbar>
      </Popover>
    </>

  )
}