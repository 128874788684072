// import { Suspense } from 'react';
import { useRoutes } from 'react-router-dom';
// layouts
// import DashboardLayout from '../../../../layouts/dashboard';
// hooks 
import useAuth from '../../hooks/useAuth';
// components
// import LoadingScreen from '../../../../components/LoadingScreen';
// default routes
import defaultRouter from '../defaultRouter';

// ----------------------------------------------------------------------

// const Loadable = (Component) => (props) => {
//   // eslint-disable-next-line react-hooks/rules-of-hooks
//   const { pathname } = useLocation();

//   return (
//     <Suspense fallback={<LoadingScreen isDashboard={
//       pathname.includes('/report')} />}>
//       <Component {...props} />
//     </Suspense>
//   );
// };

export function Router() {
  const { user, username, userRole, userDepartment, userRoutes } = useAuth();

  return useRoutes([
    ...defaultRouter({ defaultRoute: "/home", user, username, userRole, userDepartment, userRoutes }),    
  ]);
}

