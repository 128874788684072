// ----------------------------------------------------------------------

function path(root, sublink) {
  return `${root}${sublink}`;
}

const ROOTS_DASHBOARD = '/dashboard';
const ROOTS_REPORT = '/report';

// ----------------------------------------------------------------------

export const PATH_DASHBOARD = {
  root: ROOTS_DASHBOARD,
  base: path(ROOTS_DASHBOARD, '/base'),
};

export const PATH_REPORT = {
  root: ROOTS_REPORT,
  // digital: path(ROOTS_REPORT, '/digital'),
  gerencial: path(ROOTS_REPORT, '/gerencial'),
  bases: path(ROOTS_REPORT, '/bases'),
  performance: {
    receitaAdvisor: path(ROOTS_REPORT, '/performance/receita')
  },
  ranking: {
    root: path(ROOTS_REPORT, '/ranking'),
    ranking: path(ROOTS_REPORT, '/ranking/ranking'),
  },
  digital: {
    root: path(ROOTS_REPORT, '/digital'),
    leader: path(ROOTS_REPORT, '/digital/leader'),
  },
  esforco: {
    root: path(ROOTS_REPORT, '/esforco'),
    regua: path(ROOTS_REPORT, '/esforco/regua/advisor'),
    leads: path(ROOTS_REPORT, '/esforco/leads/advisor'),
  },
  xsell: path(ROOTS_REPORT, '/performance/xsell')
};
