import { useSnackbar } from 'notistack';
import { useContext, useRef, useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/styles';
import { alpha } from '@mui/material/styles';
import { Box, Stack, Divider, MenuItem, Typography, CircularProgress } from '@mui/material';
// routes
import { SettingsContext } from '../../../contexts/SettingsContext';
import { PATH_IN } from '../../../routes/generalPaths';
// hooks
import useAuth from '../../../hooks/useAuth';
// components
import ShareButton from '../../../components/ShareButton';
import Iconify from '../../../components/Iconify';
import MyAvatar from '../../../components/MyAvatar';
import MenuPopover from '../../../components/MenuPopover';
import { IconButtonAnimate } from '../../../components/animate';

// ----------------------------------------------------------------------

const MENU_OPTIONS = [
  {
    label: 'Home',
    linkTo: '/',
    icon: 'mdi:home',
  },
  {
    label: 'Conta',
    linkTo: PATH_IN.account,
    icon: 'mdi:account',
  }
];

// ----------------------------------------------------------------------

export default function AccountPopover() {
  const theme = useTheme();
  const anchorRef = useRef(null);
  const { enqueueSnackbar } = useSnackbar();
  const { user, logout, username } = useAuth();
  const { onToggleMode } = useContext(SettingsContext);

  const [open, setOpen] = useState(false);
  const [clickLogout, setClickLogout] = useState(false);

  const displayName =
    (localStorage.getItem('assignedUsername') && username) ||
    user?.attributes?.nickname ||
    user?.attributes?.name?.split(" ")[0] ||
    user?.attributes?.Username;

  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const handleLogout = async () => {
    setClickLogout(true);
    localStorage.setItem('clickLogout', true);
    window.setTimeout(async () => {
      try {
        await logout?.();
      } catch (error) {
        enqueueSnackbar('Unable to logout', { variant: 'error' });
        console.log(error);
      }
      setClickLogout(false);
    }, 1000);
  };

  return (
    <>
      <IconButtonAnimate
        ref={anchorRef}
        onClick={handleOpen}
        sx={{
          padding: 0,
          width: 44,
          height: 44,
          ...(open && {
            '&:before': {
              zIndex: 1,
              content: "''",
              width: '100%',
              height: '100%',
              borderRadius: '50%',
              position: 'absolute',
              bgcolor: (theme) => alpha(theme.palette.grey[900], 0.8),
            },
          }),
        }}
      >
        <MyAvatar />
      </IconButtonAnimate>

      <MenuPopover open={open} onClose={handleClose} anchorEl={anchorRef.current} sx={{ width: 220 }}>
        <Box sx={{ my: 1.5, px: 2.5 }}>
          <Typography variant="subtitle1" noWrap>
            {displayName}
          </Typography>
          <Typography variant="body2" sx={{ color: 'text.secondary' }} noWrap>
            {user?.attributes?.email}
          </Typography>
        </Box>

        <Divider />
        <Stack spacing={0.5} sx={{ p: 1 }}>
          {MENU_OPTIONS.map((option) => (
            <MenuItem
              key={option.label}
              to={option.linkTo}
              component={RouterLink}
              onClick={handleClose}
              sx={{ typography: 'body2', py: 1, px: 2, borderRadius: 1 }}
            >
              <Box
                component={Iconify}
                icon={option.icon}
                sx={{
                  mr: 2,
                  width: 24,
                  height: 24,
                }}
              />
              {option.label}
            </MenuItem>
          ))}
          <MenuItem
            onClick={onToggleMode}
            sx={{ typography: 'body2', py: 1, px: 2, borderRadius: 1 }}
          >
            <Box
              component={Iconify}
              icon={theme.palette.mode === 'dark' ? "mdi:brightness-7" : "mdi:brightness-4"}
              sx={{
                mr: 2,
                width: 24,
                height: 24,
              }}
            />
            Modo {theme.palette.mode === 'dark' ? 'Claro' : 'Escuro'}
          </MenuItem>
          <ShareButton link={`https://valorinvestimentos.com.br/fale-com?a=${username}`}/>
        </Stack>
        <Divider />

        <MenuItem
          onClick={handleLogout}
          sx={{ color: 'error.main', typography: 'body2', py: 1, px: 2, borderRadius: 1, m: 1 }}
        >
          {clickLogout || localStorage.getItem('clickLogout') ? (<CircularProgress
            color='error'
            size='24px'
            sx={{
              mr: 2,
            }} />) : (<Box
              component={Iconify}
              icon={'mdi:logout'}
              sx={{
                mr: 2,
                width: 24,
                height: 24,
              }}
            />)}

          Sair
        </MenuItem>
      </MenuPopover >
    </>
  );
}
