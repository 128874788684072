import { Suspense, lazy } from 'react';
import { Navigate, useRoutes, useLocation } from 'react-router-dom';
// layouts
import DashboardLayout from '../../../../layouts/dashboard';
// components
import LoadingScreen from '../../../../components/LoadingScreen';
// hooks 
import useAuth from '../../../../hooks/useAuth';
// default routes
import defaultRouter from '../../../defaultRouter';

// ----------------------------------------------------------------------

const Loadable = (Component) => (props) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { pathname } = useLocation();

  return (
    <Suspense fallback={<LoadingScreen isDashboard={
      pathname.includes('/report')} />}>
      <Component {...props} />
    </Suspense>
  );
};

export function Router() {
  const { user, username, userRole, userDepartment, userRoutes } = useAuth();

  return useRoutes([
    ...defaultRouter({ defaultRoute: "/home", user, username, userRole, userDepartment, userRoutes }),

    {
      path: 'report',
      element: <DashboardLayout />,
      children: [
        { element: <Navigate to="/report/clienthistory" replace />, index: true },
        {
          path: 'clienthistory',
          element: <ClientHistory />
        },
        {
          path: 'bases',
          element: <Bases />
        },
        {
          path: 'events',
          element: <Events />
        },
        {
          path: 'sdrleads',
          element: <SdrLeads />
        }
      ]
    },
  ]);
}

// REPORT
const Bases = Loadable(lazy(() => import('../../../../pages/VI/MKT/general/Bases')));
const ClientHistory = Loadable(lazy(() => import('../../../../pages/VI/MKT/general/ClientHistory')));
const SdrLeads = Loadable(lazy(() => import('../../../../pages/VI/MKT/general/SdrLeads')));
const Events = Loadable(lazy(() => import('../../../../pages/VI/EVENTOS/general/power-bi/Dashboard')));
