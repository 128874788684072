import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { styled } from '@mui/material/styles';
import { Box, Link, Typography } from '@mui/material';
// routes
import { PATH_IN } from '../../../routes/generalPaths';
// hooks
import useAuth from '../../../hooks/useAuth';
// components
import MyAvatar from '../../../components/MyAvatar';
// mocks
import { DEPARTMENTS_MAP, ACCESS_LEVELS_MAP } from '../../../mocks/profiles';

// ----------------------------------------------------------------------

const RootStyle = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(2, 2.5),
  borderRadius: Number(theme.shape.borderRadius) * 1.5,
  backgroundColor: theme.palette.grey[500_12],
  transition: theme.transitions.create('opacity', {
    duration: theme.transitions.duration.shorter,
  }),
}));

// ----------------------------------------------------------------------

NavbarAccount.propTypes = {
  isCollapse: PropTypes.bool,
};

export default function NavbarAccount({ isCollapse }) {
  const { user, username, userRole, userDepartment } = useAuth();

  const displayName =
    (localStorage.getItem('assignedUsername') && username) ||
    user?.attributes?.nickname ||
    user?.attributes?.name?.split(" ")[0] ||
    user?.attributes?.Username;

  const department = DEPARTMENTS_MAP[userDepartment] || 'Valor Investimentos';
  const accessLevel = ACCESS_LEVELS_MAP[userRole];

  return (
    <Link underline="none" color="inherit" component={RouterLink} to={PATH_IN.account}>
      <RootStyle
        sx={{
          ...(isCollapse && {
            bgcolor: 'transparent',
          }),
        }}
      >
        <MyAvatar />

        <Box
          sx={{
            ml: 2,
            transition: (theme) =>
              theme.transitions.create('width', {
                duration: theme.transitions.duration.shorter,
              }),
            ...(isCollapse && {
              ml: 0,
              width: 0,
            }),
          }}
        >
          <Typography variant="subtitle2" noWrap>
            {displayName}
          </Typography>
          <Typography variant="body2" noWrap sx={{ color: 'text.secondary' }}>
            {department}
          </Typography>
          {accessLevel && (
            <Typography variant="body2" noWrap sx={{ color: 'text.tertiary' }}>
              {accessLevel}
            </Typography>
          )}
        </Box>
      </RootStyle>
    </Link>
  );
}
