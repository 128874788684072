import { useRef, useState } from "react";
import ptBR from 'date-fns/locale/pt-BR';
import { useNavigate } from 'react-router-dom';
import { formatDistanceToNow } from 'date-fns';
// @mui
import { Badge, Box, Button, Card, CardActionArea, Divider, Popover, Stack, Tooltip, Typography, alpha, useTheme } from "@mui/material";
// components
import Iconify from "../../../components/Iconify";
import Scrollbar from "../../../components/Scrollbar";
import { IconButtonAnimate } from '../../../components/animate';
// hooks
import useAuth from "../../../hooks/useAuth";
// 
import { PATH_ANNOUNCEMENTS } from "../../../routes/generalPaths";

function fToNow(timestamp) {
  try {
    return formatDistanceToNow(new Date(timestamp), {
      addSuffix: true,
      locale: ptBR
    });
  } catch {
    return timestamp;
  }
}

export default function Notifications() {
  const [open, setOpen] = useState(false);

  const anchorRef = useRef(null);
  const { userAnnouncements } = useAuth();
  const theme = useTheme();
  const navigate = useNavigate();

  return (
    <>
      <Tooltip title="Notificações">
        <IconButtonAnimate
          ref={anchorRef}
          onClick={() => setOpen(!open)}
          color={open ? 'primary' : 'default'}
          sx={{
            padding: 0,
            margin: 0,
            width: 36,
            height: 36,
            ...(open && { bgcolor: alpha(theme.palette.grey[500], 0.5) })
          }}
        >
          <Badge badgeContent={userAnnouncements?.unreadCount ?? 0} color="error" invisible={(userAnnouncements?.unreadCount || -1) < 0} max={9}>
            <Iconify icon="material-symbols:notifications" sx={{ fontSize: 27 }} />
          </Badge>
        </IconButtonAnimate>
      </Tooltip>

      <Popover
        open={open}
        anchorEl={anchorRef.current}
        onClose={() => setOpen(false)}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <Scrollbar sx={{ width: 300, maxHeight: '60vh' }}>
          <Card sx={{ p: 1, width: 300, bgcolor: alpha(theme.palette.grey[500], 0.1) }}>
            <Stack direction='row' justifyContent='space-between' alignItems='center' sx={{ mb: 1 }}>
              <Typography variant="h6">
                Notificações
              </Typography>

              <Button
                onClick={() => {
                  setOpen(false);
                  navigate(PATH_ANNOUNCEMENTS.announcements);
                }}
                size="small"
              >
                Ver Todas
              </Button>
            </Stack>

            <Stack direction='column'>
              {userAnnouncements?.Items?.map(e =>
                <Box key={e.id}>
                  <Card
                    sx={{
                      borderRadius: 1,
                      '&:hover': {
                        bgcolor: alpha(theme.palette.grey[300], 0.1),
                      }
                    }}
                  >
                    <CardActionArea sx={{ p: 1 }}
                      onClick={() => {
                        setOpen(false);
                        navigate(`${PATH_ANNOUNCEMENTS.announcement}/${e.id}`);
                      }}
                    >
                      <Stack direction='row' alignItems='center'>
                        <Stack direction='column' sx={{ width: e.status !== 'read' ? '90%' : '100%' }}>
                          <Typography variant="subtitle1">
                            {e.title}
                          </Typography>
                          <Typography variant="caption">
                            {e.subtitle}
                          </Typography>
                        </Stack>
                        {e.status !== 'read' &&
                          <Tooltip title='Não lido'>
                            <span>
                              <Iconify icon='material-symbols:circle' />
                            </span>
                          </Tooltip>
                        }
                      </Stack>
                      <Typography textAlign='right' variant="caption" color={theme.palette.grey[500]} sx={{ display: 'block', 'width': '100%' }}>
                        {fToNow(Number(e.startDate))}
                      </Typography>
                    </CardActionArea>
                  </Card>
                  <Divider sx={{ borderColor: theme.palette.grey[400], marginX: 0.5 }} />
                </Box>
              )}
            </Stack>
          </Card>
        </Scrollbar>
      </Popover>
    </>

  )
}