import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet-async';
import { hoursToMilliseconds } from 'date-fns';
import { useLocation, useNavigate } from 'react-router';
import { forwardRef, useEffect, useState } from 'react';
// @mui
import { Box } from '@mui/material';
// components
import AnnouncementDialog from './AnnouncementDialog';
// hooks
import useAuth from '../hooks/useAuth';

// ----------------------------------------------------------------------

const Page = forwardRef(({ children, title = '', meta, ...other }, ref) => {
  const { pathname } = useLocation();
  const [openDialog, setOpenDialog] = useState(false);
  const [announcements, setAnnouncements] = useState(null);

  const { userAnnouncements, setUserAnnouncements, setResourcesOnLogin } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    (async () => {
      if (!localStorage.getItem('userResources')) {
        const resp = await setResourcesOnLogin();
        if (!resp) {
          navigate('/update');
        }
      }
    })()
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (userAnnouncements?.Items?.length) {
      const items = userAnnouncements.Items.filter(e =>
        e.popupOnPages?.includes(pathname) &&
        e.status !== 'read' &&
        Number(e.startDate) + hoursToMilliseconds(24 * Number(e.popupTime)) >= Date.now() &&
        (!e.lastPopupDate || Number(e.lastPopupDate) + hoursToMilliseconds(24) <= Date.now())
      )
      if (items?.length) {
        setAnnouncements(items);
        setOpenDialog(true);
      }
    }
  }, [userAnnouncements, pathname]);

  return (
    <>
      <Helmet>
        <title>{`${title} | Valor Investimentos`}</title>
        {meta}
      </Helmet>

      <Box ref={ref} {...other}>
        {children}
      </Box>

      {openDialog &&
        <AnnouncementDialog
          openDialog={openDialog}
          setOpenDialog={setOpenDialog}
          announcements={announcements}
          setAnnouncements={setAnnouncements}
          setUserAnnouncements={setUserAnnouncements}
        />
      }

    </>
  )
});

Page.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.string,
  meta: PropTypes.node,
};

export default Page;
