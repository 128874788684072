import { Suspense, lazy } from 'react';
import { Navigate, useRoutes, useLocation } from 'react-router-dom';
// layouts
import DashboardLayout from '../../../../layouts/dashboard';
// components
import LoadingScreen from '../../../../components/LoadingScreen';
// hooks 
import useAuth from '../../../../hooks/useAuth';
// default routes
import defaultRouter from '../../../defaultRouter';

// ----------------------------------------------------------------------

const Loadable = (Component) => (props) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { pathname } = useLocation();

  return (
    <Suspense fallback={<LoadingScreen isDashboard={
      pathname.includes('/report')} />}>
      <Component {...props} />
    </Suspense>
  );
};

export function Router() {
  const { user, username, userRole, userDepartment, userBranch, userRoutes } = useAuth();

  return useRoutes([
    ...defaultRouter({ defaultRoute: "/home", user, username, userRole, userDepartment, userBranch, userRoutes }),

    {
      path: 'report',
      element: <DashboardLayout />,
      children: [
        { element: <Navigate to="/report/comissao" replace />, index: true },
        { path: 'comissao', element: <Comissao /> },

        {
          path: 'produtos',
          children: [
            { element: <Navigate to="/report/produtos/rf" replace />, index: true },
            { path: 'rf', element: <RendaFixa /> },
          ],
        },

        {
          path: 'ranking',
          children: [
            { element: <Navigate to="/report/ranking/ranking" replace />, index: true },
            { path: 'ranking', element: <RankingAdvisor /> },
          ],
        },
      ],
    },
  ]);
}

// REPORT
const Comissao = Loadable(lazy(() => import('../../../../pages/VI/RV/general/power-bi/Comissao')));
const RendaFixa = Loadable(lazy(() => import('../../../../pages/VI/PROD/general/power-bi/RendaFixa')));
const RankingAdvisor = Loadable(lazy(() => import('../../../../pages/VI/ASSES/general/power-bi/ranking/RankingAdvisor')));
