// routes
// import { PATH_REPORT } from '../../../../../../routes/VI/PJ/paths';
// // components
// import SvgIconStyle from '../../../../../../components/SvgIconStyle';

// // ----------------------------------------------------------------------

// const getIcon = (name) => <SvgIconStyle src={`/icons/${name}.svg`} sx={{ width: 1, height: 1 }} />;

// const ICONS = {
//   user: getIcon('ic_user'),
//   form: getIcon('ic_form'),
//   upload: getIcon('ic_upload'),
//   analytics: getIcon('ic_analytics'),
//   dashboard: getIcon('ic_dashboard'),
//   maintenance: getIcon('ic_maintenance'),
//   search: getIcon('ic_search'),
//   speaker: getIcon('ic_speaker'),
//   target: getIcon('ic_target'),
//   question: getIcon('ic_question'),
//   export: getIcon('ic_analytics_export'),
// };

// eslint-disable-next-line
export const navConfig = [
  // PERFORMANCE
  // ----------------------------------------------------------------------
  // {
  //   subheader: 'Performance',
  //   items: [
  //     {
  //       title: 'Gerencial',
  //       path: PATH_REPORT.performance.gerencial,
  //       icon: ICONS.analytics,
  //     },
  //     {
  //       title: 'Bases',
  //       path: PATH_REPORT.performance.bases,
  //       icon: ICONS.analytics,
  //     },
  //   ],
  // },
];
