import AWS from 'aws-sdk';
import { COGNITO_API } from '../config';
import disableUser from './disableUser';
import enableUser from './enableUser';

export default async function updateUser(userdata) {
  const cognitoClient = new AWS.CognitoIdentityServiceProvider({
    apiVersion: '2016-04-19',
    region: COGNITO_API.region,
  });

  if (!Date.now) {
    Date.now = () => new Date().getTime();
  }

  const attributes = [];
  const email = {
    Name: 'email',
    Value: userdata.email || '',
  };
  if (userdata.email) attributes.push(email);
  const emailVerified = {
    Name: 'email_verified',
    Value: 'true',
  };
  if (userdata.email) attributes.push(emailVerified);
  const updatedAt = {
    Name: 'updated_at',
    Value: Date.now().toString(),
  };
  if (userdata.email) attributes.push(updatedAt);
  const givenName = {
    Name: 'given_name',
    Value: userdata.given_name || userdata.givenName || '',
  };
  if (userdata.given_name || userdata.givenName) attributes.push(givenName);
  const familyName = {
    Name: 'family_name',
    Value: userdata.family_name || userdata.familyName || '',
  };
  if (userdata.family_name || userdata.familyName) attributes.push(familyName);
  const name = {
    Name: 'name',
    Value: `${userdata.given_name || userdata.givenName} ${userdata.family_name || userdata.familyName}` || '',
  };
  if (userdata.given_name || userdata.family_name || userdata.givenName || userdata.familyName) attributes.push(name);
  const nickname = {
    Name: 'nickname',
    Value: userdata.nickname || '',
  };
  if (userdata.nickname) attributes.push(nickname);
  const phoneNumber = {
    Name: 'phone_number',
    Value: userdata.phone_number || userdata.phoneNumber || '',
  };
  if (userdata.phone_number || userdata.phoneNumber) attributes.push(phoneNumber);
  const phoneNumberVerified = {
    Name: 'phone_number_verified',
    Value: 'true',
  };
  if (userdata.phone_number || userdata.phoneNumber) attributes.push(phoneNumberVerified);
  const city = {
    Name: 'custom:city',
    Value: userdata.city || '',
  };
  if (userdata.city) attributes.push(city);
  const complement = {
    Name: 'custom:complement',
    Value: userdata.complement || '',
  };
  if (userdata.complement) attributes.push(complement);
  const district = {
    Name: 'custom:district',
    Value: userdata.district || '',
  };
  if (userdata.district) attributes.push(district);
  const number = {
    Name: 'custom:number',
    Value: userdata.number || '',
  };
  if (userdata.number) attributes.push(number);
  const state = {
    Name: 'custom:state',
    Value: userdata.state || '',
  };
  if (userdata.state) attributes.push(state);
  const street = {
    Name: 'custom:street',
    Value: userdata.street || '',
  };
  if (userdata.street) attributes.push(street);
  const accessLevel = {
    Name: 'custom:access_level',
    Value: userdata.access_level || userdata.accessLevel || '',
  };
  if (userdata.access_level || userdata.accessLevel) attributes.push(accessLevel);
  const core = {
    Name: 'custom:core',
    Value: userdata.core || '',
  };
  if (userdata.core) attributes.push(core);
  const department = {
    Name: 'custom:department',
    Value: userdata.department || '',
  };
  if (userdata.department) attributes.push(department);
  const picture = {
    Name: 'picture',
    Value: userdata.picture || '',
  };
  if (userdata.picture) attributes.push(picture);
  const twitterLink = {
    Name: 'custom:twitter_link',
    Value: userdata.twitter_link || userdata.twitterLink || '',
  };
  if (userdata.twitter_link) attributes.push(twitterLink);
  const linkedinLink = {
    Name: 'custom:linkedin_link',
    Value: userdata.linkedin_link || userdata.linkedinLink || '',
  };
  if (userdata.linkedin_link || userdata.linkedinLink) attributes.push(linkedinLink);
  const instagramLink = {
    Name: 'custom:instagram_link',
    Value: userdata.instagram_link || userdata.instagramLink || '',
  };
  if (userdata.instagram_link || userdata.instagramLink) attributes.push(instagramLink);
  const codXp = {
    Name: 'custom:cod_xp',
    Value: userdata.cod_xp || userdata.codXp || '',
  };
  if (userdata.cod_xp || userdata.codXp) attributes.push(codXp);

  const superior = {
    Name: 'custom:superior',
    Value: userdata.superior || '',
  };
  if (userdata.superior) attributes.push(superior);

  const poolData = {
    UserPoolId: COGNITO_API.CognitoID,
    Username: userdata.username,
    UserAttributes: attributes,
  };

  async function adminUpdateUser(poolData) {
    try {
      await cognitoClient.adminUpdateUserAttributes(poolData).promise();
      if (userdata.Enabled === 'true') {
        const resp = await enableUser(userdata.username);
        if (resp.code !== 200) {
          return { code: 404, message: resp.message };
        }
      } else if (userdata.Enabled === 'false') {
        const resp = await disableUser(userdata.username);
        if (resp.code !== 200) {
          return { code: 404, message: resp.message };
        }
      }
      return { code: 200, username: userdata.username };
    } catch (error) {
      return { code: 404, error: error.message };
    }
  }

  return adminUpdateUser(poolData);
}
