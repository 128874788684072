import AWS from 'aws-sdk';
import { COGNITO_API } from '../config';

export default async function listUser() {
  const cognitoClient = new AWS.CognitoIdentityServiceProvider({
    apiVersion: '2016-04-19',
    region: COGNITO_API.region,
  });

  async function adminListUser(PaginationToken = null, limit = 60) {
    const poolData = {
      UserPoolId: COGNITO_API.CognitoID,
      Limit: limit,
    };
    if (PaginationToken) {
      poolData.PaginationToken = PaginationToken;
    }

    return new Promise((resolve, reject) => {
      cognitoClient.listUsers(poolData, (err, data) => {
        if (err) {
          reject(err)
        }
        else {
          resolve(data)
        }
      })
    });
  }

  async function fetchUsersAll() {
    let response = await adminListUser();
    let usersList = response.Users;
    while (response.PaginationToken) {
      // eslint-disable-next-line no-await-in-loop
      response = await adminListUser(response.PaginationToken);
      usersList = usersList.concat(response.Users);
    }
    return usersList;
  }

  async function fetchUsers() {
    const usersList = await fetchUsersAll();
    try {
      if (usersList) {
        usersList.forEach(async (a) => {
          const v = Object.assign(
            a,
            await a?.Attributes?.reduce((b, x) => ({ ...b, [x.Name.split(':').at(-1)]: x.Value }), {})
          );
          if (v.Attributes) {
            delete v.Attributes;
          }
          /* eslint-disable no-empty */
          if (await v.picture) {
            try {
              v.picture = `/avatars/avatar_${Math.floor(Math.random() * (8 - 1) + 1)}.jpg`;
            } catch { }
          }
          return v;
        });

        return usersList;
      }
      return [];
    } catch (error) {
      return [];
    }
    /* eslint-enable no-empty */
  }

  return fetchUsers();
}
