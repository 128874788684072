import axios from 'axios';
// config
import { HOST_API } from '../config';

// ----------------------------------------------------------------------

const axiosInstance = axios.create({
  baseURL: HOST_API,
});

axiosInstance.defaults.headers.common.Accept = '*/*';
axiosInstance.defaults.headers.common['Access-Control-Allow-Origin'] = '*';
axiosInstance.defaults.headers.common['Content-Type'] = 'application/json';

axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => Promise.reject(error?.response?.data || error?.response?.error || error?.error || 'Something went wrong')
);

export default axiosInstance;
