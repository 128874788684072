// routes
// import { PATH_REPORT } from '../../../../../../routes/generalPaths';
// components
// import SvgIconStyle from '../../../../../../components/SvgIconStyle';
// ----------------------------------------------------------------------

// const getIcon = (name) => <SvgIconStyle src={`/icons/${name}.svg`} sx={{ width: 1, height: 1 }} />;

// const ICONS = {
//   user: getIcon('ic_user'),
//   analytics: getIcon('ic_analytics'),
//   search: getIcon('ic_search'),
//   target: getIcon('ic_target'),
//   question: getIcon('ic_question'),
//   form: getIcon('ic_form'),
//   export: getIcon('ic_analytics_export'),
// };

// eslint-disable-next-line
export const navConfig = [
  // SETTINGS
  // {
  //   subheader: 'Configurações',
  //   items: [
  //     {
  //       title: 'Usuários',
  //       path: PATH_SETTINGS.user.root,
  //       icon: ICONS.user,
  //       children: [
  //         { title: 'Criar', path: PATH_SETTINGS.user.newUser },
  //         { title: 'Usuários', path: PATH_SETTINGS.user.list },          
  //       ],
  //     },
  //   ]
  // }
];
