// ----------------------------------------------------------------------

function path(root, sublink) {
  return `${root}${sublink}`;
}

const ROOTS_DASHBOARD = '/dashboard';
const ROOTS_REPORT = '/report';
const ROOTS_GU = '/gu';

// ----------------------------------------------------------------------

export const PATH_DASHBOARD = {
  root: ROOTS_DASHBOARD,
  base: path(ROOTS_DASHBOARD, '/base'),
  campaigns: path(ROOTS_DASHBOARD, '/campaigns'),
};

export const PATH_GU = {
  book: path(ROOTS_GU, '/booktatico'),
  acessos: path(ROOTS_GU, '/acessos'),
  campanhasCrm: path(ROOTS_GU, '/campanhascrm'),
  ativAsses: path(ROOTS_GU, '/ativacao300kconsultagu'),
  ativMaster: path(ROOTS_GU, '/ativacao300krankinggu'),
  gerencial: path(ROOTS_GU, '/gerencialgu'),
  oportunidades: path(ROOTS_GU, '/oportunidadesativacaogu'),
  previdencia: path(ROOTS_GU, '/previdenciagu'),
  superRanking: path(ROOTS_GU, '/superrankinggu'),
  indicadores: path(ROOTS_GU, '/indicadores'),
  n2: path(ROOTS_GU, '/n2'),
  guDigi : path(ROOTS_GU, '/gudigi'),
}

export const PATH_REPORT = {
  root: ROOTS_REPORT,
  performance: {
    root: path(ROOTS_REPORT, '/performance'),
    gerencial: {
      advisor: path(ROOTS_REPORT, '/performance/gerencial/advisor'),
      leader: path(ROOTS_REPORT, '/performance/gerencial/leader'),
      manager: path(ROOTS_REPORT, '/performance/gerencial/manager'),
    },
    bases: path(ROOTS_REPORT, '/performance/bases'),
    receita: path(ROOTS_REPORT, '/performance/receita'),
    receitaAdvisor: path(ROOTS_REPORT, '/performance/receita/advisor'),
    receitaLeader: path(ROOTS_REPORT, '/performance/receita/leader'),
    receitaGestor: path(ROOTS_REPORT, '/performance/receita/manager'),
    pan: path(ROOTS_REPORT, '/performance/pan'),
    pan2: path(ROOTS_REPORT, '/performance/pan2'),
    metas: {
      advisor: path(ROOTS_REPORT, '/performance/metas/advisor'),
      farmer: path(ROOTS_REPORT, '/performance/metas/farmer'),
      leader: path(ROOTS_REPORT, '/performance/metas/leader'),
      leaderFull: path(ROOTS_REPORT, '/performance/metas/leaderfull'),
      manager: path(ROOTS_REPORT, '/performance/metas/manager'),
    },
    corretagem: {
      advisor: path(ROOTS_REPORT, '/performance/corretagem/advisor'),
      leader: path(ROOTS_REPORT, '/performance/corretagem/leader'),
      manager: path(ROOTS_REPORT, '/performance/corretagem/manager'),
    },
    xsell: path(ROOTS_REPORT, '/performance/xsell'),
  },
  pan: {
    root: path(ROOTS_REPORT, '/pan'), 
    pan: path(ROOTS_REPORT, '/pan/1'),
    pan2: path(ROOTS_REPORT, '/pan/2'),
  },
  oportunidades: {
    root: path(ROOTS_REPORT, '/oportunidades'),
    ativacoes: {
      advisor: path(ROOTS_REPORT, '/oportunidades/ativacoes/advisor'),
      leader: path(ROOTS_REPORT, '/oportunidades/ativacoes/leader'),
    }
  },
  ranking: {
    root: path(ROOTS_REPORT, '/ranking/follow-up'),
    followUp: {
      advisor: path(ROOTS_REPORT, '/ranking/follow-up/advisor'),
      leader: path(ROOTS_REPORT, '/ranking/follow-up/leader'),
      manager: path(ROOTS_REPORT, '/ranking/follow-up/manager'),
    }
  },
  campanhas: {
    root: path(ROOTS_REPORT, '/campanhas'),
    crm: path(ROOTS_REPORT, '/campanhas/crm'),
    ativacao300k: {
      ranking: path(ROOTS_REPORT, '/campanhas/ativacao300k/ranking'),
      consulta: path(ROOTS_REPORT, '/campanhas/ativacao300k/consulta'),
    },
  },
  esforco: {
    root: path(ROOTS_REPORT, '/esforco'),
    regua: {
      advisor: path(ROOTS_REPORT, '/esforco/regua/advisor'),
      leader: path(ROOTS_REPORT, '/esforco/regua/leader'),
    },
    leads: {
      advisor: path(ROOTS_REPORT, '/esforco/leads/advisor'),
      leader: path(ROOTS_REPORT, '/esforco/leads/leader'),
      manager: path(ROOTS_REPORT, '/esforco/leads/manager'),
    },
    crmLeader: path(ROOTS_REPORT, '/esforco/crmleader'),
    crmAdvisor: path(ROOTS_REPORT, '/esforco/crmadvisor'),
  },
  reunioes: {
    root: path(ROOTS_REPORT, '/reunioes'),
    n1: path(ROOTS_REPORT, '/reunioes/n1'),
    n2: path(ROOTS_REPORT, '/reunioes/n2'),
    n3: path(ROOTS_REPORT, '/reunioes/n3'),
    newN2: path(ROOTS_REPORT, '/reunioes/newN2'),
    newN3: path(ROOTS_REPORT, '/reunioes/newN3'),
  },
};
