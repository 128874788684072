import PropTypes from 'prop-types';
import { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
// @mui
import { styled, useTheme } from '@mui/material/styles';
import { Box, Stack, Drawer } from '@mui/material';
// hooks
import useResponsive from '../../../hooks/useResponsive';
import useCollapseDrawer from '../../../hooks/useCollapseDrawer';
import useAuth from '../../../hooks/useAuth';
// utils
import cssStyles from '../../../utils/cssStyles';
// config
import { NAVBAR } from '../../../config';
// components
import Logo from '../../../components/Logo';
import Scrollbar from '../../../components/Scrollbar';
import { NavSectionVertical } from '../../../components/nav-section';
// paths
import reportRules from '../../../reportRules.json';
//
import NavbarDocs from './NavbarDocs';
import NavbarAccount from './NavbarAccount';
import CollapseButton from './CollapseButton';
//
import navConfigBlank from './NavConfigBlank';
import defaultNavConfig from './defaultNavConfig';
// ADMIN
import { navConfig as navConfigViAdminHead } from './VI/ADMIN/HEAD/NavConfig';
import { navConfig as navConfigViAdminExpert } from './VI/ADMIN/EXPERT/NavConfig';
// BANKING
import { navConfig as navConfigViBankingExpert } from './VI/BANKING/EXPERT/NavConfig';
// ASSES
import { navConfig as navConfigViAssesAdmin } from './VI/ASSES/ADMIN/NavConfig';
import { navConfig as navConfigViAssesLeader } from './VI/ASSES/LEADER/NavConfig';
import { navConfig as navConfigViAssesFarmer } from './VI/ASSES/FARMER/NavConfig';
import { navConfig as navConfigViAssesFollow } from './VI/ASSES/FOLLOW/NavConfig';
import { navConfig as navConfigViAssesAdvisor } from './VI/ASSES/ADVISOR/NavConfig';
import { navConfig as navConfigViAssesManager } from './VI/ASSES/MANAGER/NavConfig';
import { navConfig as navConfigViAssesFLeader } from './VI/ASSES/FLEADER/NavConfig';
import { navConfig as navConfigViAssesHead } from './VI/ASSES/HEAD/NavConfig';
// DADOS
import { navConfig as navConfigViDados } from './VI/DADOS/NavConfig';
// DADOS
import { navConfig as navConfigViTIExpert } from './VI/TI/EXPERT/NavConfig';
// DIGI
import { navConfig as navConfigViDigiManager } from './VI/DIGI/MANAGER/NavConfig';
import { navConfig as navConfigViDigiAdvisor } from './VI/DIGI/ADVISOR/NavConfig';
import { navConfig as navConfigViDigiLeader } from './VI/DIGI/LEADER/NavConfig';
// EXP
import { navConfig as navConfigViExp } from './VI/EXP/NavConfig';
// EVENTOS
import { navConfig as navConfigViEventosManager } from './VI/EVENTOS/MANAGER/NavConfig';
// MELCONT
import { navConfig as navConfigViMelcontExpert } from './VI/MELCONT/EXPERT/NavConfig';
import { navConfig as navConfigViMelcontHead } from './VI/MELCONT/HEAD/NavConfig';
// MKT
import { navConfig as navConfigViMktExpert } from './VI/MKT/EXPERT/NavConfig';
import { navConfig as navConfigViMktLeader } from './VI/MKT/LEADER/NavConfig';
// SDR
import { navConfig as navConfigViSDRExpert } from './VI/SDR/EXPERT/NavConfig';
// PROD
import { navConfig as navConfigViProdManager } from './VI/PROD/MANAGER/NavConfig';
import { navConfig as navConfigViProdExpert } from './VI/PROD/EXPERT/NavConfig';
// RH
import { navConfig as navConfigViRhHead } from './VI/RH/HEAD/NavConfig';
import { navConfig as navConfigViRhExpert } from './VI/RH/EXPERT/NavConfig';
import { navConfig as navConfigViRhLeader } from './VI/RH/LEADER/NavConfig';
// RV
import { navConfig as navConfigViRvManager } from './VI/RV/MANAGER/NavConfig';
import { navConfig as navConfigViRvExpert } from './VI/RV/EXPERT/NavConfig';
import { navConfig as navConfigViRvLeader } from './VI/RV/LEADER/NavConfig';
// PJ
import { navConfig as navConfigViPjExpert } from './VI/PJ/EXPERT/NavConfig';
// VCS
import { navConfig as navConfigVcsVcsHead } from './VCS/VCS/HEAD/NavConfig';
import { navConfig as navConfigVcsPrevExpert } from './VCS/PREV/EXPERT/NavConfig';
import { navConfig as navConfigVcsPrevLeader } from './VCS/PREV/LEADER/NavConfig';
import { navConfig as navConfigVcsPrevManager } from './VCS/PREV/MANAGER/NavConfig';
import { navConfig as navConfigVcsSegManager } from './VCS/SEG/MANAGER/NavConfig';
import { navConfig as navConfigVcsSegHead } from './VCS/SEG/HEAD/NavConfig';
import { navConfig as navConfigVcsSegLeader } from './VCS/SEG/LEADER/NavConfig';
import { navConfig as navConfigVcsSegExpert } from './VCS/SEG/EXPERT/NavConfig';
import { navConfig as navConfigVcsPlanejManager } from './VCS/PLANEJ/MANAGER/NavConfig';
import { navConfig as navConfigVcsPlanejLeader } from './VCS/PLANEJ/LEADER/NavConfig';

// VCCP
import { navConfig as navConfigVccpVccpHead } from './VCCP/VCCP/HEAD/NavConfig';
import { navConfig as navConfigVccpPjHead } from './VCCP/PJ/HEAD/NavConfig';
import { navConfig as navConfigVccpPjExpert } from './VCCP/PJ/EXPERT/NavConfig';

// ----------------------------------------------------------------------

const RootStyle = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('lg')]: {
    flexShrink: 0,
    transition: theme.transitions.create('width', {
      duration: theme.transitions.duration.shorter,
    }),
  },
}));

// ----------------------------------------------------------------------

DashboardNavbar.propTypes = {
  isOpenSidebar: PropTypes.bool,
  onCloseSidebar: PropTypes.func,
};

export default function DashboardNavbar({ isOpenSidebar, onCloseSidebar }) {
  const [navConfig, setnavConfig] = useState(navConfigBlank);

  const { user, username, userCore, userDepartment, userRole, userBranch, userRoutes } = useAuth();

  const powerBiExport = reportRules.powerBiExport.includes(username) || (userRole === 'DEV');
  const okrGeneral = reportRules.okrGeneral.includes(username) || (userRole === 'DEV');

  const isAdmin = (userRole === 'ADMIN') || (userRole === 'DEV');
  const isAssess = (userDepartment === 'ASSES') || (userDepartment === 'DIGI') || isAdmin;
  const uploadEnabled = user?.attributes?.['custom:upload_enabled'] === 'TRUE';

  const setNavConfig = (currentNavConfig) => {
    setnavConfig(defaultNavConfig({ currentNavConfig, username, userRole, powerBiExport, okrGeneral, isAssess, isAdmin, uploadEnabled, userCore, userBranch, userDepartment, userRoutes, user }));
  }

  useEffect(() => {
    if (userCore === 'VI') {
      if (userDepartment === 'ADMIN') {
        if (userRole === 'HEAD') {
          setNavConfig(navConfigViAdminHead);
        } else if (userRole === 'EXPERT') {
          setNavConfig(navConfigViAdminExpert);
        }
      }

      if (userDepartment === 'BANKING') {
        setNavConfig(navConfigViBankingExpert);
      }

      if (userDepartment === 'TI') {
        setNavConfig(navConfigViTIExpert);
      }

      else if (userDepartment === 'ASSES') {
        if (userRole === 'ADMIN') {
          setNavConfig(navConfigViAssesAdmin);
        } else if (userRole === 'LEADER') {
          setNavConfig(navConfigViAssesLeader);
        } else if (userRole === 'FARMER') {
          setNavConfig(navConfigViAssesFarmer);
        } else if (userRole === 'FLEADER') {
          setNavConfig(navConfigViAssesFLeader);
        } else if (userRole === 'ADVISOR') {
          setNavConfig(navConfigViAssesAdvisor);
        } else if (userRole === 'MANAGER') {
          setNavConfig(navConfigViAssesManager);
        } else if (userRole === 'FOLLOW') {
          setNavConfig(navConfigViAssesFollow);
        } else if (userRole === 'HEAD') {
          setNavConfig(navConfigViAssesHead);
        }
      }

      else if (userDepartment === 'DADOS') {
        if (userRole === 'DEV') {
          setNavConfig(navConfigViDados);
        } else {
          setNavConfig(navConfigViDados);
        }
      }

      else if (userDepartment === 'DIGI') {
        if (userRole === 'MANAGER') {
          setNavConfig(navConfigViDigiManager);
        } else if (userRole === 'ADVISOR') {
          setNavConfig(navConfigViDigiAdvisor);
        } else if (userRole === 'LEADER') {
          setNavConfig(navConfigViDigiLeader);
        }
      }

      else if (userDepartment === 'EXP') {
        setNavConfig(navConfigViExp);
      }

      else if (userDepartment === 'EVENTOS') {
        if (userRole === 'MANAGER')
          setNavConfig(navConfigViEventosManager);
      }

      else if (userDepartment === 'MELCONT') {
        if (userRole === 'EXPERT')
          setNavConfig(navConfigViMelcontExpert);
        if (userRole === 'HEAD')
          setNavConfig(navConfigViMelcontHead);
      }

      else if (userDepartment === 'MKT'){
        if(userRole === 'EXPERT')
          setNavConfig(navConfigViMktExpert)
        else if(userRole === 'LEADER')
          setNavConfig(navConfigViMktLeader)
      }

      else if (userDepartment === 'SDR') {
        setNavConfig(navConfigViSDRExpert);
      }

      else if (userDepartment === 'PROD') {
        if (userRole === 'MANAGER') {
          setNavConfig(navConfigViProdManager);
        } else if (userRole === 'EXPERT') {
          setNavConfig(navConfigViProdExpert)
        }
      }

      else if (userDepartment === 'RV') {
        if (userRole === 'MANAGER') {
          setNavConfig(navConfigViRvManager);
        } else if (userRole === 'EXPERT') {
          setNavConfig(navConfigViRvExpert);
        } else if (userRole === 'LEADER') {
          setNavConfig(navConfigViRvLeader);
        }
      }

      else if (userDepartment === 'RH'){
        if (userRole === 'LEADER'){
          setNavConfig(navConfigViRhLeader);
        }
        else if (userRole === 'HEAD') {
          setNavConfig(navConfigViRhHead);
        } else if (userRole === 'EXPERT') {
          setNavConfig(navConfigViRhExpert);
        }
      }

      else if (userDepartment === 'PJ') {
        if (userRole === 'EXPERT') {
          setNavConfig(navConfigViPjExpert);
        }
      }

      else if (userDepartment === 'BACKOFF') {
        if (userRole === 'EXPERT') {
          setNavConfig([]);
        }
      }
    }

    else if (userCore === 'VCS') {
      if (userDepartment === 'VCS') {
        if (userRole === 'HEAD') {
          setNavConfig(navConfigVcsVcsHead);
        }
      }
      else if (userDepartment === 'PREV') {
        if (userRole === 'MANAGER') {
          setNavConfig(navConfigVcsPrevManager);
        } else if (userRole === 'EXPERT') {
          setNavConfig(navConfigVcsPrevExpert);
        } else if (userRole === 'LEADER') {
          setNavConfig(navConfigVcsPrevLeader);
        }
      }
      else if (userDepartment === 'PLANEJ') {
        if (userRole === 'LEADER') {
          setNavConfig(navConfigVcsPlanejLeader);
        } else if (userRole === 'MANAGER') {
          setNavConfig(navConfigVcsPlanejManager);
        }
      }
      else if (userDepartment === 'SEG') {
        if (userRole === 'MANAGER') {
          setNavConfig(navConfigVcsSegManager);
        } else if (userRole === 'LEADER') {
          setNavConfig(navConfigVcsSegLeader);
        } else if (userRole === 'EXPERT') {
          setNavConfig(navConfigVcsSegExpert);
        }
        else if (userRole === 'HEAD'){
          setNavConfig(navConfigVcsSegHead);
        }
      }
    }

    else if (userCore === 'VCCP') {
      if (userDepartment === 'VCCP') {
        if (userRole === 'HEAD') {
          setNavConfig(navConfigVccpVccpHead);
        }
      }
      if (userDepartment === 'PJ') {
        if (userRole === 'HEAD') {
          setNavConfig(navConfigVccpPjHead);
        } else if (userRole === 'EXPERT') {
          setNavConfig(navConfigVccpPjExpert);
        }
      }
    }

    else if (userCore === 'VGR'){
      if (userRole === 'EXPERT')
        setNavConfig([]);
    }

    else if (userCore === 'LUMI'){
      if (userRole === 'EXPERT')
        setNavConfig([]);
    }

    else if (userCore === 'SIMPLES'){
      setNavConfig([]);
    }

    // eslint-disable-next-line
  }, [user, username, userCore, userDepartment, userRole, userRoutes]);

  const theme = useTheme();

  const { pathname } = useLocation();

  const isDesktop = useResponsive('up', 'lg');

  const { isCollapse, collapseClick, collapseHover, onToggleCollapse, onHoverEnter, onHoverLeave } =
    useCollapseDrawer();

  useEffect(() => {
    if (isOpenSidebar) {
      onCloseSidebar();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  const renderContent = (
    <Scrollbar
      sx={{
        height: 1,
        '& .simplebar-content': { height: 1, display: 'flex', flexDirection: 'column' },
      }}
    >
      <Stack
        spacing={3}
        sx={{
          pt: 3,
          pb: 2,
          px: 2.5,
          flexShrink: 0,
          ...(isCollapse && { alignItems: 'center' }),
        }}
      >
        <Stack direction="row" alignItems="center" justifyContent="space-between">
          {theme.palette.mode === 'light' ? 
            <Logo sx={{ fill: theme.palette.primary.main, stroke: theme.palette.primary.main }} /> : 
            <Logo color='white' />
          }
          {isDesktop && !isCollapse && (
            <CollapseButton onToggleCollapse={onToggleCollapse} collapseClick={collapseClick} />
          )}
        </Stack>

        <NavbarAccount isCollapse={isCollapse} />
      </Stack>

      <NavSectionVertical navConfig={navConfig} isCollapse={isCollapse} />

      <Box sx={{ flexGrow: 1 }} />

      {!isCollapse && <NavbarDocs />}
    </Scrollbar>
  );

  return (
    <RootStyle
      sx={{
        width: {
          lg: isCollapse ? NAVBAR.DASHBOARD_COLLAPSE_WIDTH : NAVBAR.DASHBOARD_WIDTH,
        },
        ...(collapseClick && {
          position: 'absolute',
        }),
      }}
    >
      {!isDesktop && (
        <Drawer open={isOpenSidebar} onClose={onCloseSidebar} PaperProps={{ sx: { width: NAVBAR.DASHBOARD_WIDTH } }}>
          {renderContent}
        </Drawer>
      )}

      {isDesktop && (
        <Drawer
          open
          variant="persistent"
          onMouseEnter={onHoverEnter}
          onMouseLeave={onHoverLeave}
          PaperProps={{
            sx: {
              cursor: 'pointer',
              width: NAVBAR.DASHBOARD_WIDTH,
              borderRightStyle: 'dashed',
              bgcolor: 'background.default',
              transition: (theme) =>
                theme.transitions.create('width', {
                  duration: theme.transitions.duration.standard,
                }),
              ...(isCollapse && {
                width: NAVBAR.DASHBOARD_COLLAPSE_WIDTH,
              }),
              ...(collapseHover && {
                ...cssStyles(theme).bgBlur(),
                boxShadow: (theme) => theme.customShadows.z24,
              }),
            },
          }}
        >
          {renderContent}
        </Drawer>
      )}
    </RootStyle>
  );
}