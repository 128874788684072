import { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
// @mui
import { alpha } from '@mui/material/styles';
import {
  Stack,
  Select,
  Button,
  Tooltip,
  MenuItem,
  MenuList,
  TextField,
  InputLabel,
  FormControl,
  Box,
  Autocomplete,
  Typography,
  Divider
} from '@mui/material';
// hooks
import useAuth from '../../../hooks/useAuth';
// components
import Iconify from '../../../components/Iconify';
import MenuPopover from '../../../components/MenuPopover';
import { IconButtonAnimate } from '../../../components/animate';
// mocks
import { ACCESS_LEVELS, DEPARTMENTS, BRANCHES, CORES } from '../../../mocks/profiles';


// ----------------------------------------------------------------------

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  }
};

// ----------------------------------------------------------------------

export default function DowngradePopover() {
  const anchorRef = useRef(null);
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const {
    username,
    userCore,
    userRole,
    userBranch,
    userDepartment,
    listProfiles,
    setAssignedCore,
    setAssignedUsername,
    setAssignedUserRole,
    setAssignedUserBranch,
    setAssignedDepartment
  } = useAuth();

  const [userlist, setUserlist] = useState([]);
  const [inputUser, setInputUser] = useState({ Username: '' });
  const [inputUsername, setInputUsername] = useState(username);
  const [inputUserRole, setInputUserRole] = useState(userRole);
  const [inputUserCore, setInputUserCore] = useState(userCore);
  const [inputUserDepartment, setInputUserDepartment] = useState(userDepartment);
  const [inputUserBranch, setInputUserBranch] = useState(Array.isArray(userBranch) ? userBranch : [userBranch]);

  useEffect(() => {
    (async () => {
      try {
        const resp = await listProfiles();
        // if (isMountedRef.current)
        setUserlist(resp);
      } catch {
        // if (isMountedRef.current)
        setUserlist([]);
      }
    })();
    // eslint-disable-next-line
  }, []);

  const onEmbed = () => {
    setOpen(false);
    if (inputUserCore !== userCore) {
      setAssignedCore(inputUserCore);
      localStorage.setItem('assignedCore', inputUserCore);
    }
    if (inputUserDepartment !== userDepartment) {
      setAssignedDepartment(inputUserDepartment);
      localStorage.setItem('assignedDepartment', inputUserDepartment);
    }
    if (inputUserRole !== userRole) {
      setAssignedUserRole(inputUserRole);
      localStorage.setItem('assignedUserRole', inputUserRole);
    }
    if (inputUserBranch && (inputUserBranch.length > 0)) {
      const br = [...new Set(inputUserBranch.filter(e => e !== "NULL"))].join(',');
      setAssignedUserBranch(br);
      localStorage.setItem('assignedUserBranch', br);
    }
    if (inputUsername !== username) {
      setAssignedUsername(inputUsername);
      localStorage.setItem('assignedUsername', inputUsername);
    }

    if (inputUserCore !== userCore ||
      inputUserDepartment !== userDepartment ||
      inputUserRole !== userRole ||
      inputUserBranch !== userBranch ||
      inputUsername !== username
    ) {
      navigate('/');
    }
  };

  return (
    <>
      <Tooltip title="Alterar nível de acesso">
        <IconButtonAnimate
          ref={anchorRef}
          size="large"
          color={open ? 'primary' : 'default'}
          onClick={() => setOpen(true)}
          sx={{
            ...(open && {
              bgcolor: (theme) => alpha(theme.palette.primary.main, theme.palette.action.focusOpacity),
            }),
          }}
        >
          <Iconify icon={'mdi:account-convert'} width={20} height={20} />
        </IconButtonAnimate>
      </Tooltip>

      <MenuPopover sx={{ minWidth: '450px' }}
        open={open} onClose={() => setOpen(false)} anchorEl={anchorRef.current} >

        <Stack sx={{ display: 'flex', flexDirection: 'row' }}>
          <Box sx={{ p: 2, pt: 3 }}>
            <Typography variant='body1' sx={{ mb: 2 }}>
              <b>Embedar com credenciais de usuário</b>
            </Typography>
            <Autocomplete
              fullWidth
              options={userlist}
              value={inputUser}
              getOptionLabel={e => e.Username}
              onChange={(e, newValue) => {
                setInputUser(newValue);
                if (newValue !== null) {
                  setInputUserCore(newValue?.core);
                  setInputUserBranch(Array.isArray(newValue?.branch) ? newValue?.branch : [newValue?.branch]);
                  setInputUserDepartment(newValue?.department);
                  setInputUserRole(newValue?.access_level);
                  setInputUsername(newValue?.Username);
                }
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Usuário"
                />
              )}
            />
          </Box>

          <Divider orientation='vertical' variant='middle' flexItem />

          <Box>
            <MenuList dense autoFocusItem>
              <Stack sx={{ pl: 2, pr: 2, pt: 3 }}>
                <FormControl fullWidth>
                  <InputLabel>Centro</InputLabel>
                  <Select
                    value={inputUserCore}
                    label="Centro"
                    onChange={(e) => setInputUserCore(e.target.value)}
                    MenuProps={MenuProps}
                  >
                    {CORES.filter((e) => e.key !== 'NULL').map((option) => (
                      <MenuItem value={option.key} key={`${option.key}_core`}>{option.label}</MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Stack>
            </MenuList>

            <MenuList dense autoFocusItem>
              <Stack sx={{ pl: 2, pr: 2, pt: 1 }}>
                <FormControl fullWidth>
                  <InputLabel>Departamento</InputLabel>
                  <Select
                    value={inputUserDepartment}
                    label="Departamento"
                    onChange={(e) => setInputUserDepartment(e.target.value)}
                    MenuProps={MenuProps}
                  >
                    {DEPARTMENTS.filter((e) => e.key !== 'NULL').map((option) => (
                      <MenuItem value={option.key} key={`${option.key}_department`}>{option.label}</MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Stack>
            </MenuList>

            <MenuList dense autoFocusItem>
              <Stack sx={{ pl: 2, pr: 2, pt: 1 }}>
                <FormControl fullWidth>
                  <InputLabel>Nível de Acesso</InputLabel>
                  <Select
                    value={inputUserRole}
                    label="Nível de Acesso"
                    onChange={(e) => setInputUserRole(e.target.value)}
                    MenuProps={MenuProps}
                  >
                    {ACCESS_LEVELS.filter((e) => e.key !== 'NULL').map((option) => (
                      <MenuItem value={option.key} key={`${option.key}_level`}>{option.label}</MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Stack>
            </MenuList>

            <MenuList dense autoFocusItem>
              <Stack sx={{ pl: 2, pr: 2, pt: 1 }}>
                <FormControl fullWidth>
                  <InputLabel>Filial</InputLabel>
                  <Select
                    multiple
                    value={inputUserBranch}
                    label="Filial"
                    onChange={(e) => setInputUserBranch(e.target.value)}
                    MenuProps={MenuProps}
                  >
                    {BRANCHES.filter((e) => e.key !== 'NULL').map((option) => (
                      <MenuItem value={option.key} key={`${option.key}_branch`}>{option.label}</MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Stack>
            </MenuList>

            <MenuList dense autoFocusItem>
              <Stack sx={{ pl: 2, pr: 2, pt: 1 }}>
                <FormControl fullWidth>
                  <TextField
                    label="Officer"
                    variant="outlined"
                    value={inputUsername}
                    onChange={(e) => setInputUsername(e.target.value)}
                  />
                </FormControl>
              </Stack>
            </MenuList>

            <MenuList dense autoFocusItem>
              <Stack sx={{ pl: 2, pr: 2, pt: 2 }}>
                <Button onClick={onEmbed} variant="contained" endIcon={<Iconify icon="mdi:account-arrow-up" />}>
                  Embedar
                </Button>
              </Stack>
            </MenuList>
          </Box>
        </Stack>

      </MenuPopover>
    </>
  );
}
