import AWS from 'aws-sdk';
import { COGNITO_API } from '../config';

export default async function listSessions(username, setData) {
  const cognitoClient = new AWS.CognitoIdentityServiceProvider({
    apiVersion: '2016-04-19',
    region: COGNITO_API.region,
  });

  async function fetchSessionsAll(NextToken = null) {
    const poolData = {
      UserPoolId: COGNITO_API.CognitoID,
      Username: username,
      MaxResults: 60
    };
    if (NextToken) {
      poolData.NextToken = NextToken;
    }

    cognitoClient.adminListUserAuthEvents(poolData, async (err, resp) => {
      if (err) console.log(err, err.stack);
      else {
        const data = resp.AuthEvents.map(e => ({
          Username: username,
          EventId: e.EventId,
          EventResponse: e.EventResponse,
          EventType: e.EventType,
          CompromisedCredentialsDetected: e.EventRisk.CompromisedCredentialsDetected,
          RiskDecision: e.EventRisk.RiskDecision,
          City: e.EventContextData.City,
          Country: e.EventContextData.Country,
          DeviceName: e.EventContextData.DeviceName,
          IpAddress: e.EventContextData.IpAddress,
          CreationDate: e.CreationDate,
          ...Object.assign({}, ...e.ChallengeResponses.map((x) => ({ [x.ChallengeName]: x.ChallengeResponse })))
        }));
        setData(data);
        if (resp.NextToken) {
          await fetchSessionsAll(resp.NextToken);
        }
      }
    });
  }

  await fetchSessionsAll();
}