// ----------------------------------------------------------------------

function path(root, sublink) {
    return `${root}${sublink}`;
  }
  
  const ROOTS_REPORT = '/report';
  
  // ----------------------------------------------------------------------
  
  export const PATH_REPORT = {
    root: ROOTS_REPORT,
    bases: path(ROOTS_REPORT, '/bases'),   
    esforco: {
      crmLeader: path(ROOTS_REPORT, '/esforco/crmleader'), 
    }
  };
  