import { Suspense, lazy } from 'react';
import { Navigate, useRoutes, useLocation } from 'react-router-dom';
// layouts
import DashboardLayout from '../../../../layouts/dashboard';
// components
import LoadingScreen from '../../../../components/LoadingScreen';
// hooks 
import useAuth from '../../../../hooks/useAuth';
// default routes
import defaultRouter from '../../../defaultRouter';

// ----------------------------------------------------------------------

const Loadable = (Component) => (props) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { pathname } = useLocation();

  return (
    <Suspense fallback={<LoadingScreen isDashboard={
      pathname.includes('/report')} />}>
      <Component {...props} />
    </Suspense>
  );
};

export function Router() {
  const { user, username, userRole, userDepartment, userBranch, userRoutes } = useAuth();

  return useRoutes([
    ...defaultRouter({ defaultRoute: "/home", user, username, userRole, userDepartment, userBranch, userRoutes }),

    {
      path: 'report',
      element: <DashboardLayout />,
      children: [
        { element: <Navigate to="/report/bases" replace />, index: true },
        { path: 'bases', element: <Bases /> },
        {
          path: 'esforco',
          children: [
            { path: 'crmleader', element: <CRMLeader />}
          ]
        },   
      ],
    },
    {
      path: 'settings',
      element: <DashboardLayout />,
      children: [
        {
          path: 'user',
          children: [
            { element: <Navigate to="/settings/user/list" replace />, index: true },
            { path: 'list', element: <UserList /> },
            { path: ':username/edit', element: <UserCreate /> },
          ],
        }
      ]
    },

    {
      path: 'training',
      element: <DashboardLayout />,
      children: [
        { path: 'list', element: <TrainingList />},
        { path: 'new', element: <TrainingForm />},
        { path: ':classId/:training/edit', element: <TrainingForm isEdit />},
        { path: 'class/new', element: <ClassForm />},
        { path: 'class/:id/edit', element: <ClassForm isEdit />},
        { path: 'classes', element: <Classes />},
        { path: 'officer', element: <Officer />},
        { path: 'meet/new', element: <MeetingForm />},
        { path: 'meet/:meetId/edit', element: <MeetingForm isEdit />},
        { path: 'meets', element: <MeetsList />}
      ]
    },

    {
      path: 'backoffice',
      element: <DashboardLayout />,
      children: [
        { path: 'tickets', element: <FollowUp /> },
        { path: 'newticket', element: <NewTicket /> },
      ]
    },
  ]);
}

// REPORT
const Bases = Loadable(lazy(() => import('../../../../pages/VI/MELCONT/general/Bases')));
const CRMLeader = Loadable(lazy(() => import('../../../../pages/VI/ASSES/general/power-bi/esforco/CRMLeader')));

// TRAINING
const TrainingList = Loadable(lazy(() => import('../../../../pages/general/tools/training/TrainingList')));
const TrainingForm = Loadable(lazy(() => import('../../../../pages/general/tools/training/TrainingForm')));
const Classes = Loadable(lazy(() => import('../../../../pages/general/tools/training/Classes')));
const ClassForm = Loadable(lazy(() => import('../../../../pages/general/tools/training/ClassForm')));
const Officer = Loadable(lazy(() => import('../../../../pages/general/tools/training/Officer')));
const MeetingForm = Loadable(lazy(() => import('../../../../pages/general/tools/training/MeetingForm')));
const MeetsList = Loadable(lazy(() => import('../../../../pages/general/tools/training/MeetsList')));

// SETTINGS
const UserList = Loadable(lazy(() => import('../../../../pages/VI/general/user/UserList')));
const UserCreate = Loadable(lazy(() => import('../../../../pages/VI/general/user/UserCreate')));

const FollowUp = Loadable(lazy(() => import('../../../../pages/general/backoffice-tickets/FollowUp')));
const NewTicket = Loadable(lazy(() => import('../../../../pages/general/backoffice-tickets/NewTicket')));
