// routes
// import { PATH_REPORT } from '../../../../../../routes/VI/MELCONT/paths';
// components
// import SvgIconStyle from '../../../../../../components/SvgIconStyle';

// ----------------------------------------------------------------------

// const getIcon = (name) => <SvgIconStyle src={`/icons/${name}.svg`} sx={{ width: 1, height: 1 }} />;

// const ICONS = {
//   user: getIcon('ic_user'),
//   form: getIcon('ic_form'),
//   upload: getIcon('ic_upload'),
//   analytics: getIcon('ic_analytics'),
//   dashboard: getIcon('ic_dashboard'),
//   maintenance: getIcon('ic_maintenance'),
//   search: getIcon('ic_search'),
//   export: getIcon('ic_analytics_export'),
// };

export const navConfig = [

];
