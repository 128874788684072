// ----------------------------------------------------------------------

function path(root, sublink) {
  return `${root}${sublink}`;
}

const ROOTS_REPORT = '/report';

// ----------------------------------------------------------------------

export const PATH_REPORT = {
  root: ROOTS_REPORT,
  comissao: path(ROOTS_REPORT, '/comissao'),
  produtos: {
    root: path(ROOTS_REPORT, '/produtos'),
    rf: path(ROOTS_REPORT, '/produtos/rf'),
  },
  ranking: {
    root: path(ROOTS_REPORT, '/ranking'),
    ranking: path(ROOTS_REPORT, '/ranking/ranking'),
  },
};
