import { useRoutes } from 'react-router-dom';
// hooks 
import useAuth from '../../../hooks/useAuth';
// default routes
import defaultRouter from '../../defaultRouter';

// ----------------------------------------------------------------------


export function Router() {
  const { user, username, userRole, userDepartment, userRoutes } = useAuth();

  return useRoutes([
    ...defaultRouter({ defaultRoute: "/home", user, username, userRole, userDepartment, userRoutes }),
  ]);
}

