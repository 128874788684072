// components
import SvgIconStyle from '../../../../../../components/SvgIconStyle';
// routes
import { PATH_REPORT } from '../../../../../../routes/VI/ASSES/paths';

// ----------------------------------------------------------------------

const getIcon = (name) => <SvgIconStyle src={`/icons/${name}.svg`} sx={{ width: 1, height: 1 }} />;

const ICONS = {
  user: getIcon('ic_user'),
  form: getIcon('ic_form'),
  upload: getIcon('ic_upload'),
  analytics: getIcon('ic_analytics'),
  dashboard: getIcon('ic_dashboard'),
  maintenance: getIcon('ic_maintenance'),
  search: getIcon('ic_search'),
  speaker: getIcon('ic_speaker'),
  target: getIcon('ic_target'),
  question: getIcon('ic_question'),
  export: getIcon('ic_analytics_export'),
};

export const navConfig = [

  // CAMPANHAS
  // ----------------------------------------------------------------------
  {
    group: 'Campanhas',
    items: [
      {
        title: 'CRM',
        path: PATH_REPORT.campanhas.crm,
        icon: ICONS.analytics,
      },
    ],
  },

  // PERFORMANCE
  // ----------------------------------------------------------------------
  {
    subheader: 'Performance',
    group: 'Indicadores',
    // path: PATH_REPORT.performance.root,
    icon: ICONS.analytics,
    items: [
      {
        title: 'Gerencial',
        path: PATH_REPORT.performance.gerencial.advisor,
        icon: ICONS.analytics,
      },
      {
        title: 'ROAe',
        path: PATH_REPORT.performance.receita,
        icon: ICONS.analytics,
      },
      {
        title: 'Corretagem',
        path: PATH_REPORT.performance.corretagem.advisor,
        icon: ICONS.analytics,
      },
      {
        title: 'Bases',
        path: PATH_REPORT.performance.bases,
        icon: ICONS.analytics,
      },
      {
        title: 'Cross Sell',
        path: PATH_REPORT.performance.xsell,
        icon: ICONS.analytics,
      },
    ],
  },

  {
    group: 'Bônus',
    items: [
      {
        title: 'Metas',
        path: PATH_REPORT.performance.metas.advisor,
        icon: ICONS.analytics,
      },
      {
        title: 'PAN',
        path: PATH_REPORT.performance.pan,
        icon: ICONS.analytics,
      },
    ]
  },

  // OPORTUNIDADES
  // ----------------------------------------------------------------------
  {
    subheader: 'Oportunidades',
    group: 'Indicadores',
    path: PATH_REPORT.oportunidades.root,
    icon: ICONS.analytics,
    items: [
      {
        title: 'Ativações',
        path: PATH_REPORT.oportunidades.ativacoes.advisor,
        icon: ICONS.analytics,
      },
    ],
  },

  // RANKING
  // ----------------------------------------------------------------------
  // {
  //   subheader: 'Ranking',
  //   items: [
  //     {
  //       title: 'Assessoria',
  //       path: PATH_REPORT.ranking.followUp.advisor,
  //       icon: ICONS.analytics,
  //     }
  //   ]
  // },

  // ESFORÇO
  // ----------------------------------------------------------------------
  {
    subheader: 'Esforço',
    group: 'Indicadores',
    path: PATH_REPORT.esforco.root,
    icon: ICONS.analytics,
    items: [
      {
        title: 'Acompanhamento',
        path: PATH_REPORT.esforco.crmAdvisor,
        icon: ICONS.analytics,
      },
    ],
  },


  // REUNIÕES
  // ----------------------------------------------------------------------
  {
    subheader: 'Reuniões',
    group: 'Indicadores',
    path: PATH_REPORT.reunioes.root,
    icon: ICONS.analytics,
    items: [
      {
        title: 'N3',
        path: PATH_REPORT.reunioes.n3,
        icon: ICONS.analytics,
      },
      {
        title: 'Novo N3',
        path: PATH_REPORT.reunioes.newN3,
        icon: getIcon('ic_analytics_export')
      }
    ],
  },
];