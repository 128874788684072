// hooks
import useAuth from '../hooks/useAuth';
// utils
import createAvatar from '../utils/createAvatar';
//
import Avatar from './Avatar';

// ----------------------------------------------------------------------

export default function MyAvatar({ ...other }) {
  const { user, picture, username } = useAuth();

  const displayName =
    user?.attributes?.nickname ||
    user?.attributes?.name ||
    user?.attributes?.email ||
    username;

  return (
    <Avatar
      src={picture}
      alt={displayName}
      color={picture ? 'default' : createAvatar(displayName).color}
      {...other}
    >
      {createAvatar(displayName).name}
    </Avatar>
  );
}
