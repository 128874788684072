import React from 'react';
import PropTypes from 'prop-types';
import { useLocation } from 'react-router-dom';
// routes
import RouterBlank from './routes/RouterBlank';
// ADMIN
import { Router as RouterViAdminHead } from './routes/VI/ADMIN/HEAD';
import { Router as RouterViAdminExpert } from './routes/VI/ADMIN/EXPERT';
// ASSES
import { Router as RouterViAssesAdmin } from './routes/VI/ASSES/ADMIN';
import { Router as RouterViAssesFarmer } from './routes/VI/ASSES/FARMER';
import { Router as RouterViAssesLeader } from './routes/VI/ASSES/LEADER';
import { Router as RouterViAssesFollow } from './routes/VI/ASSES/FOLLOW';
import { Router as RouterViAssesFLeader } from './routes/VI/ASSES/FLEADER';
import { Router as RouterViAssesAdvisor } from './routes/VI/ASSES/ADVISOR';
import { Router as RouterViAssesManager } from './routes/VI/ASSES/MANAGER';
import { Router as RouterViAssesHead } from './routes/VI/ASSES/HEAD';
// DADOS
import { Router as RouterViDados } from './routes/VI/DADOS';
// TI
import { Router as RouterViTIExpert } from './routes/VI/TI/EXPERT';
// DIGI
import { Router as RouterViDigiManager } from './routes/VI/DIGI/MANAGER';
import { Router as RouterViDigiAdvisor } from './routes/VI/DIGI/ADVISOR';
import { Router as RouterViDigiLeader } from './routes/VI/DIGI/LEADER';
// EXP
import { Router as RouterViExp } from './routes/VI/EXP';
// EVENTOS
import { Router as RouterViEventosManager } from './routes/VI/EVENTOS/MANAGER';
// MELCONT
import { Router as RouterViMelcontExpert } from './routes/VI/MELCONT/EXPERT';
import { Router as RouterViMelcontHead } from './routes/VI/MELCONT/HEAD';
// MKT
import { Router as RouterViMktExpert } from './routes/VI/MKT/EXPERT';
import { Router as RouterViMktLeader } from './routes/VI/MKT/LEADER';
// SDR
import { Router as RouterSDRExpert } from './routes/VI/SDR/EXPERT';
// PROD
import { Router as RouterViProdManager } from './routes/VI/PROD/MANAGER';
import { Router as RouterViProdExpert } from './routes/VI/PROD/EXPERT';
// RH
import { Router as RouterViRhHead } from './routes/VI/RH/HEAD';
import { Router as RouterViRhExpert } from './routes/VI/RH/EXPERT';
import { Router as RouterViRhLeader } from './routes/VI/RH/LEADER';
// RV
import { Router as RouterViRvManager } from './routes/VI/RV/MANAGER';
import { Router as RouterViRvExpert } from './routes/VI/RV/EXPERT';
import { Router as RouterViRvLeader } from './routes/VI/RV/LEADER';
// PJ
import { Router as RouterViPjExpert } from './routes/VI/PJ/EXPERT';
// BACKOFFICE
import { Router as RouterViBackofficeExpert } from './routes/VI/BACKOFFICE/EXPERT';
// BANKING
import { Router as RouterViBankingExpert } from './routes/VI/BANKING/EXPERT';
// VCCP
import { Router as RouterVccpVccpHead } from './routes/VCCP/VCCP/HEAD';
import { Router as RouterVccpPjHead } from './routes/VCCP/PJ/HEAD';
import { Router as RouterVccpPjExpert } from './routes/VCCP/PJ/EXPERT';
// VCS
import { Router as RouterVcsVcsHead } from './routes/VCS/VCS/HEAD';
import { Router as RouterVcsSegLeader } from './routes/VCS/SEG/LEADER';
import { Router as RouterVcsSegExpert } from './routes/VCS/SEG/EXPERT';
import { Router as RouterVcsSegManager } from './routes/VCS/SEG/MANAGER';
import { Router as RouterVcsSegHead } from './routes/VCS/SEG/HEAD';
import { Router as RouterVcsPrevExpert } from './routes/VCS/PREV/EXPERT';
import { Router as RouterVcsPrevLeader } from './routes/VCS/PREV/LEADER';
import { Router as RouterVcsPrevManager } from './routes/VCS/PREV/MANAGER';
import { Router as RouterVcsPlanejLeader } from './routes/VCS/PLANEJ/LEADER';
import { Router as RouterVcsPlanejManager } from './routes/VCS/PLANEJ/MANAGER';
// VGR
import { Router as RouterVgrVgrExpert } from './routes/VGR/general';
// LUMI
import { Router as RouterLumiExpert } from './routes/LUMI/LUMI/EXPERT';
// SIMPLES
import { Router as RouterSimples } from './routes/SIMPLES';
// theme
import ThemeProvider from './theme';
import GlobalStyles from './theme/globalStyles';
// components
import RtlLayout from './components/RtlLayout';
import { ChartStyle } from './components/chart';
import ScrollToTop from './components/ScrollToTop';
import LoadingScreen from './components/LoadingScreen';
import { ProgressBarStyle } from './components/ProgressBar';
import NotistackProvider from './components/NotistackProvider';
import ThemeColorPresets from './components/ThemeColorPresets';
import ThemeLocalization from './components/ThemeLocalization';
import MotionLazyContainer from './components/animate/MotionLazyContainer';
// hooks
import useAuth from './hooks/useAuth';
//
import Page500 from './pages/general/Page500';

// ----------------------------------------------------------------------

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  componentDidCatch(error, info) {
    // Display fallback UI
    this.setState({ hasError: true });
    console.log(error);
    console.log(info);
    window.location.href = `/500`;
  }

  render() {
    if (this.state.hasError) {
      return <Page500 />;
    }
    return this.props.children;
  }
}

ErrorBoundary.propTypes = {
  children: PropTypes.any
};

// ----------------------------------------------------------------------

export default function App() {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { pathname } = useLocation();
  const { userCore, userDepartment, userRole } = useAuth();

  let routes = <RouterBlank />;
  if (!userCore || !userDepartment || !userRole ||
    userCore === 'NULL' || userDepartment === 'NULL' || userRole === 'NULL') {
    routes = <LoadingScreen isDashboard={false} />;
    window.setTimeout(() => {
      window.location.reload();
    }, 3000);
  }

  if (userCore === 'VI') {

    if (userDepartment === 'ADMIN') {
      if (userRole === 'HEAD') {
        routes = <RouterViAdminHead />;
      } else if (userRole === 'EXPERT') {
        routes = <RouterViAdminExpert />;
      }
    }

    else if (userDepartment === 'ASSES') {
      if (userRole === 'ADMIN') {
        routes = <RouterViAssesAdmin />;
      } else if (userRole === 'LEADER') {
        routes = <RouterViAssesLeader />;
      } else if (userRole === 'FARMER') {
        routes = <RouterViAssesFarmer />;
      } else if (userRole === 'FLEADER') {
        routes = <RouterViAssesFLeader />;
      } else if (userRole === 'ADVISOR') {
        routes = <RouterViAssesAdvisor />;
      } else if (userRole === 'MANAGER') {
        routes = <RouterViAssesManager />;
      } else if (userRole === 'FOLLOW') {
        routes = <RouterViAssesFollow />;
      } else if (userRole === 'HEAD') {
        routes = <RouterViAssesHead />;
      }
    }

    else if (userDepartment === 'TI') {
      routes = <RouterViTIExpert />
    }

    else if (userDepartment === 'BANKING') {
      routes = <RouterViBankingExpert />
    }

    else if (userDepartment === 'DADOS') {
      routes = <RouterViDados />;
    }

    else if (userDepartment === 'DIGI') {
      if (userRole === 'MANAGER') {
        routes = <RouterViDigiManager />;
      } else if (userRole === 'ADVISOR') {
        routes = <RouterViDigiAdvisor />;
      } else if (userRole === 'LEADER') {
        routes = <RouterViDigiLeader />;
      }
    }

    else if (userDepartment === 'EXP') {
      routes = <RouterViExp />;
    }

    else if (userDepartment === 'EVENTOS') {
      // if(userRole === 'EXPERT')
      //   routes = <RouterViEventosExpert />;
      if (userRole === 'MANAGER')
        routes = <RouterViEventosManager />;
    }

    else if (userDepartment === 'MELCONT') {
      if (userRole === 'EXPERT')
        routes = <RouterViMelcontExpert />;
      if (userRole === 'HEAD')
        routes = <RouterViMelcontHead />;
    }

    else if (userDepartment === 'MKT') {
      if (userRole === 'EXPERT')
        routes = <RouterViMktExpert />
      else if (userRole === 'LEADER')
        routes = <RouterViMktLeader />
    }

    else if (userDepartment === 'SDR') {
      routes = <RouterSDRExpert />
    }

    else if (userDepartment === 'PROD') {
      if (userRole === 'MANAGER') {
        routes = <RouterViProdManager />;
      } else if (userRole === 'EXPERT')
        routes = <RouterViProdExpert />
    }

    else if (userDepartment === 'RH') {
      if (userRole === 'LEADER') {
        routes = <RouterViRhLeader />
      } else if (userRole === 'HEAD') {
        routes = <RouterViRhHead />;
      } else if (userRole === 'EXPERT') {
        routes = <RouterViRhExpert />;
      }
    }

    else if (userDepartment === 'RV') {
      if (userRole === 'MANAGER') {
        routes = <RouterViRvManager />;
      } else if (userRole === 'EXPERT') {
        routes = <RouterViRvExpert />;
      }
      else if (userRole === 'LEADER') {
        routes = <RouterViRvLeader />;
      }
    }

    else if (userDepartment === 'PJ') {
      if (userRole === 'EXPERT') {
        routes = <RouterViPjExpert />;
      }
    }

    else if (userDepartment === 'BACKOFF') {
      if (userRole === 'EXPERT') {
        routes = <RouterViBackofficeExpert />;
      }
    }
  }

  else if (userCore === 'VCS') {
    if (userDepartment === 'VCS') {
      if (userRole === 'HEAD') {
        routes = <RouterVcsVcsHead />;
      }
    }
    else if (userDepartment === 'PREV') {
      if (userRole === 'MANAGER') {
        routes = <RouterVcsPrevManager />;
      } else if (userRole === 'EXPERT') {
        routes = <RouterVcsPrevExpert />;
      } else if (userRole === 'LEADER') {
        routes = <RouterVcsPrevLeader />;
      }
    }
    else if (userDepartment === 'PLANEJ') {
      if (userRole === 'MANAGER') {
        routes = <RouterVcsPlanejManager />;
      } else if (userRole === 'LEADER') {
        routes = <RouterVcsPlanejLeader />;
      }
    }
    else if (userDepartment === 'SEG') {
      if (userRole === 'MANAGER') {
        routes = <RouterVcsSegManager />;
      } else if (userRole === 'LEADER') {
        routes = <RouterVcsSegLeader />;
      } else if (userRole === 'EXPERT') {
        routes = <RouterVcsSegExpert />;
      } else if (userRole === 'HEAD') {
        routes = <RouterVcsSegHead />;
      }
    }
  }

  else if (userCore === 'VCCP') {
    if (userDepartment === 'VCCP') {
      if (userRole === 'HEAD') {
        routes = <RouterVccpVccpHead />;
      }
    } else if (userDepartment === 'PJ') {
      if (userRole === 'HEAD') {
        routes = <RouterVccpPjHead />
      } else if (userRole === 'EXPERT') {
        routes = <RouterVccpPjExpert />
      }
    }
  }

  else if (userCore === 'VGR') {
    if (userDepartment === 'VGR')
      routes = <RouterVgrVgrExpert />
  }

  else if (userCore === 'LUMI') {
    if (userDepartment === 'LUMI')
      routes = <RouterLumiExpert />
  }

  else if (userCore === 'SIMPLES') {
    routes = <RouterSimples />
  }

  return (
    <ThemeProvider>
      <ThemeColorPresets>
        <ThemeLocalization>
          <RtlLayout>
            <NotistackProvider>
              <MotionLazyContainer>
                <GlobalStyles />
                <ProgressBarStyle />
                <ChartStyle />
                {(pathname.includes('/report') || pathname.includes('/export') || pathname.includes('/okr') ||
                  pathname.includes('/superranking') || (pathname.includes('/campaigns') && !pathname.includes('/dashboard')))
                }
                <ScrollToTop />
                <ErrorBoundary>
                  {routes}
                </ErrorBoundary>
              </MotionLazyContainer>
            </NotistackProvider>
          </RtlLayout>
        </ThemeLocalization>
      </ThemeColorPresets>
    </ThemeProvider>
  );
}