import CryptoJS from 'crypto-js';

// https://stackoverflow.com/questions/11567290/cryptojs-and-pycrypto-working-together
import { CIPHER_COGNITO } from '../config';

export function encrypt(msgString) {
  // encoded key
  const key = CryptoJS.enc.Utf8.parse(CIPHER_COGNITO);

  // msgString is expected to be Utf8 encoded
  const iv = CryptoJS.lib.WordArray.random(16);
  const encrypted = CryptoJS.AES.encrypt(msgString, key, { iv });
  return iv.concat(encrypted.ciphertext).toString(CryptoJS.enc.Base64);
}

export function decrypt(ciphertextStr) {
  // encoded key
  const key = CryptoJS.enc.Utf8.parse(CIPHER_COGNITO);

  const ciphertext = CryptoJS.enc.Base64.parse(ciphertextStr);

  // split IV and ciphertext
  const iv = ciphertext.clone();
  iv.sigBytes = 16;
  iv.clamp();
  ciphertext.words.splice(0, 4); // delete 4 words = 16 bytes
  ciphertext.sigBytes -= 16;

  // decryption
  const decrypted = CryptoJS.AES.decrypt({ ciphertext }, key, { iv });
  return decrypted.toString(CryptoJS.enc.Utf8);
}
