// ----------------------------------------------------------------------

function path(root, sublink) {
  return `${root}${sublink}`;
}

const ROOTS_REPORT = '/report';

export const PATH_REPORT = {
  root: ROOTS_REPORT,
  ativacoes: path(ROOTS_REPORT, '/ativacoes'),
  gerencial: path(ROOTS_REPORT, '/gerencial'),
  campaigns: path(ROOTS_REPORT, '/campaigns'),
  captacao: path(ROOTS_REPORT, '/captacao')
};
