import PropTypes from 'prop-types';
import { Outlet } from 'react-router-dom';
// @mui
import { styled } from '@mui/material/styles';
// components
import Logo from '../components/Logo';

// ----------------------------------------------------------------------

const HeaderStyle = styled('header')(({ theme }) => ({
  top: 0,
  left: 0,
  lineHeight: 0,
  width: '100%',
  position: 'absolute',
  padding: theme.spacing(3, 3, 0),
  [theme.breakpoints.up('sm')]: {
    padding: theme.spacing(5, 5, 0)
  }
}));

// ----------------------------------------------------------------------

LogoOnlyLayout.propTypes = {
  content: PropTypes.bool
}
export default function LogoOnlyLayout({ content }) {
  return (
    <>
      <HeaderStyle sx={ content && { pt: '16px !important' }}>
        <Logo />
      </HeaderStyle>
      <Outlet />
    </>
  );
}
