import { Suspense, lazy } from 'react';
import { Navigate, useRoutes, useLocation } from 'react-router-dom';
// layouts
import DashboardLayout from '../../../../layouts/dashboard';
// components
import LoadingScreen from '../../../../components/LoadingScreen';
// hooks 
import useAuth from '../../../../hooks/useAuth';
// default routes
import defaultRouter from '../../../defaultRouter';

// ----------------------------------------------------------------------

const Loadable = (Component) => (props) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { pathname } = useLocation();

  return (
    <Suspense fallback={<LoadingScreen isDashboard={
      pathname.includes('/dashboard') ||
      pathname.includes('/report')} />}>
      <Component {...props} />
    </Suspense>
  );
};

export function Router() {
  const { user, username, userRole, userDepartment, userRoutes } = useAuth();

  return useRoutes([
    ...defaultRouter({ defaultRoute: "/home", user, username, userRole, userDepartment, userRoutes }),

    {
      path: 'dashboard',
      element: <DashboardLayout />,
      children: [
        { element: <Navigate to="/dashboard/base" replace />, index: true },
        { path: 'base', element: <GeneralBase /> },
      ],
    },

    {
      path: 'report',
      element: <DashboardLayout />,
      children: [
        { element: <Navigate to="/report/digital" replace />, index: true },
        { path: 'digital', element: <PainelDigital /> },
        { path: 'gerencial', element: <Gerencial /> },

        {
          path: 'ranking',
          children: [
            { element: <Navigate to="/report/ranking/ranking" replace />, index: true },
            { path: 'ranking', element: <RankingAdvisor /> },
          ],
        },
      ],
    },
  ]);
}

// DASHBOARD
const GeneralBase = Loadable(lazy(() => import('../../../../pages/VI/general/GeneralBase')));

// REPORT
const Gerencial = Loadable(lazy(() => import('../../../../pages/VI/DIGI/general/power-bi/Gerencial')));
const PainelDigital = Loadable(lazy(() => import('../../../../pages/VI/DIGI/general/power-bi/PainelDigital')));
const RankingAdvisor = Loadable(lazy(() => import('../../../../pages/VI/ASSES/general/power-bi/ranking/RankingAdvisor')));
