// @mui
import { Stack, Button, Typography } from '@mui/material';
// routes
import { PATH_DOCS } from '../../../routes/generalPaths';
// assets
import { DocIllustration } from '../../../assets';

// ----------------------------------------------------------------------

export default function NavbarDocs() {
  return (
    <Stack spacing={3} alignItems="center" sx={{ px: 5, pb: 5, mt: 10, width: 1, textAlign: 'center' }}>
      <DocIllustration sx={{ width: 1 }} />

      <div>
        <Typography variant="body2" sx={{ color: 'text.secondary' }}>
          Precisa de ajuda?
          <br /> Entre em contato conosco
        </Typography>
      </div>

      <Button href={PATH_DOCS} target="_top" rel="noopener" variant="contained">
        Contato
      </Button>
    </Stack>
  );
}
