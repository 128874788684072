import { Suspense } from 'react';
import { useRoutes, useLocation } from 'react-router-dom';
// layouts
// import DashboardLayout from '../../../../layouts/dashboard';
// components
import LoadingScreen from '../../../../components/LoadingScreen';
// hooks 
import useAuth from '../../../../hooks/useAuth';
// default routes
import defaultRouter from '../../../defaultRouter';

// ----------------------------------------------------------------------

// eslint-disable-next-line
const Loadable = (Component) => (props) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { pathname } = useLocation();

  return (
    <Suspense fallback={<LoadingScreen isDashboard={
      pathname.includes('/report')} />}>
      <Component {...props} />
    </Suspense>
  );
};

export function Router() {
  const { user, username, userRole, userDepartment, userBranch, userRoutes } = useAuth();

  return useRoutes([
    ...defaultRouter({ defaultRoute: "/home", user, username, userRole, userDepartment, userBranch, userRoutes })    
  ]);
}
