import { capitalize } from 'lodash';

// ----------------------------------------------------------------------

function getFirstCharacter(name) {
  return capitalize(name && name.charAt(0));
}

function getAvatarColor(name) {
  return (name || name.length % 2 === 0) ? 'primary' : 'secondary';
}

export default function createAvatar(name = 'V') {
  return {
    name: getFirstCharacter(name),
    color: getAvatarColor(name),
  };
}
