export const ACCESS_LEVELS = [
  { key: 'DEV', label: 'Desenvolvedor' },
  { key: 'ADMIN', label: 'Administrador' },
  { key: 'ADVISOR', label: 'Assessor' },
  { key: 'EXPERT', label: 'Especialista' },
  { key: 'INTERN', label: 'Estagiário' },
  { key: 'FARMER', label: 'Farmer' },
  { key: 'MANAGER', label: 'Gestor' },
  { key: 'HEAD', label: 'Head' },
  { key: 'LEADER', label: 'Líder' },
  { key: 'FLEADER', label: 'Líder Farmer' },
  { key: 'FOLLOW', label: 'Melhoria Contínua' },
  { key: 'NULL', label: 'NULL' },
];

export const CORES = [
  { key: 'VI', label: 'Valor Investimentos' },
  { key: 'LUMI', label: 'Lumi Research' },
  { key: 'VCS', label: 'Valor Corretora de Seguros' },
  { key: 'VCCP', label: 'VCCP' },
  { key: 'VE', label: 'Valor Educação' },
  { key: 'VGR', label: 'Valor Gestora de Recursos' },
  { key: 'PRISMA', label: 'Prisma' },
  { key: 'SIMPLES', label: 'Simples' },
  { key: 'NULL', label: 'NULL' },
];

export const DEPARTMENTS = [
  { key: 'ASSES', label: 'Assessoria' },
  { key: 'ADMIN', label: 'Admin. Fin.' },
  { key: 'BACKOFF', label: 'BackOffice'},
  { key: 'DADOS', label: 'Dados' },
  { key: 'DIGI', label: 'Digital' },
  { key: 'EVENTOS', label: 'Eventos' },
  { key: 'EXP', label: 'Expansão' },
  { key: 'RH', label: 'Gente e Gestão' },
  { key: 'JUR', label: 'Jurídico' },
  { key: 'MKT', label: 'Marketing' },
  { key: 'MELCONT', label: 'Melhoria Contínua' },
  { key: 'BANKING', label: 'Mesa Banking' },
  { key: 'PJ', label: 'PJ' },
  { key: 'PLANEJ', label: 'Plan. Fin.' },
  { key: 'PREV', label: 'Previdência' },
  { key: 'PROD', label: 'Produtos' },
  { key: 'RV', label: 'Renda Variável' },
  { key: 'SDR', label: 'SDR' },
  { key: 'SEG', label: 'Seguros' },
  { key: 'SIMPLES', label: 'Simples' },
  { key: 'TI', label: 'TI' },
  { key: 'VE', label: 'Valor Educação' },
  { key: 'VCCP', label: 'VCCP' },
  { key: 'VCS', label: 'VCS' },
  { key: 'VGR', label: 'VGR' },
  { key: 'LUMI', label: 'Lumi' },
  { key: 'NULL', label: 'NULL' },
];

export const BRANCHES = [
  { key: 'BH', label: 'Belo Horizonte' },
  { key: 'BRU', label: 'Brumadinho' },
  { key: 'BSB', label: 'Brasília' },
  { key: 'CAXU', label: 'Cachoeiro' },
  { key: 'DIGITAL', label: 'Digital' },
  { key: 'GYN', label: 'Goiânia' },
  { key: 'RJ', label: 'Rio de Janeiro' },
  { key: 'SP', label: 'São Paulo' },
  { key: 'VIX', label: 'Vitória' },
  // { key: 'NULL', label: 'NULL' },
];

export const STATES = [
  { key: 'ES', label: 'Espírito Santo' },
  { key: 'AC', label: 'Acre' },
  { key: 'AL', label: 'Alagoas' },
  { key: 'AP', label: 'Amapá' },
  { key: 'AM', label: 'Amazonas' },
  { key: 'BA', label: 'Bahia' },
  { key: 'CE', label: 'Ceará' },
  { key: 'GO', label: 'Goiás' },
  { key: 'MA', label: 'Maranhão' },
  { key: 'MT', label: 'Mato Grosso' },
  { key: 'MS', label: 'Mato Grosso do Sul' },
  { key: 'MG', label: 'Minas Gerais' },
  { key: 'PA', label: 'Pará' },
  { key: 'PB', label: 'Paraíba' },
  { key: 'PR', label: 'Paraná' },
  { key: 'PE', label: 'Pernambuco' },
  { key: 'PI', label: 'Piauí' },
  { key: 'RJ', label: 'Rio de Janeiro' },
  { key: 'RN', label: 'Rio Grande do Norte' },
  { key: 'RS', label: 'Rio Grande do Sul' },
  { key: 'RO', label: 'Rondônia' },
  { key: 'RR', label: 'Roraima' },
  { key: 'SC', label: 'Santa Catarina' },
  { key: 'SP', label: 'São Paulo' },
  { key: 'SE', label: 'Sergipe' },
  { key: 'TO', label: 'Tocantins' },
  { key: 'DF', label: 'Distrito Federal' },
];

export const CORES_MAP = {
  VI: 'Valor Investimentos',
  VCS: 'Valor Corretora de Seguros',
  VE: 'Valor Educação',
  VGR: 'Valor Gestora de Recursos',
  VCCP: 'VCCP',
  LUMI: 'Lumi Research',
  PRISMA: 'Prisma',
  SIMPLES: 'Simples'
};

export const DEPARTMENTS_MAP = {
  ASSES: 'Assessoria',
  ADMIN: 'Administrativo Financeiro',
  BACKOFF: 'BackOffice',
  DADOS: 'Dados',
  DIGI: 'Digital',
  EVENTOS: 'Eventos',
  EXP: 'Expansão',
  GENTE: 'Gente e Cultura',
  JUR: 'Jurídico',
  MKT: 'Marketing',
  SDR: 'SDR',
  PJ: 'PJ',
  PLANEJ: 'Plan. Fin.',
  PREV: 'Previdência',
  PROD: 'Produtos',
  MELCONT: 'Melhoria Contínua',
  BANKING: 'Mesa Banking',
  RV: 'Renda Variável',
  RH: 'Gente e Gestão',
  SEG: 'Seguros',
  SIMPLES: 'Simples',
  VE: 'Valor Educação',
  VCCP: 'VCCP',
  VCS: 'Valor Corretora de Seguros',
  VGR: 'Valor Gestora de Recursos',
  LUMI: 'Lumi',
  TI: 'TI',
  NULL: '',
};

export const ACCESS_LEVELS_MAP = {
  DEV: 'Desenvolvedor',
  ADMIN: 'Administrador',
  HEAD: 'Head',
  MANAGER: 'Gestor',
  LEADER: 'Líder',
  ADVISOR: 'Assessor',
  EXPERT: 'Especialista',
  FLEADER: 'Líder Farmer',
  FARMER: 'Farmer',
  FOLLOW: 'Melhoria Contínua',
  INTERN: 'Estagiário',
};

export const BRANCHES_MAP = {
  BH: 'Belo Horizonte',
  BRU: 'Brumadinho',
  BSB: 'Brasília',
  CAXU: 'Cachoeiro de Itapemirim',
  DIGITAL: 'Digital',
  GYN: 'Goiânia',
  RJ: 'Rio de Janeiro',
  SP: 'São Paulo',
  VIX: 'Vitória',
  NACIONAL: 'Nacional',
  'VI DIGITAL': 'VI Digital',
  NULL: ''
};

export const MENAGERIAL_LEVEL = [
  'PLENO',
  'LIDER 0',
  'MASTER',
  'SENIOR I',
  'SENIOR II',
  'DIGITAL 0',
  'ESPECIALISTA PJ',
  'GERENCIAL 0',
  'DIGITAL_LIDER',
  'DIGITAL',
  'PLENO 0',
  'GESTOR 0',
  'BACKOFFICE',
  'GESTOR',
  'JUNIOR 0',
  'JUNIOR',
  'INVESTOR',
  'TRADER',
  'LIDER',
  'EXTERNO 0'
]
