import { Suspense, lazy } from 'react';
import { Navigate, useRoutes, useLocation } from 'react-router-dom';
// layouts
import DashboardLayout from '../layouts/dashboard';
import LogoOnlyLayout from '../layouts/LogoOnlyLayout';
// components
import LoadingScreen from '../components/LoadingScreen';

// ----------------------------------------------------------------------

const Loadable = (Component) => (props) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { pathname } = useLocation();

  return (
    <Suspense fallback={<LoadingScreen isDashboard={
      pathname.includes('/in')} />}>
      <Component {...props} />
    </Suspense>
  );
};

export default function Router() {

  return useRoutes([
    // Self profile
    {
      path: 'in',
      element: <DashboardLayout />,
      children: [
        { element: <Navigate to="/in/account" replace />, index: true },
        { path: 'account', element: <UserAccount /> },
        { path: 'welcome', element: <Welcome /> },
      ],
    },

    // Main Routes
    {
      path: '*',
      element: <LogoOnlyLayout />,
      children: [
        { path: '500', element: <Page500 /> },
        { path: '404', element: <NotFound /> },
        { path: 'maintenance', element: <Maintenance /> },
        { path: '*', element: <Redirect /> },
        // { path: '*', element: <Navigate to="/404" replace /> },
      ],
    },
    { path: '/', element: <Navigate to={"/in/welcome"} replace /> },
    // { path: '*', element: <Navigate to="/404" replace /> },
    { path: '*', element: <Redirect /> },

    // TOOLS
    {
      path: 'tools',
      element: <DashboardLayout />,
      children: [
        { element: <Navigate to="/tools/find" replace />, index: true },

        // { path: 'find', element: <FindClient /> },

        { path: 'links', element: <Links /> },

        { path: 'scheduler', element: <SchedulerV2 /> },
        {
          path: 'rooms',
          children: [
            { element: <Navigate to="/rooms/list" replace />, index: true },
            { path: 'list', element: <RoomList /> },
          ]
        },

        {
          path: 'sharing',
          children: [
            { element: <Navigate to="/tools/sharing/list" replace />, index: true },
            { path: 'list', element: <Posts /> },
            { path: 'post/:postid', element: <Post /> },
          ],
        },

        {
          path: 'gv',
          children: [
            { element: <Navigate to="/tools/gv/fullscreen" replace />, index: true },
            { path: 'fullscreen', element: <GestaoAVista /> }
          ]
        },
      ],
    },

    {
      path: 'orgchart',
      element: <DashboardLayout />,
      children: [
        { element: <OrgChart />, index: true },
      ]
    },

    {
      path: 'download',
      element: <DashboardLayout />,
      children: [
        { element: <Download />, index: true },
      ]
    },

    // BACKOFFICE
    {
      path: 'backoffice',
      element: <DashboardLayout />,
      children: [
        { path: 'tickets', element: <FollowUp /> },
        { path: 'newticket', element: <NewTicket /> }
      ]
    },

    {
      path: 'eventcontrol',
      element: <DashboardLayout />,
      children: [
        { element: <EventControl />, index: true },
      ]
    },
  ]
  );
}

// IMPORT COMPONENTS
// Self profile
const Welcome = Loadable(lazy(() => import('../pages/general/Welcome')));
const UserAccount = Loadable(lazy(() => import('../pages/VI/general/UserAccount')));

// Main Routes
const Page500 = Loadable(lazy(() => import('../pages/general/Page500')));
const NotFound = Loadable(lazy(() => import('../pages/general/Page404')));
const Maintenance = Loadable(lazy(() => import('../pages/general/Maintenance')));
const Redirect = Loadable(lazy(() => import('../pages/general/Redirect')));

// TOOLS
const Links = Loadable(lazy(() => import('../pages/general/Links')));
const Post = Loadable(lazy(() => import('../pages/general/tools/post/Post')));
const Posts = Loadable(lazy(() => import('../pages/general/tools/post/Posts')));
const SchedulerV2 = Loadable(lazy(() => import('../pages/general/tools/scheduler/SchedulerV2')));
const RoomList = Loadable(lazy(() => import('../pages/general/tools/scheduler/RoomList')));

// GV
const GestaoAVista = Loadable(lazy(() => import('../pages/general/tools/gestaoAVista/GestaoAVista')));

const OrgChart = Loadable(lazy(() => import('../pages/general/orgchart/OrgChart')));
const Download = Loadable(lazy(() => import('../pages/general/Download')));
const EventControl = Loadable(lazy(() => import('../pages/general/events/EventControl')));

// BACKOFFICE
const FollowUp = Loadable(lazy(() => import('../pages/general/backoffice-tickets/FollowUp')));
const NewTicket = Loadable(lazy(() => import('../pages/general/backoffice-tickets/NewTicket')));
