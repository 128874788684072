// ----------------------------------------------------------------------

function path(root, sublink) {
  return `${root}${sublink}`;
}

const ROOTS_REPORT = '/report';

// ----------------------------------------------------------------------

export const PATH_REPORT = {
  root: ROOTS_REPORT,
  produtos: {
    root: path(ROOTS_REPORT, '/produtos'),
    rf: path(ROOTS_REPORT, '/produtos/rf'),
    aderencia: path(ROOTS_REPORT, '/produtos/aderencia'),
  },
  performance: {
    root: path(ROOTS_REPORT, '/performance'),
    corretagem: {
      advisor: path(ROOTS_REPORT, '/performance/corretagem/advisor'),
    },
  },
  campaigns: {
    root: path(ROOTS_REPORT, '/campaigns'),
    crm: path(ROOTS_REPORT, '/campaigns'),
  }
};
