import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useSnackbar } from 'notistack';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import { Box, MenuItem, Tooltip } from '@mui/material';
import Iconify from './Iconify';

ShareButton.propTypes = {
  link: PropTypes.string,
};
export default function ShareButton({ link }) {
  const [open, setOpen] = useState(false);
  const [shareLink] = useState(link);

  const { enqueueSnackbar } = useSnackbar();

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleCopy = () => {
    enqueueSnackbar("Link copiado", { persist: true });
    navigator.clipboard.writeText(shareLink).then(() => {
      enqueueSnackbar("Link copiado", { persist: true });
      handleClose();
    }, (err) => {
      enqueueSnackbar("Não foi possivel copiar o link:", { variant: 'error', persist: true });
      console.error('Não foi possivel copiar o link: ', err);
    });
  };

  return (
    <div>
      <Tooltip title="Link parametrizado da página da valor para compartilhar com clientes">
        <MenuItem
          sx={{ typography: 'body2', py: 1, px: 2, borderRadius: 1 }}
          onClick={handleClickOpen}
        >
          <Box
            component={Iconify}
            icon={"pepicons-pop:chain"}
            sx={{
              mr: 2,
              width: 24,
              height: 24,
            }}
          />
          Sua Página
        </MenuItem>
      </Tooltip>
      <Dialog fullWidth open={open} onClose={handleClose}>
        <DialogTitle>Link parametrizado da página da valor para compartilhar com clientes</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            label="Link"
            type="url"
            fullWidth
            value={shareLink}
            InputProps={{
              readOnly: true,
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancelar
          </Button>
          <Button onClick={handleCopy} color="primary">
            Copiar Link
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};