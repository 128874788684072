// components
import SvgIconStyle from '../../../../../../components/SvgIconStyle';
// routes
import { PATH_REPORT } from '../../../../../../routes/VI/ASSES/paths';

// ----------------------------------------------------------------------

const getIcon = (name) => <SvgIconStyle src={`/icons/${name}.svg`} sx={{ width: 1, height: 1 }} />;

const ICONS = {
  user: getIcon('ic_user'),
  form: getIcon('ic_form'),
  upload: getIcon('ic_upload'),
  analytics: getIcon('ic_analytics'),
  dashboard: getIcon('ic_dashboard'),
  maintenance: getIcon('ic_maintenance'),
  search: getIcon('ic_search'),
  speaker: getIcon('ic_speaker'),
  target: getIcon('ic_target'),
  question: getIcon('ic_question'),
  export: getIcon('ic_analytics_export'),
};

export const navConfig = [

  // PERFORMANCE
  // ----------------------------------------------------------------------
  {
    subheader: 'Performance',
    group: 'Indicadores',
    path: PATH_REPORT.performance.root,
    icon: ICONS.analytics,
    items: [
      {
        title: 'Gerencial',
        path: PATH_REPORT.performance.gerencial.root,
        icon: ICONS.analytics,
        children: [
          { title: 'Individual', path: PATH_REPORT.performance.gerencial.advisor },
          { title: 'Equipe', path: PATH_REPORT.performance.gerencial.leader },
        ],
      },
      {
        title: 'Receita',
        path: PATH_REPORT.performance.receita,
        icon: ICONS.analytics,
      },
      {
        title: 'Corretagem',
        path: PATH_REPORT.performance.corretagem.root,
        icon: ICONS.analytics,
        children: [
          { title: 'Individual', path: PATH_REPORT.performance.corretagem.advisor },
          { title: 'Equipe', path: PATH_REPORT.performance.corretagem.leader },
        ],
      },
      {
        title: 'Bases',
        path: PATH_REPORT.performance.bases,
        icon: ICONS.analytics,
      },
      {
        title: 'Cross Sell',
        path: PATH_REPORT.performance.xsell,
        icon: ICONS.analytics,
      },    
    ],
  },

  {
    group: 'Bônus',
    items: [
      {
        title: 'Metas',
        path: PATH_REPORT.performance.metas.farmer,
        icon: ICONS.analytics,
      },
    ]
  },

  // OPORTUNIDADES
  // ----------------------------------------------------------------------
  {
    subheader: 'Oportunidades',
    group: 'Indicadores',
    path: PATH_REPORT.oportunidades.root,
    icon: ICONS.analytics,
    items: [
      {
        title: 'Ativações',
        path: PATH_REPORT.oportunidades.root,
        icon: ICONS.analytics,
        children: [
          { title: 'Individual', path: PATH_REPORT.oportunidades.ativacoes.advisor },
          { title: 'Equipe', path: PATH_REPORT.oportunidades.ativacoes.leader },
        ],
      },
    ],
  },

  // ESFORÇO
  // ----------------------------------------------------------------------
  {
    subheader: 'Esforço',
    group: 'Indicadores',
    path: PATH_REPORT.esforco.root,
    icon: ICONS.analytics,
    items: [
      {
        title: 'Relacionamento',
        path: PATH_REPORT.esforco.regua.root,
        icon: ICONS.analytics,
        children: [
          { title: 'Individual', path: PATH_REPORT.esforco.regua.advisor },
          { title: 'Equipe', path: PATH_REPORT.esforco.regua.leader },
        ],
      },
      {
        title: 'Leads',
        path: PATH_REPORT.esforco.leads.root,
        icon: ICONS.analytics,
        children: [
          { title: 'Individual', path: PATH_REPORT.esforco.leads.advisor },
          { title: 'Equipe', path: PATH_REPORT.esforco.leads.leader },
        ],
      },
    ],
  },
];