import { Suspense, lazy } from 'react';
import { Navigate, useRoutes, useLocation } from 'react-router-dom';
// layouts
import DashboardLayout from '../../../../layouts/dashboard';
// components
import LoadingScreen from '../../../../components/LoadingScreen';
// hooks 
import useAuth from '../../../../hooks/useAuth';
// default routes
import defaultRouter from '../../../defaultRouter';

// ----------------------------------------------------------------------

const Loadable = (Component) => (props) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { pathname } = useLocation();

  return (
    <Suspense fallback={<LoadingScreen isDashboard={
      pathname.includes('/report')} />}>
      <Component {...props} />
    </Suspense>
  );
};

export function Router() {
  const { user, username, userRole, userDepartment, userBranch, userRoutes } = useAuth();

  return useRoutes([
    ...defaultRouter({ defaultRoute: "/home", user, username, userRole, userDepartment, userBranch, userRoutes }),
    {
      path: 'settings',
      element: <DashboardLayout />,
      children: [
        {
          path: 'user',
          children: [
            { element: <Navigate to="/settings/user/list" replace />, index: true },
            { path: 'list', element: <UserList /> },
            { path: ':username/edit', element: <UserCreate /> },
          ],
        },
      ],
    },
    {
      path: 'superranking/leader',
      element: <DashboardLayout />,
      children: [
        { element: <Navigate to="/superranking/leader/general" replace />, index: true },
        { path: 'general', element: <SuperRankingLeaderGeneral /> },
      ]
    },
    {
      path: 'report',
      element: <DashboardLayout />,
      children: [
        { element: <Navigate to="/report/ranking" replace />, index: true },

        {
          path: 'ranking',
          children: [
            { element: <Navigate to="/report/ranking/follow-up" replace />, index: true },
            {
              path: 'follow-up',
              children: [
                { element: <Navigate to="/report/ranking/follow-up/advisor" replace />, index: true },
                { path: 'advisor', element: <RankingAdvisor /> },
                { path: 'leader', element: <RankingLeader /> },
                { path: 'manager', element: <RankingManager /> },
              ]
            },
          ],
        },   
        {
          path: 'performance',
          children: [
            { element: <Navigate to="/report/performance/bases" replace />, index: true },
            { path: 'bases', element: <Bases /> },  
            { path: 'pan', element: <PAN /> },  
            { path: 'pan2', element: <PAN2 /> },          
          ],
        },  
        {
          path: 'esforco',
          children: [
            { path: 'crmleader', element: <CRMLeader />}
          ]
        },     
      ],
    },
  ]);
}

// SUPER RANKING
const SuperRankingLeaderGeneral = Loadable(lazy(() => import('../../../../pages/VI/ASSES/general/power-bi/SuperRankingLeaderGeneral')));
// USERS
const UserList = Loadable(lazy(() => import('../../../../pages/VI/general/user/UserList')));
const UserCreate = Loadable(lazy(() => import('../../../../pages/VI/general/user/UserCreate')));
// REPORT
const RankingAdvisor = Loadable(lazy(() => import('../../../../pages/VI/ASSES/general/power-bi/ranking/RankingAdvisor')));
const RankingLeader = Loadable(lazy(() => import('../../../../pages/VI/ASSES/LEADER/power-bi/ranking/RankingLeader')));
const RankingManager = Loadable(lazy(() => import('../../../../pages/VI/ASSES/MANAGER/power-bi/ranking/RankingManager')));
const Bases = Loadable(lazy(() => import('../../../../pages/VI/ASSES/general/power-bi/performance/Bases')));
const PAN = Loadable(lazy(() => import('../../../../pages/VI/ASSES/general/power-bi/performance/PAN')));
const PAN2 = Loadable(lazy(() => import('../../../../pages/VI/ASSES/general/power-bi/performance/PAN2')));
const CRMLeader = Loadable(lazy(() => import('../../../../pages/VI/ASSES/general/power-bi/esforco/CRMLeader')));
