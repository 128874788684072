import PropTypes from 'prop-types';
import { m } from 'framer-motion';
// @mui
import { alpha, styled, useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';
//
import Logo from './Logo';
import ProgressBar from './ProgressBar';

// ----------------------------------------------------------------------

const RootStyle = styled('div')(({ theme, isdashboard }) => ({
  zIndex: 99999,
  width: '100%',
  height: '50vh',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  backgroundColor: theme.palette.background.default,
  ...(isdashboard === 'false' ? {
    right: 0,
    bottom: 0,
    position: 'fixed',
    height: '100%',
  } : {})
}));

// ----------------------------------------------------------------------

LoadingScreen.propTypes = {
  isDashboard: PropTypes.bool,
};

export default function LoadingScreen({ isDashboard = false, ...other }) {
  const theme = useTheme();
  return (
    <>
      <ProgressBar />

      <RootStyle isdashboard={isDashboard.toString()} {...other}>
        <m.div
          animate={{
            scale: [1, 0.9, 0.9, 1, 1],
            opacity: [1, 0.48, 0.48, 1, 1],
          }}
          transition={{
            duration: 2,
            ease: 'easeInOut',
            repeatDelay: 1,
            repeat: Infinity,
          }}
        >
          {theme.palette.mode === 'light' ?
            <Logo single disabledLink sx={{ width: 64, height: 64 }} /> :
            <Logo color={theme.palette.grey[300]} single disabledLink sx={{ width: 64, height: 64 }} />
          }
        </m.div>

        <Box
          component={m.div}
          animate={{
            scale: [1.2, 1, 1, 1.2, 1.2],
            rotate: [270, 0, 0, 270, 270],
            opacity: [0.25, 1, 1, 1, 0.25],
            borderRadius: ['25%', '25%', '50%', '50%', '25%'],
          }}
          transition={{ ease: 'linear', duration: 3.2, repeat: Infinity }}
          sx={{
            width: 100,
            height: 100,
            borderRadius: '25%',
            position: 'absolute',
            border:
              theme.palette.mode === 'light' ?
                (theme) => `solid 3px ${alpha(theme.palette.primary.dark, 0.24)}`
                :
                (theme) => `solid 3px ${alpha(theme.palette.grey[300], 0.24)}`,
          }
          }
        />

        <Box
          component={m.div}
          animate={{
            scale: [1, 1.2, 1.2, 1, 1],
            rotate: [0, 270, 270, 0, 0],
            opacity: [1, 0.25, 0.25, 0.25, 1],
            borderRadius: ['25%', '25%', '50%', '50%', '25%'],
          }}
          transition={{
            ease: 'linear',
            duration: 3.2,
            repeat: Infinity,
          }}
          sx={{
            width: 120,
            height: 120,
            borderRadius: '25%',
            position: 'absolute',
            border:
              theme.palette.mode === 'light' ?
                (theme) => `solid 8px ${alpha(theme.palette.primary.dark, 0.24)}`
                :
                (theme) => `solid 8px ${alpha(theme.palette.grey[300], 0.24)}`,
          }}
        />
      </RootStyle>
    </>
  );
}
