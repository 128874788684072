// ----------------------------------------------------------------------

function path(root, sublink) {
  return `${root}${sublink}`;
}

const ROOTS_REPORT = '/report';

const ROOTS_TEST = '/test';
const ROOTS_SETTINGS = '/settings';

// ----------------------------------------------------------------------

// ----------------------------------------------------------------------

export const PATH_REPORT = {
  root: ROOTS_REPORT,
  clientHistory: path(ROOTS_REPORT, '/clienthistory'),
  bases: path(ROOTS_REPORT, '/bases'),
  eventos: path(ROOTS_REPORT, '/events'),
  sdrleads: path(ROOTS_REPORT, '/sdrleads')
};


export const PATH_TEST = {
  root: ROOTS_TEST,
  bi: path(ROOTS_TEST, '/bi'),
  announcements: {
    announcements: path(ROOTS_TEST, '/announcements'),
    announcement: path(ROOTS_TEST, '/announcement'),
    new: path(ROOTS_TEST, '/announcement/new'),
    management: path(ROOTS_TEST, '/announcements/management'),
  }, 
  composite: path(ROOTS_TEST, '/composite'),  
  orgchart: path(ROOTS_TEST, '/orgchart'),  
  orgstructure: path(ROOTS_TEST, '/orgstructure'),
  eventcontrol: path(ROOTS_TEST, '/eventcontrol'),
  vgr: path(ROOTS_TEST, '/vgr'),
  roa: path(ROOTS_TEST, '/roa'),
  npscalculator: path(ROOTS_TEST, '/npscalculator'),
};

export const PATH_SETTINGS = {
  root: ROOTS_SETTINGS,
  user: {
    root: path(ROOTS_SETTINGS, '/user'),
    list: path(ROOTS_SETTINGS, '/user/list'),
    mass: path(ROOTS_SETTINGS, '/user/mass'),
    newUser: path(ROOTS_SETTINGS, '/user/new'),
    updates: path(ROOTS_SETTINGS, '/user/updates'),
    cognito: path(ROOTS_SETTINGS, '/user/cognito-logs'),
  },
  report: {
    root: path(ROOTS_SETTINGS, '/report'),
    list: path(ROOTS_SETTINGS, '/report/list'),
  },
  campaign: {
    root: path(ROOTS_SETTINGS, '/campaign'),
    list: path(ROOTS_SETTINGS, '/campaign/list'),
  },
  profiles: path(ROOTS_SETTINGS, '/profiles'),
  org: path(ROOTS_SETTINGS, '/org'),
};
