import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';
//
import useCollapseDrawer from '../hooks/useCollapseDrawer';

// ----------------------------------------------------------------------

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  single: PropTypes.bool,
  color: PropTypes.string,
  sx: PropTypes.any,
};

export default function Logo({ disabledLink = false, single = false, color, sx }) {
  const theme = useTheme();
  const { isCollapse } = useCollapseDrawer();
  const PRIMARY_MAIN = theme.palette.primary.main;

  const logoFull = (
    <Box sx={{ width: 200, height: 80, ...sx }}>
      <svg xmlns="http://www.w3.org/2000/svg"
        width="100%" height="100%"
        viewBox="0 0 3000 1000">
        <path id="logo-full"
          fill={color || (theme.palette.mode === 'light' ? PRIMARY_MAIN : theme.palette.grey[100])}
          d="M 2758.15,578.43
           C 2746.99,583.27 2735.56,586.42 2735.56,586.42
             2735.56,586.42 2810.85,766.42 2810.85,766.42
             2810.85,766.42 2668.29,766.42 2668.29,766.42
             2668.29,766.42 2547.41,483.92 2547.41,483.92
             2547.41,483.92 2671.05,483.73 2671.05,483.73
             2703.11,483.20 2722.59,466.80 2727.40,436.30
             2729.83,420.76 2726.94,407.34 2719.04,397.45
             2710.97,387.36 2698.01,381.44 2681.02,380.33
             2681.02,380.33 2505.03,380.33 2505.03,380.33
             2505.03,380.33 2495.66,474.83 2495.66,474.83
             2495.66,474.83 2479.78,608.62 2479.78,608.62
             2479.78,608.62 2464.13,766.23 2464.13,766.23
             2464.13,766.23 2327.75,766.54 2327.75,766.54
             2327.75,766.54 2377.94,269.24 2377.94,269.24
             2377.94,269.24 2702.66,269.23 2702.66,269.23
             2772.07,273.29 2853.35,304.99 2846.40,433.09
             2846.40,433.09 2846.39,433.34 2846.39,433.34
             2845.03,500.63 2817.96,552.49 2758.15,578.43M 393.91,767.16
           C 434.31,767.16 463.24,751.82 482.35,720.28
             482.35,720.28 742.19,269.92 742.19,269.92
             742.19,269.92 590.84,269.92 590.84,269.92
             590.84,269.92 416.48,576.76 416.48,576.76
             416.48,576.76 295.56,269.92 295.56,269.92
             295.56,269.92 135.74,269.92 135.74,269.92
             135.74,269.92 313.53,715.80 313.53,715.80
             326.23,748.42 354.27,767.16 393.91,767.16M 1027.44,775.63
           C 1027.44,775.63 1187.27,775.63 1187.27,775.63
             1187.27,775.63 1007.22,324.18 1007.22,324.18
             994.29,293.42 968.72,278.39 929.08,278.39
             888.71,278.39 859.77,293.74 840.62,325.31
             840.62,325.31 580.81,775.63 580.81,775.63
             580.81,775.63 732.17,775.63 732.17,775.63
             732.17,775.63 906.51,468.81 906.51,468.81
             906.51,468.81 1027.44,775.63 1027.44,775.63 Z
           M 1426.87,639.42
           C 1390.61,635.83 1372.12,615.70 1372.67,581.14
             1372.67,581.14 1372.71,580.49 1372.71,580.49
             1372.71,580.49 1402.59,269.93 1402.59,269.93
             1402.59,269.93 1264.76,269.93 1264.76,269.93
             1264.76,269.93 1234.39,585.24 1234.39,585.24
             1227.70,646.56 1237.27,692.82 1262.84,722.80
             1285.83,749.75 1322.80,764.67 1372.73,767.16
             1372.73,767.16 1665.76,767.15 1665.76,767.15
             1665.76,767.15 1677.73,639.71 1677.73,639.71
             1677.73,639.71 1426.87,639.42 1426.87,639.42 Z
           M 2120.05,270.39
           C 2165.80,269.58 2201.07,282.15 2224.85,307.77
             2259.97,345.65 2267.88,419.17 2261.44,510.58
             2253.91,683.48 2199.87,764.77 2101.49,766.80
             2101.49,766.80 1861.63,766.80 1861.63,766.80
             1861.63,766.80 1860.78,766.80 1860.78,766.80
             1822.96,766.80 1793.24,755.35 1772.43,732.75
             1737.49,694.80 1728.96,620.58 1739.71,518.41
             1756.05,350.00 1813.52,270.90 1923.00,270.02
             1923.00,270.02 2120.05,270.39 2120.05,270.39 Z
           M 2135.44,512.84
           C 2140.21,458.79 2138.47,423.40 2125.45,407.67
             2112.84,391.05 2097.07,381.05 2064.26,380.48
             2064.26,380.48 1954.59,380.50 1954.59,380.50
             1919.91,379.18 1895.83,399.53 1881.36,429.45
             1872.11,443.52 1865.10,472.89 1859.91,519.17
             1853.10,579.63 1855.62,596.54 1859.02,607.76
             1866.41,630.56 1881.93,649.56 1909.70,649.45
             1909.70,649.45 1922.92,649.58 1922.92,649.58
             1922.92,649.58 2044.61,649.24 2044.61,649.24
             2044.61,649.24 2053.52,649.19 2053.52,649.19
             2082.61,647.99 2101.05,634.84 2112.38,614.69
             2121.62,598.16 2130.76,565.59 2135.44,512.84" />
      </svg>
    </Box>
  );

  const logoSingle = (
    <Box sx={{ width: 40, height: 40, ...sx }}>
      <svg xmlns="http://www.w3.org/2000/svg"
        width="100%" height="100%"
        viewBox="0 0 770 627">
        <path id="logo-single"
          fill={color || PRIMARY_MAIN}
          d="M 54.00,44.00
           C 54.00,44.00 226.00,44.00 226.00,44.00
             226.00,44.00 233.59,45.02 233.59,45.02
             233.59,45.02 241.80,63.00 241.80,63.00
             241.80,63.00 265.42,123.00 265.42,123.00
             265.42,123.00 288.80,182.00 288.80,182.00
             288.80,182.00 334.40,298.00 334.40,298.00
             334.40,298.00 358.05,358.00 358.05,358.00
             358.05,358.00 368.00,385.00 368.00,385.00
             373.27,380.07 380.07,365.87 384.00,359.00
             384.00,359.00 415.28,304.00 415.28,304.00
             415.28,304.00 516.42,126.00 516.42,126.00
             516.42,126.00 551.72,64.00 551.72,64.00
             553.66,60.59 560.95,46.57 563.39,45.02
             565.34,43.78 568.73,44.00 571.00,44.00
             571.00,44.00 734.00,44.00 734.00,44.00
             734.00,44.00 704.42,96.00 704.42,96.00
             704.42,96.00 655.42,181.00 655.42,181.00
             655.42,181.00 486.42,474.00 486.42,474.00
             486.42,474.00 453.42,531.00 453.42,531.00
             443.46,548.17 437.29,561.07 422.00,574.83
             403.46,591.51 375.80,600.71 351.00,601.00
             335.60,601.18 323.97,600.74 309.00,596.28
             290.56,590.78 273.72,579.18 263.08,563.00
             254.99,550.69 250.24,536.60 244.80,523.00
             244.80,523.00 224.00,471.00 224.00,471.00
             224.00,471.00 114.80,197.00 114.80,197.00
             114.80,197.00 76.60,101.00 76.60,101.00
             76.60,101.00 54.00,44.00 54.00,44.00 Z" />
      </svg>
    </Box>
  );

  if (disabledLink) {
    return <Box>{isCollapse || single ? logoSingle : logoFull}</Box>;
  }

  return <RouterLink to="/">{isCollapse || single ? logoSingle : logoFull}</RouterLink>;
}