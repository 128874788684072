import PropTypes from 'prop-types';
import { createContext, useCallback, useState, useMemo } from 'react';
// hooks
import useLocalStorage from '../hooks/useLocalStorage';
// utils
import getColorPresets, { colorPresets, defaultPreset } from '../utils/getColorPresets';
// config
import { defaultSettings } from '../config';

// ----------------------------------------------------------------------

const initialState = {
  ...defaultSettings,
  onChangeMode: () => { },
  onToggleMode: () => { },
  onChangeDirection: () => { },
  onChangePowerBiDirection: () => { },
  onChangeColor: () => { },
  onToggleStretch: () => { },
  onResetSetting: () => { },
  setColor: defaultPreset,
  colorOption: [],
  prefersColorScheme: 'light',
};

const SettingsContext = createContext(initialState);

SettingsProvider.propTypes = {
  children: PropTypes.node,
};

function SettingsProvider({ children }) {
  const [changingThemePowerBiDirection, setChangingThemePowerBiDirection] = useState('');

  const [settings, setSettings] = useLocalStorage('settings', {
    themeMode: initialState.themeMode,
    themeDirection: initialState.themeDirection,
    themePowerBiDirection: initialState.themePowerBiDirection,
    themeColorPresets: initialState.themeColorPresets,
    themeStretch: initialState.themeStretch,
  });

  const onChangeMode = useCallback((event) => {
    setSettings({
      ...settings,
      themeMode: event.target.value,
    });
  }, [settings, setSettings]);

  const onToggleMode = useCallback(() => {
    setSettings({
      ...settings,
      themeMode: settings.themeMode === 'light' ? 'dark' : 'light',
    });
  }, [settings, setSettings]);

  const onChangeDirection = useCallback((event) => {
    setSettings({
      ...settings,
      themeDirection: event.target.value,
    });
  }, [settings, setSettings]);

  const onChangePowerBiDirection = useCallback((event) => {
    setChangingThemePowerBiDirection(event.target.value);
    setSettings({
      ...settings,
      themePowerBiDirection: event.target.value,
    });
  }, [settings, setSettings]);

  const onChangeColor = useCallback((event) => {
    setSettings({
      ...settings,
      themeColorPresets: event.target.value,
    });
  }, [settings, setSettings]);

  const onToggleStretch = useCallback(() => {
    setSettings({
      ...settings,
      themeStretch: !settings.themeStretch,
    });
  }, [settings, setSettings]);

  const onResetSetting = useCallback(() => {
    setSettings({
      themeMode: initialState.themeMode,
      themeDirection: initialState.themeDirection,
      themePowerBiDirection: initialState.themePowerBiDirection,
      themeColorPresets: initialState.themeColorPresets,
      themeStretch: initialState.themeStretch,
    });
  }, [setSettings]);

  const settingsContextValues = useMemo(() => (
    {
      ...settings,
      // Mode
      onChangeMode,
      onToggleMode,
      // Direction
      onChangeDirection,
      onChangePowerBiDirection,
      changingThemePowerBiDirection,
      setChangingThemePowerBiDirection,
      // Color
      onChangeColor,
      setColor: getColorPresets(settings.themeColorPresets),
      colorOption: colorPresets.map((color) => ({
        name: color.name,
        value: color.main,
      })),
      // Stretch
      onToggleStretch,
      // Reset Setting
      onResetSetting,
      // Broswer theme mode
      prefersColorScheme:
        window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches ? 'dark' : 'light',
    }
  ), [settings, changingThemePowerBiDirection, onChangeColor, onChangeDirection, onChangeMode, onChangePowerBiDirection, onResetSetting, onToggleMode, onToggleStretch]);

  return (
    <SettingsContext.Provider value={settingsContextValues} >
      {children}
    </SettingsContext.Provider>
  );
}

export { SettingsProvider, SettingsContext };
