import { Suspense, lazy } from 'react';
import { Navigate, useRoutes, useLocation } from 'react-router-dom';
// layouts
import DashboardLayout from '../../../layouts/dashboard';
// components
import LoadingScreen from '../../../components/LoadingScreen';
// hooks 
import useAuth from '../../../hooks/useAuth';
// default routes
import defaultRouter from '../../defaultRouter';

// ----------------------------------------------------------------------

const Loadable = (Component) => (props) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { pathname } = useLocation();

  return (
    <Suspense fallback={<LoadingScreen isDashboard={
      pathname.includes('/report')} />}>
      <Component {...props} />
    </Suspense>
  );
};

export function Router() {
  const { user, username, userRole, userDepartment, userBranch, userRoutes } = useAuth();

  return useRoutes([
    ...defaultRouter({ defaultRoute: "/home", user, username, userRole, userDepartment, userBranch, userRoutes }),

    {
      path: 'report',
      element: <DashboardLayout />,
      children: [
        { element: <Navigate to="/report/gerencial" replace />, index: true },

        { path: 'gerencial', element: <Gerencial /> },
      ]
    }
  ])
}

// REPORT
const Gerencial = Loadable(lazy(() => import('../../../pages/VI/EXP/Gerencial')));
