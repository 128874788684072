// ----------------------------------------------------------------------

export default function LinearProgress(theme) {
  return {
    MuiLinearProgress: {
      styleOverrides: {
        root: {
          ...theme.palette.mode === 'dark' && { 
            backgroundColor: theme.palette.grey[200],
            color: theme.palette.grey[200]
          }
        },
      },
    },
  };
}
